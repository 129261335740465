import { useTranslation } from "@toolkit/i18n";
import { CustomDialog } from "@toolkit/ui";
import { useEffect, useState } from "react";
import { GuidedCareTemplateGoalRecommendationInterventionsActivitiesFields } from "../../forms/TemplateGoalRecommendationInterventionsActivitiesFields/GuidedCareTemplateGoalRecommendationInterventionsActivitiesFields";
import { useOpenState } from "@toolkit/core";

type IGuidedCareTemplateGoalRecommendationInterventionActivitiesModalData = {
  interventionUniqueId: string;
};

type IOpen = (data: IGuidedCareTemplateGoalRecommendationInterventionActivitiesModalData) => void;
type IClose = () => void;

export const GuidedCareTemplateGoalRecommendationInterventionActivitiesModalApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

export const GuidedCareTemplateGoalRecommendationInterventionActivitiesModal = () => {
  const [data, setData] = useState<IGuidedCareTemplateGoalRecommendationInterventionActivitiesModalData>();

  const { t } = useTranslation("gcadmin");

  const { open: isOpen, handleOpen, handleClose } = useOpenState();

  const open: IOpen = _data => {
    handleOpen();
    setData(_data);
  };

  useEffect(() => {
    GuidedCareTemplateGoalRecommendationInterventionActivitiesModalApi.open = open;
    GuidedCareTemplateGoalRecommendationInterventionActivitiesModalApi.close = handleClose;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      maxWidth={"lg"}
      DialogTitleProps={{
        title: t("Intervention Activities"),
        onClose: handleClose,
      }}
      DialogActionsProps={{
        hasClose: true,
      }}
    >
      <GuidedCareTemplateGoalRecommendationInterventionsActivitiesFields interventionUniqueId={data?.interventionUniqueId!} />
    </CustomDialog>
  );
};
