import { LabTemplate } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useNavigate, useParams } from "react-router-dom";
import { labTemplatesPaths } from "../../constants";
import { ILabTemplateUpsertFormEvent, LabTemplateUpsertForm } from "../../forms/LabTemplateUpsert/LabTemplateUpsertForm";
import { useLabTemplateCloneMutation, useLabTemplateGetQuery } from "../../gql";
import { useSetLabTemplatesBreadcrumbs } from "../../hooks";
import { convertLabTemplateFormValuesToBackEndValues } from "../../others/utils";
import { ILabTemplateCloneContainerParams } from "../../types";

export const LabTemplateCloneContainer = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const { labTemplateId } = useParams<ILabTemplateCloneContainerParams>();

  const { data, loading } = useLabTemplateGetQuery({
    variables: {
      id: labTemplateId!,
    },
    skip: !labTemplateId,
  });

  const labTemplate = data?.getLabTemplate as LabTemplate;

  const [cloneLabTemplate, { loading: isSubmitting }] = useLabTemplateCloneMutation({
    onCompleted: mutationData => {
      if (!mutationData?.duplicateLabTemplate?.errors?.length) {
        succeeded(t("Lab Template cloned successfully"));
        navigate(labTemplatesPaths.list.fullPath);
      } else {
        failed(t("Lab Template clone failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onLabTemplateUpsertFormChange = (event: ILabTemplateUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertLabTemplateFormValuesToBackEndValues(event.payload.values);

      cloneLabTemplate({
        variables: {
          id: labTemplateId!,
          input: values,
        },
      });
    }
  };

  useSetLabTemplatesBreadcrumbs("CLONE", pickLocalizedValue(labTemplate?.display!, labTemplate?.arabicDisplay!));

  return (
    <LabTemplateUpsertForm
      labTemplate={labTemplate}
      submitButtonLabel={t("Clone")}
      isClone
      isLoading={loading}
      isButtonDisabled={isSubmitting}
      onChange={onLabTemplateUpsertFormChange}
    />
  );
};
