import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MedicalMessageVariantActivationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type MedicalMessageVariantActivationMutation = { __typename?: 'Mutation', updateMedicalMessageVariantActiveStatus?: { __typename?: 'MedicalMessageVariantCRUD', medicalMessageVariant?: { __typename?: 'MedicalMessageVariant', isActive?: boolean | null } | null, medicalMessageVariantErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, message?: string | null }> | null } | null };


export const MedicalMessageVariantActivationDocument = gql`
    mutation MedicalMessageVariantActivation($id: ID!) {
  updateMedicalMessageVariantActiveStatus(id: $id) {
    medicalMessageVariant {
      isActive
    }
    medicalMessageVariantErrors {
      field
      message
    }
  }
}
    `;
export type MedicalMessageVariantActivationMutationFn = Apollo.MutationFunction<MedicalMessageVariantActivationMutation, MedicalMessageVariantActivationMutationVariables>;

/**
 * __useMedicalMessageVariantActivationMutation__
 *
 * To run a mutation, you first call `useMedicalMessageVariantActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMedicalMessageVariantActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [medicalMessageVariantActivationMutation, { data, loading, error }] = useMedicalMessageVariantActivationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMedicalMessageVariantActivationMutation(baseOptions?: Apollo.MutationHookOptions<MedicalMessageVariantActivationMutation, MedicalMessageVariantActivationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MedicalMessageVariantActivationMutation, MedicalMessageVariantActivationMutationVariables>(MedicalMessageVariantActivationDocument, options);
      }
export type MedicalMessageVariantActivationMutationHookResult = ReturnType<typeof useMedicalMessageVariantActivationMutation>;
export type MedicalMessageVariantActivationMutationResult = Apollo.MutationResult<MedicalMessageVariantActivationMutation>;
export type MedicalMessageVariantActivationMutationOptions = Apollo.BaseMutationOptions<MedicalMessageVariantActivationMutation, MedicalMessageVariantActivationMutationVariables>;