import { GuidedCareActivityType, MedicalMessageCategory } from "@/schema/types";
import { MedicalFormsAutocomplete, MedicalMessagesAutocomplete } from "@health/autocompletes";
import {
  appointmentFrequencyTypeOptions,
  guidedCareActivityTypeTemplateOptions,
  isGuidedCareActivityTypeMedicalMessage,
} from "@health/enum-options";
import { useCustomForm, useCustomFormContext } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, FormNumberField, Grid } from "@toolkit/ui";
import {
  filterTeamMembersByActivityType,
  getMedicalMessageCategory,
  shouldTemplateActivityMessagesAndOffsetsShow,
} from "pages/Templates/others";
import { ForwardRefRenderFunction, forwardRef, useEffect, useImperativeHandle } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { IGuidedCareTemplateUpsertFormValues } from "../TemplateUpsert/GuidedCareTemplateUpsertFormSchema";
import {
  IGuidedCareTemplateInterventionActivityUpsertFormValues,
  guidedCareTemplateInterventionActivityUpsertFormDefaultValues,
  guidedCareTemplateInterventionActivityUpsertFormSchema,
} from "./GuidedCareTemplateInterventionActivityUpsertFormSchema";

export type IGuidedCareTemplateInterventionActivityUpsertFormEvent = {
  type: "CREATE" | "UPDATE";
  payload: {
    values: IGuidedCareTemplateInterventionActivityUpsertFormValues;
    interventionIndex: number;
  };
};

type GuidedCareTemplateInterventionActivityUpsertFormProps = {
  interventionIndex: number;
  activity?: IGuidedCareTemplateInterventionActivityUpsertFormValues;
  onChange: (event: IGuidedCareTemplateInterventionActivityUpsertFormEvent) => void;
};

export type GuidedCareTemplateInterventionActivityUpsertFormRef = {
  getForm: () => UseFormReturn<IGuidedCareTemplateInterventionActivityUpsertFormValues>;
  submit: () => void;
};

const GuidedCareTemplateInterventionActivityUpsertFormForwardRef: ForwardRefRenderFunction<
  GuidedCareTemplateInterventionActivityUpsertFormRef,
  GuidedCareTemplateInterventionActivityUpsertFormProps
> = (props, ref) => {
  const { activity, interventionIndex, onChange } = props;

  const { t } = useTranslation("gcadmin");

  const formContext = useCustomFormContext<IGuidedCareTemplateUpsertFormValues>();
  const teamMembers = formContext.getValues("teamMembers");

  const form = useCustomForm<IGuidedCareTemplateInterventionActivityUpsertFormValues>({
    defaultValues: guidedCareTemplateInterventionActivityUpsertFormDefaultValues,
    schema: guidedCareTemplateInterventionActivityUpsertFormSchema,
  });

  const { watch, setValues, handleSubmit } = form;

  const templateActivityType = watch("templateActivityType");
  const templateActivityTypeValue = templateActivityType?.value;

  const showTemplateActivityMessagesAndOffsets = shouldTemplateActivityMessagesAndOffsetsShow(templateActivityTypeValue);
  const filteredTeamMembers = filterTeamMembersByActivityType(teamMembers, templateActivityTypeValue);
  const medicalMessageCategory = getMedicalMessageCategory(templateActivityType?.label!);
  const medicalMessageActivity = isGuidedCareActivityTypeMedicalMessage(templateActivityType?.key!);

  const onSubmit = (values: IGuidedCareTemplateInterventionActivityUpsertFormValues) => {
    if (activity) {
      onChange({
        type: "UPDATE",
        payload: {
          values: {
            ...values,
            activityServices: activity?.activityServices,
          },
          interventionIndex,
        },
      });
    } else {
      onChange({
        type: "CREATE",
        payload: { values, interventionIndex },
      });
    }
  };

  useImperativeHandle(ref, () => ({
    getForm: () => form,
    submit: handleSubmit(onSubmit),
  }));

  useEffect(() => {
    if (activity) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { activityServices, ...rest } = activity;
      setValues(rest);
    }
  }, [activity, setValues]);

  return (
    <FormProvider {...form}>
      <form>
        <Grid container xs={12} spacing={2}>
          <Grid item xs={6}>
            <FormAutocomplete name={"templateActivityType"} label={t("Activity Type")} options={guidedCareActivityTypeTemplateOptions} />
          </Grid>

          <Grid item xs={6}>
            <FormAutocomplete
              name={"teamMember"}
              label={t("Assignee")}
              disabled={!templateActivityTypeValue}
              options={filteredTeamMembers}
            />
          </Grid>

          {templateActivityTypeValue === GuidedCareActivityType.MedicalForm && (
            <Grid item xs={12}>
              <MedicalFormsAutocomplete name={"medicalForm"} filter={{ isAdminForm: true, isActive: true }} />
            </Grid>
          )}

          {medicalMessageActivity && (
            <Grid item xs={12}>
              <MedicalMessagesAutocomplete name={"medicalMessage"} filter={{ category: medicalMessageCategory, isPublished: true }} />
            </Grid>
          )}

          <Grid item xs={6}>
            <FormNumberField name={"numberOfOccurrences"} label={t("Occurrences")} />
          </Grid>

          <Grid item xs={6}>
            <FormAutocomplete name={"frequencyType"} label={t("Frequency")} options={appointmentFrequencyTypeOptions} />
          </Grid>

          {showTemplateActivityMessagesAndOffsets && (
            <>
              <Grid item xs={6}>
                <MedicalMessagesAutocomplete
                  name={"beforeActivity"}
                  label={t("Before Activity")}
                  filter={{ category: MedicalMessageCategory.Instruction, isPublished: true }}
                />
              </Grid>

              <Grid item xs={6}>
                <MedicalMessagesAutocomplete
                  name={"afterActivity"}
                  label={t("After Activity")}
                  filter={{ category: MedicalMessageCategory.Instruction, isPublished: true }}
                />
              </Grid>

              <Grid item xs={6}>
                <FormNumberField name={"beforeActivityOffset"} label={t("Before Activity - Offset (Days)")} />
              </Grid>

              <Grid item xs={6}>
                <FormNumberField name={"afterActivityOffset"} label={t("After Activity - Offset (Days)")} />
              </Grid>
            </>
          )}
        </Grid>
      </form>
    </FormProvider>
  );
};

export const GuidedCareTemplateInterventionActivityUpsertForm = forwardRef(GuidedCareTemplateInterventionActivityUpsertFormForwardRef);
