import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MedicalMessageVariantUpdateMutationVariables = Types.Exact<{
  medicalMessageVariantUpdateId: Types.Scalars['ID']['input'];
  input: Types.MedicalMessageVariantInput;
}>;


export type MedicalMessageVariantUpdateMutation = { __typename?: 'Mutation', medicalMessageVariantUpdate?: { __typename?: 'MedicalMessageVariantCRUD', medicalMessageVariantErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, message?: string | null }> | null } | null };


export const MedicalMessageVariantUpdateDocument = gql`
    mutation MedicalMessageVariantUpdate($medicalMessageVariantUpdateId: ID!, $input: MedicalMessageVariantInput!) {
  medicalMessageVariantUpdate(id: $medicalMessageVariantUpdateId, input: $input) {
    medicalMessageVariantErrors {
      field
      message
    }
  }
}
    `;
export type MedicalMessageVariantUpdateMutationFn = Apollo.MutationFunction<MedicalMessageVariantUpdateMutation, MedicalMessageVariantUpdateMutationVariables>;

/**
 * __useMedicalMessageVariantUpdateMutation__
 *
 * To run a mutation, you first call `useMedicalMessageVariantUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMedicalMessageVariantUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [medicalMessageVariantUpdateMutation, { data, loading, error }] = useMedicalMessageVariantUpdateMutation({
 *   variables: {
 *      medicalMessageVariantUpdateId: // value for 'medicalMessageVariantUpdateId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMedicalMessageVariantUpdateMutation(baseOptions?: Apollo.MutationHookOptions<MedicalMessageVariantUpdateMutation, MedicalMessageVariantUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MedicalMessageVariantUpdateMutation, MedicalMessageVariantUpdateMutationVariables>(MedicalMessageVariantUpdateDocument, options);
      }
export type MedicalMessageVariantUpdateMutationHookResult = ReturnType<typeof useMedicalMessageVariantUpdateMutation>;
export type MedicalMessageVariantUpdateMutationResult = Apollo.MutationResult<MedicalMessageVariantUpdateMutation>;
export type MedicalMessageVariantUpdateMutationOptions = Apollo.BaseMutationOptions<MedicalMessageVariantUpdateMutation, MedicalMessageVariantUpdateMutationVariables>;