import { useTranslation } from "@toolkit/i18n";
import { CustomDialog } from "@toolkit/ui";
import { useEffect, useState } from "react";
import { GuidedCareTemplateInterventionActivityServices } from "../../forms/TemplateInterventionActivityServices/GuidedCareTemplateInterventionActivityServices";
import { useOpenState } from "@toolkit/core";

type IGuidedCareTemplateInterventionActivityServicesModalData = {
  interventionIndex: number;
  activityIndex: number;
};

type IOpen = (data: IGuidedCareTemplateInterventionActivityServicesModalData) => void;
type IClose = () => void;

export const GuidedCareTemplateInterventionActivityServicesModalApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

export const GuidedCareTemplateInterventionActivityServicesModal = () => {
  const [data, setData] = useState<IGuidedCareTemplateInterventionActivityServicesModalData>();

  const { t } = useTranslation("gcadmin");

  const { open: isOpen, handleOpen, handleClose } = useOpenState();

  const open: IOpen = _data => {
    handleOpen();
    setData(_data);
  };

  useEffect(() => {
    GuidedCareTemplateInterventionActivityServicesModalApi.open = open;
    GuidedCareTemplateInterventionActivityServicesModalApi.close = handleClose;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      DialogTitleProps={{
        title: t("Activity Services"),
        onClose: handleClose,
      }}
      DialogActionsProps={{
        hasClose: true,
      }}
    >
      <GuidedCareTemplateInterventionActivityServices interventionIndex={data?.interventionIndex!} activityIndex={data?.activityIndex!} />
    </CustomDialog>
  );
};
