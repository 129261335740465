import { PermissionEnum } from "@/schema/types";
import { hasPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import { RouteItem } from "shared/components";
import {
  GuidedCareTemplateActivitiesItemsManageContainer,
  GuidedCareTemplateActivitiesItemsTimelineContainer,
  GuidedCareTemplateActivityItemListContainer,
  GuidedCareTemplateCreateContainer,
  GuidedCareTemplateInterventionActivityItemListContainer,
  GuidedCareTemplateListContainer,
  GuidedCareTemplateUpdateContainer,
} from "../containers";
import { GuidedCareTemplateClone } from "../containers/TemplateClone/GuidedCareTemplateClone";
import { guidedCareTemplatesPaths, guidedCareTemplatesRoute } from "./GuidedCareTemplatesPaths";

export const guidedCareTemplatesRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  return {
    id: "guided-care-templates-routes",
    text: t("Templates", { ns: "gcadmin" }),
    route: guidedCareTemplatesRoute,
    isProhibited: !hasPermission(PermissionEnum.ManageProgramTemplates),
    subItems: [
      {
        id: "guided-care-template-list-route",
        route: guidedCareTemplatesPaths.list.route,
        fullPath: guidedCareTemplatesPaths.list.fullPath,
        element: <GuidedCareTemplateListContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "guided-care-template-create-route",
        route: guidedCareTemplatesPaths.create.route,
        fullPath: guidedCareTemplatesPaths.create.fullPath,
        hidden: true,
        element: <GuidedCareTemplateCreateContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "guided-care-template-update-route",
        route: guidedCareTemplatesPaths.update.route,
        fullPath: guidedCareTemplatesPaths.update.fullPath,
        hidden: true,
        element: <GuidedCareTemplateUpdateContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "guided-care-template-clone-route",
        route: guidedCareTemplatesPaths.clone.route,
        fullPath: guidedCareTemplatesPaths.clone.fullPath,
        hidden: true,
        element: <GuidedCareTemplateClone />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "guided-care-template-timeline-route",
        route: guidedCareTemplatesPaths.timeline.route,
        fullPath: guidedCareTemplatesPaths.timeline.fullPath,
        hidden: true,
        element: <GuidedCareTemplateActivitiesItemsTimelineContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "guided-care-template-activities-items-manage-route",
        route: guidedCareTemplatesPaths.activitiesItemsManage.route,
        fullPath: guidedCareTemplatesPaths.activitiesItemsManage.fullPath,
        hidden: true,
        element: <GuidedCareTemplateActivitiesItemsManageContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "guided-care-template-activity-item-list-route",
        route: guidedCareTemplatesPaths.activityItemList.route,
        fullPath: guidedCareTemplatesPaths.activityItemList.fullPath,
        hidden: true,
        element: <GuidedCareTemplateActivityItemListContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "guided-care-template-intervention-activity-item-list-route",
        route: guidedCareTemplatesPaths.interventionActivityItemList.route,
        fullPath: guidedCareTemplatesPaths.interventionActivityItemList.fullPath,
        hidden: true,
        element: <GuidedCareTemplateInterventionActivityItemListContainer />,
        onClick: (route: string) => navigate(route),
      },
    ],
  };
};
