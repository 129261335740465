import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthConditionCreateMutationVariables = Types.Exact<{
  input: Types.HealthConditionInput;
}>;


export type HealthConditionCreateMutation = { __typename?: 'Mutation', addHealthCondition?: { __typename?: 'HealthCondition', errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', field?: string | null, message?: string | null } | null> | null } | null };


export const HealthConditionCreateDocument = gql`
    mutation HealthConditionCreate($input: HealthConditionInput!) {
  addHealthCondition(input: $input) {
    errors {
      field
      message
    }
  }
}
    `;
export type HealthConditionCreateMutationFn = Apollo.MutationFunction<HealthConditionCreateMutation, HealthConditionCreateMutationVariables>;

/**
 * __useHealthConditionCreateMutation__
 *
 * To run a mutation, you first call `useHealthConditionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthConditionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthConditionCreateMutation, { data, loading, error }] = useHealthConditionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHealthConditionCreateMutation(baseOptions?: Apollo.MutationHookOptions<HealthConditionCreateMutation, HealthConditionCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthConditionCreateMutation, HealthConditionCreateMutationVariables>(HealthConditionCreateDocument, options);
      }
export type HealthConditionCreateMutationHookResult = ReturnType<typeof useHealthConditionCreateMutation>;
export type HealthConditionCreateMutationResult = Apollo.MutationResult<HealthConditionCreateMutation>;
export type HealthConditionCreateMutationOptions = Apollo.BaseMutationOptions<HealthConditionCreateMutation, HealthConditionCreateMutationVariables>;