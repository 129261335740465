import { TemplateFieldType } from "@/schema/types";
import { ShowButton } from "@toolkit/ui";
import { FC } from "react";
import {
  HealthParameterDefaultRangeValuesModal,
  HealthParameterDefaultRangeValuesModalApi,
} from "../../modals/HealthParameterDefaultRangeValues/HealthParameterDefaultRangeValuesModal";

type HealthParameterDefaultRangeValuesShowButtonProps = {
  type: TemplateFieldType;
  defaultValue?: string;
  minNormalRangeValue?: number;
  maxNormalRangeValue?: number;
  allowedValues?: string[];
};

export const HealthParameterDefaultRangeValuesShowButton: FC<HealthParameterDefaultRangeValuesShowButtonProps> = props => {
  const { type, defaultValue, minNormalRangeValue, maxNormalRangeValue, allowedValues } = props;

  const onShowButtonClick = () => {
    HealthParameterDefaultRangeValuesModalApi.open({
      type,
      defaultValue,
      minNormalRangeValue,
      maxNormalRangeValue,
      allowedValues,
    });
  };

  return (
    <>
      <HealthParameterDefaultRangeValuesModal />

      <ShowButton disabled={type !== TemplateFieldType.Number && type !== TemplateFieldType.String} onClick={onShowButtonClick} />
    </>
  );
};
