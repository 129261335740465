import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthParameterUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input?: Types.InputMaybe<Types.HealthParameterUpdateInput>;
}>;


export type HealthParameterUpdateMutation = { __typename?: 'Mutation', updateHealthParameter?: { __typename?: 'HealthParameter', errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', field?: string | null, message?: string | null } | null> | null } | null };


export const HealthParameterUpdateDocument = gql`
    mutation HealthParameterUpdate($id: ID!, $input: HealthParameterUpdateInput) {
  updateHealthParameter(id: $id, input: $input) {
    errors {
      field
      message
    }
  }
}
    `;
export type HealthParameterUpdateMutationFn = Apollo.MutationFunction<HealthParameterUpdateMutation, HealthParameterUpdateMutationVariables>;

/**
 * __useHealthParameterUpdateMutation__
 *
 * To run a mutation, you first call `useHealthParameterUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthParameterUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthParameterUpdateMutation, { data, loading, error }] = useHealthParameterUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHealthParameterUpdateMutation(baseOptions?: Apollo.MutationHookOptions<HealthParameterUpdateMutation, HealthParameterUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthParameterUpdateMutation, HealthParameterUpdateMutationVariables>(HealthParameterUpdateDocument, options);
      }
export type HealthParameterUpdateMutationHookResult = ReturnType<typeof useHealthParameterUpdateMutation>;
export type HealthParameterUpdateMutationResult = Apollo.MutationResult<HealthParameterUpdateMutation>;
export type HealthParameterUpdateMutationOptions = Apollo.BaseMutationOptions<HealthParameterUpdateMutation, HealthParameterUpdateMutationVariables>;