import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TemplateActivityItemUpdateMutationVariables = Types.Exact<{
  activityItems?: Types.InputMaybe<Array<Types.InputMaybe<Types.TemplateActivityEventItemInput>> | Types.InputMaybe<Types.TemplateActivityEventItemInput>>;
}>;


export type TemplateActivityItemUpdateMutation = { __typename?: 'Mutation', updateTemplateActivityItem?: { __typename?: 'TemplateActivityCRUD', templateActivity?: { __typename?: 'TemplateActivity', id: string } | null } | null };


export const TemplateActivityItemUpdateDocument = gql`
    mutation TemplateActivityItemUpdate($activityItems: [TemplateActivityEventItemInput]) {
  updateTemplateActivityItem(activityItems: $activityItems) {
    templateActivity {
      id
    }
  }
}
    `;
export type TemplateActivityItemUpdateMutationFn = Apollo.MutationFunction<TemplateActivityItemUpdateMutation, TemplateActivityItemUpdateMutationVariables>;

/**
 * __useTemplateActivityItemUpdateMutation__
 *
 * To run a mutation, you first call `useTemplateActivityItemUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTemplateActivityItemUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [templateActivityItemUpdateMutation, { data, loading, error }] = useTemplateActivityItemUpdateMutation({
 *   variables: {
 *      activityItems: // value for 'activityItems'
 *   },
 * });
 */
export function useTemplateActivityItemUpdateMutation(baseOptions?: Apollo.MutationHookOptions<TemplateActivityItemUpdateMutation, TemplateActivityItemUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TemplateActivityItemUpdateMutation, TemplateActivityItemUpdateMutationVariables>(TemplateActivityItemUpdateDocument, options);
      }
export type TemplateActivityItemUpdateMutationHookResult = ReturnType<typeof useTemplateActivityItemUpdateMutation>;
export type TemplateActivityItemUpdateMutationResult = Apollo.MutationResult<TemplateActivityItemUpdateMutation>;
export type TemplateActivityItemUpdateMutationOptions = Apollo.BaseMutationOptions<TemplateActivityItemUpdateMutation, TemplateActivityItemUpdateMutationVariables>;