import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ArticleCategoryUpdateMutationVariables = Types.Exact<{
  articleCategoryId: Types.Scalars['ID']['input'];
  input: Types.ArticleCategoryInput;
}>;


export type ArticleCategoryUpdateMutation = { __typename?: 'Mutation', articleCategoryUpdate?: { __typename?: 'ArticleCategory', id: string } | null };


export const ArticleCategoryUpdateDocument = gql`
    mutation ArticleCategoryUpdate($articleCategoryId: ID!, $input: ArticleCategoryInput!) {
  articleCategoryUpdate(id: $articleCategoryId, input: $input) {
    id
  }
}
    `;
export type ArticleCategoryUpdateMutationFn = Apollo.MutationFunction<ArticleCategoryUpdateMutation, ArticleCategoryUpdateMutationVariables>;

/**
 * __useArticleCategoryUpdateMutation__
 *
 * To run a mutation, you first call `useArticleCategoryUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArticleCategoryUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [articleCategoryUpdateMutation, { data, loading, error }] = useArticleCategoryUpdateMutation({
 *   variables: {
 *      articleCategoryId: // value for 'articleCategoryId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArticleCategoryUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ArticleCategoryUpdateMutation, ArticleCategoryUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArticleCategoryUpdateMutation, ArticleCategoryUpdateMutationVariables>(ArticleCategoryUpdateDocument, options);
      }
export type ArticleCategoryUpdateMutationHookResult = ReturnType<typeof useArticleCategoryUpdateMutation>;
export type ArticleCategoryUpdateMutationResult = Apollo.MutationResult<ArticleCategoryUpdateMutation>;
export type ArticleCategoryUpdateMutationOptions = Apollo.BaseMutationOptions<ArticleCategoryUpdateMutation, ArticleCategoryUpdateMutationVariables>;