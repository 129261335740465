import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LabTemplateListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.LabTemplateFilterInput>;
  sortBy?: Types.InputMaybe<Types.LabTemplateSortingInput>;
}>;


export type LabTemplateListQuery = { __typename?: 'Query', getLabTemplates?: { __typename?: 'LabTemplateConnection', pageInfo?: { __typename?: 'PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, endCursor?: string | null, startCursor?: string | null } | null, edges?: Array<{ __typename?: 'LabTemplateEdge', node?: { __typename?: 'LabTemplate', id?: string | null, display?: string | null, arabicDisplay?: string | null, labCodeDisplay?: string | null, isActive?: boolean | null, createdDate?: string | null } | null } | null> | null } | null };


export const LabTemplateListDocument = gql`
    query LabTemplateList($first: Int, $last: Int, $before: String, $after: String, $filter: LabTemplateFilterInput, $sortBy: LabTemplateSortingInput) {
  getLabTemplates(
    first: $first
    last: $last
    before: $before
    after: $after
    filter: $filter
    sortBy: $sortBy
  ) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      endCursor
      startCursor
    }
    edges {
      node {
        id
        display
        arabicDisplay
        labCodeDisplay
        isActive
        createdDate
      }
    }
  }
}
    `;

/**
 * __useLabTemplateListQuery__
 *
 * To run a query within a React component, call `useLabTemplateListQuery` and pass it any options that fit your needs.
 * When your component renders, `useLabTemplateListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLabTemplateListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useLabTemplateListQuery(baseOptions?: Apollo.QueryHookOptions<LabTemplateListQuery, LabTemplateListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LabTemplateListQuery, LabTemplateListQueryVariables>(LabTemplateListDocument, options);
      }
export function useLabTemplateListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LabTemplateListQuery, LabTemplateListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LabTemplateListQuery, LabTemplateListQueryVariables>(LabTemplateListDocument, options);
        }
export function useLabTemplateListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<LabTemplateListQuery, LabTemplateListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LabTemplateListQuery, LabTemplateListQueryVariables>(LabTemplateListDocument, options);
        }
export type LabTemplateListQueryHookResult = ReturnType<typeof useLabTemplateListQuery>;
export type LabTemplateListLazyQueryHookResult = ReturnType<typeof useLabTemplateListLazyQuery>;
export type LabTemplateListSuspenseQueryHookResult = ReturnType<typeof useLabTemplateListSuspenseQuery>;
export type LabTemplateListQueryResult = Apollo.QueryResult<LabTemplateListQuery, LabTemplateListQueryVariables>;