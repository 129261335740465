import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MedicalMessageVariantListQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sortBy?: Types.InputMaybe<Types.MedicalMessageVariantSortingInput>;
  filter?: Types.InputMaybe<Types.MedicalMessageVariantFilterInput>;
}>;


export type MedicalMessageVariantListQuery = { __typename?: 'Query', medicalMessageVariants?: { __typename?: 'MedicalMessageVariantCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'MedicalMessageVariantCountableEdge', node: { __typename?: 'MedicalMessageVariant', id: string, isActive?: boolean | null, presentedTitleArabic?: string | null, presentedTitleEnglish?: string | null, createdDate?: any | null, headerImage?: string | null, lastModifiedDate?: any | null, sentCounter?: number | null, readCounter?: number | null } }> } | null };


export const MedicalMessageVariantListDocument = gql`
    query MedicalMessageVariantList($after: String, $before: String, $first: Int, $last: Int, $sortBy: MedicalMessageVariantSortingInput, $filter: MedicalMessageVariantFilterInput) {
  medicalMessageVariants(
    after: $after
    before: $before
    first: $first
    last: $last
    sortBy: $sortBy
    filter: $filter
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
    edges {
      node {
        id
        isActive
        presentedTitleArabic
        presentedTitleEnglish
        createdDate
        headerImage
        lastModifiedDate
        sentCounter
        readCounter
      }
    }
  }
}
    `;

/**
 * __useMedicalMessageVariantListQuery__
 *
 * To run a query within a React component, call `useMedicalMessageVariantListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicalMessageVariantListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicalMessageVariantListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMedicalMessageVariantListQuery(baseOptions?: Apollo.QueryHookOptions<MedicalMessageVariantListQuery, MedicalMessageVariantListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MedicalMessageVariantListQuery, MedicalMessageVariantListQueryVariables>(MedicalMessageVariantListDocument, options);
      }
export function useMedicalMessageVariantListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MedicalMessageVariantListQuery, MedicalMessageVariantListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MedicalMessageVariantListQuery, MedicalMessageVariantListQueryVariables>(MedicalMessageVariantListDocument, options);
        }
export function useMedicalMessageVariantListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MedicalMessageVariantListQuery, MedicalMessageVariantListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MedicalMessageVariantListQuery, MedicalMessageVariantListQueryVariables>(MedicalMessageVariantListDocument, options);
        }
export type MedicalMessageVariantListQueryHookResult = ReturnType<typeof useMedicalMessageVariantListQuery>;
export type MedicalMessageVariantListLazyQueryHookResult = ReturnType<typeof useMedicalMessageVariantListLazyQuery>;
export type MedicalMessageVariantListSuspenseQueryHookResult = ReturnType<typeof useMedicalMessageVariantListSuspenseQuery>;
export type MedicalMessageVariantListQueryResult = Apollo.QueryResult<MedicalMessageVariantListQuery, MedicalMessageVariantListQueryVariables>;