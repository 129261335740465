import { DecisionPlanCategory } from "@/schema/types";
import { TFunction } from "@toolkit/i18n";
import { kebabCase } from "lodash";
import { convertToTitleCase } from "utils";
import { decisionsPaths } from "./DecisionsPaths";

export const decisionsBreadcrumbs = (t: TFunction) => ({
  title: (category: DecisionPlanCategory) => t(convertToTitleCase(category + "_PLANS")),
  main: (category: DecisionPlanCategory) => ({
    id: category,
    name: t(convertToTitleCase(category + "_PLANS")),
    route: decisionsPaths.main.fullPathWithParams({ category: kebabCase(category) }),
  }),
  create: {
    id: "decision-new",
    name: t("New", { ns: "gcadmin" }),
  },
  update: {
    id: "decision-update",
    name: t("Edit", { ns: "gcadmin" }),
  },
});
