import { H_OrderDirection, TemplateActivity, TemplateActivityEventItemSortingField } from "@/schema/types";
import { guidedCareActivityTypeNonMedicalMessageOptions } from "@health/enum-options";
import { APOLLO_MAX_LIMIT } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { AnnualTimeLine, Box, CircularProgress, MonthlyTimeLine, Typography, useGridContext } from "@toolkit/ui";
import moment from "moment";
import { useGuidedCareTemplateActivitiesItemListQuery } from "pages/Templates/gql";
import { IGuidedCareTemplateActivitiesItemsTimelineContainerParams } from "pages/Templates/types";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  GuidedCareActivityDurationPeriod,
  GuidedCareActivityItemFilter,
  guidedCareActivityDurationPeriodOptions,
  guidedCareActivityDurationPeriodOptionsMap,
  guidedCareActivityItemFilterOptions,
  guidedCareActivityItemFilterOptionsMap,
} from "../../enum-options";
import { GuidedCareTemplateActivitiesItemsTimelineAnnual } from "../TemplateActivitiesItemsTimelineAnnual/GuidedCareTemplateActivitiesItemsTimelineAnnual";
import { GuidedCareTemplateActivitiesItemsTimelineMenu } from "../TemplateActivitiesItemsTimelineMenu/GuidedCareTemplateActivitiesItemsTimelineMenu";
import { GuidedCareTemplateActivitiesItemsTimelineQuarterly } from "../TemplateActivitiesItemsTimelineQuarterly/GuidedCareTemplateActivitiesItemsTimelineQuarterly";
import { useGuidedCareTemplateActivitiesItemsTimelineStyle } from "./useGuidedCareTemplateActivitiesItemsTimelineStyle";

export const GuidedCareTemplateActivitiesItemsTimeline = () => {
  const [selectItem, setSelectItem] = useState<TemplateActivity | undefined>(undefined);

  const [filters, setFilters] = useState({
    duration: GuidedCareActivityDurationPeriod.Annual,
    showAllActivities: GuidedCareActivityItemFilter.ShowAllActivities,
  });

  const { t } = useTranslation("gcadmin");
  const { activeFiltersProps } = useGridContext();

  const { classes } = useGuidedCareTemplateActivitiesItemsTimelineStyle();

  const { templateId } = useParams<IGuidedCareTemplateActivitiesItemsTimelineContainerParams>();

  const requestedFilters = activeFiltersProps?.requestedFilters;

  const { data, loading } = useGuidedCareTemplateActivitiesItemListQuery({
    variables: {
      first: APOLLO_MAX_LIMIT,
      filter: {
        templateId: templateId!,
        ...requestedFilters,
        templateActivityType:
          filters?.showAllActivities === GuidedCareActivityItemFilter.ShowAllActivities
            ? requestedFilters?.templateActivityType || []
            : guidedCareActivityTypeNonMedicalMessageOptions?.map(item => item?.value),
      },
      sortBy: {
        field: TemplateActivityEventItemSortingField.Offset,
        direction: H_OrderDirection.Asc,
      },
    },
  });

  const months = Array.from({ length: 12 }, (_, index) => index + 1);

  const getMonthFromDays = (days: number) => {
    const date = moment().startOf("year").add(days, "days");
    return date.month() + 1;
  };

  const activityItems =
    data?.TemplateActivityEventItems?.edges?.map(item => ({
      month: getMonthFromDays(item?.node?.offsetInDays!),
      ...item?.node,
    })) || [];

  const getActivities = (month: number) => activityItems?.filter(item => item?.month === month);

  const timeline = months?.map(month => ({
    label: "Month" + " " + month,
    activities: getActivities(month),
    activitiesWeeks: {
      activitiesWeeks1: [],
      activitiesWeeks2: [],
      activitiesWeeks3: [],
      activitiesWeeks4: [],
    },
  }));

  const getWeekOfMonth = (day: number) => {
    const date = moment().dayOfYear(day);

    let weekOfMonth = Math.ceil((date.date() + moment(date).startOf("month").day()) / 7);

    if (weekOfMonth > 4) {
      weekOfMonth = 4;
    }
    return weekOfMonth;
  };

  timeline.forEach(item => {
    item.activities.forEach(activity => {
      const weekOfMonth = getWeekOfMonth(activity.offsetInDays!);
      item.activitiesWeeks[`activitiesWeeks${weekOfMonth}`].push(activity);
    });
  });

  const handleSelectItem = (item: TemplateActivity) => {
    selectItem?.id === item?.id ? setSelectItem(undefined) : setSelectItem(item);
  };

  const handleDuration = (item: GuidedCareActivityDurationPeriod) => {
    setFilters(prevState => ({ ...prevState, duration: item }));
  };

  const handleMessagesFilter = (item: GuidedCareActivityItemFilter) => {
    setFilters(prevState => ({ ...prevState, showAllActivities: item }));
  };

  return (
    <Box>
      {loading ? (
        <Box className={classes.loadingWrapper}>
          <CircularProgress size={80} />
        </Box>
      ) : (
        <Box className={classes.wrapper}>
          <Box className={classes.titleWrapper}>
            <Typography className={classes.title}>{t("Activity Items Timeline")}</Typography>

            <Box className={classes.menuWrapper}>
              <GuidedCareTemplateActivitiesItemsTimelineMenu
                title={t("Duration")}
                items={guidedCareActivityDurationPeriodOptions}
                selected={guidedCareActivityDurationPeriodOptionsMap[filters?.duration]?.label}
                onChange={handleDuration}
              />

              <GuidedCareTemplateActivitiesItemsTimelineMenu
                title={t("Messages")}
                items={guidedCareActivityItemFilterOptions}
                selected={guidedCareActivityItemFilterOptionsMap[filters?.showAllActivities]?.label}
                onChange={handleMessagesFilter}
              />
            </Box>
          </Box>

          {filters?.duration === GuidedCareActivityDurationPeriod.Annual ? (
            <AnnualTimeLine timeline={timeline}>
              <GuidedCareTemplateActivitiesItemsTimelineAnnual selectItem={selectItem!} onSelectItem={handleSelectItem} />
            </AnnualTimeLine>
          ) : (
            <MonthlyTimeLine timeline={timeline}>
              <GuidedCareTemplateActivitiesItemsTimelineQuarterly selectItem={selectItem!} onSelectItem={handleSelectItem} />
            </MonthlyTimeLine>
          )}
        </Box>
      )}
    </Box>
  );
};
