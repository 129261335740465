import { HealthCondition } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useNavigate, useParams } from "react-router-dom";
import { healthConditionsPaths } from "../../constants";
import { HealthConditionUpsertForm, IHealthConditionUpsertFormEvent } from "../../forms/HealthConditionUpsert/HealthConditionUpsertForm";
import { useHealthConditionCloneMutation, useHealthConditionGetQuery } from "../../gql";
import { useSetHealthConditionsBreadcrumbs } from "../../hooks";
import { convertHealthConditionFormValuesToBackEndValues } from "../../others/utils";
import { IHealthConditionCloneContainerParams } from "../../types";

export const HealthConditionCloneContainer = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const { healthConditionId } = useParams<IHealthConditionCloneContainerParams>();

  const { data, loading } = useHealthConditionGetQuery({
    variables: {
      id: healthConditionId!,
    },
    skip: !healthConditionId,
  });

  const healthCondition = data?.getHealthCondition as HealthCondition;

  const [cloneHealthCondition, { loading: isSubmitting }] = useHealthConditionCloneMutation({
    onCompleted: mutationData => {
      if (!mutationData?.duplicateHealthCondition?.errors?.length) {
        succeeded(t("Health Condition cloned successfully"));
        navigate(healthConditionsPaths.list.fullPath);
      } else {
        failed(t("Health Condition clone failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onHealthConditionUpsertFormChange = (event: IHealthConditionUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertHealthConditionFormValuesToBackEndValues(event.payload.values);

      cloneHealthCondition({
        variables: {
          duplicateHealthConditionId: healthConditionId!,
          input: values,
        },
      });
    }
  };

  useSetHealthConditionsBreadcrumbs("CLONE", pickLocalizedValue(healthCondition?.display!, healthCondition?.arabicDisplay!));

  return (
    <HealthConditionUpsertForm
      healthCondition={healthCondition}
      submitButtonLabel={t("Clone")}
      isClone
      isLoading={loading}
      isButtonDisabled={isSubmitting}
      onChange={onHealthConditionUpsertFormChange}
    />
  );
};
