import { z } from "zod";
import { zodSchema } from "@health/autocompletes";
import { zodEnumSchema } from "@health/enum-options";

export const guidedCareActivityItemUpdateFormSchema = z.object({
  id: z.string(),
  offset: z.coerce.number().min(0),
  time: zodEnumSchema.timeOfDay,
  beforeActivity: zodSchema.medicalMessage.nullish(),
  afterActivity: zodSchema.medicalMessage.nullish(),
  beforeActivityOffset: z.coerce.number().min(0).nullish(),
  afterActivityOffset: z.coerce.number().min(0).nullish(),
});

export type IGuidedCareActivityItemUpdateFormValues = z.infer<typeof guidedCareActivityItemUpdateFormSchema>;

export const guidedCareActivityItemUpdateFormDefaultValues: Partial<IGuidedCareActivityItemUpdateFormValues> = {
  id: undefined,
  offset: undefined,
  time: undefined,
  beforeActivity: null,
  afterActivity: null,
  beforeActivityOffset: null,
  afterActivityOffset: null,
};
