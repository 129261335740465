import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TemplateBreadcrumbGetQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type TemplateBreadcrumbGetQuery = { __typename?: 'Query', healthProgramTemplate?: { __typename?: 'HealthProgramTemplate', id: string, name?: string | null } | null };


export const TemplateBreadcrumbGetDocument = gql`
    query TemplateBreadcrumbGet($id: ID!) {
  healthProgramTemplate(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useTemplateBreadcrumbGetQuery__
 *
 * To run a query within a React component, call `useTemplateBreadcrumbGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateBreadcrumbGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateBreadcrumbGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTemplateBreadcrumbGetQuery(baseOptions: Apollo.QueryHookOptions<TemplateBreadcrumbGetQuery, TemplateBreadcrumbGetQueryVariables> & ({ variables: TemplateBreadcrumbGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateBreadcrumbGetQuery, TemplateBreadcrumbGetQueryVariables>(TemplateBreadcrumbGetDocument, options);
      }
export function useTemplateBreadcrumbGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateBreadcrumbGetQuery, TemplateBreadcrumbGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateBreadcrumbGetQuery, TemplateBreadcrumbGetQueryVariables>(TemplateBreadcrumbGetDocument, options);
        }
export function useTemplateBreadcrumbGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TemplateBreadcrumbGetQuery, TemplateBreadcrumbGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TemplateBreadcrumbGetQuery, TemplateBreadcrumbGetQueryVariables>(TemplateBreadcrumbGetDocument, options);
        }
export type TemplateBreadcrumbGetQueryHookResult = ReturnType<typeof useTemplateBreadcrumbGetQuery>;
export type TemplateBreadcrumbGetLazyQueryHookResult = ReturnType<typeof useTemplateBreadcrumbGetLazyQuery>;
export type TemplateBreadcrumbGetSuspenseQueryHookResult = ReturnType<typeof useTemplateBreadcrumbGetSuspenseQuery>;
export type TemplateBreadcrumbGetQueryResult = Apollo.QueryResult<TemplateBreadcrumbGetQuery, TemplateBreadcrumbGetQueryVariables>;