import { HealthSymptomSpecialization, Maybe } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, ShowButton } from "@toolkit/ui";
import { FC } from "react";
import { HealthSymptomsSpecializations } from "../../components/HealthSymptomsSpecializations/HealthSymptomsSpecializations";

type HealthSymptomsSpecializationsModalProps = {
  specializations?: Maybe<Maybe<HealthSymptomSpecialization>[]>;
};

export const HealthSymptomsSpecializationsModal: FC<HealthSymptomsSpecializationsModalProps> = props => {
  const { specializations } = props;

  const { t } = useTranslation("gcadmin");

  return (
    <CustomDialog
      type={"info"}
      title={t("Specializations")}
      button={<ShowButton disabled={!specializations || !specializations?.length} />}
    >
      <HealthSymptomsSpecializations specializations={specializations as HealthSymptomSpecialization[]} />
    </CustomDialog>
  );
};
