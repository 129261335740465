import { RiskFactorTemplate } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useNavigate, useParams } from "react-router-dom";
import { riskFactorTemplatesPaths } from "../../constants";
import {
  IRiskFactorTemplateUpsertFormEvent,
  RiskFactorTemplateUpsertForm,
} from "../../forms/RiskFactorTemplateUpsert/RiskFactorTemplateUpsertForm";
import { useRiskFactorTemplateGetQuery, useRiskFactorTemplateUpdateMutation } from "../../gql";
import { useSetRiskFactorTemplatesBreadcrumbs } from "../../hooks";
import { convertRiskFactorTemplateUpdateFormValuesToBackEndValues } from "../../others/utils";
import { IRiskFactorTemplateUpdateContainerParams } from "../../types";

export const RiskFactorTemplateUpdateContainer = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const { riskFactorTemplateId } = useParams<IRiskFactorTemplateUpdateContainerParams>();

  const { data, loading } = useRiskFactorTemplateGetQuery({
    variables: {
      id: riskFactorTemplateId!,
    },
    skip: !riskFactorTemplateId,
  });

  const riskFactorTemplate = data?.getRiskFactorTemplate as RiskFactorTemplate;

  const [updateRiskFactorTemplate, { loading: isSubmitting }] = useRiskFactorTemplateUpdateMutation({
    onCompleted: mutationData => {
      if (!mutationData?.updateRiskFactorTemplate?.errors?.length) {
        succeeded(t("Risk Factor Template updated successfully"));
        navigate(riskFactorTemplatesPaths.list.fullPath);
      } else {
        failed(t("Risk Factor Template update failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onRiskFactorTemplateUpsertFormChange = (event: IRiskFactorTemplateUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertRiskFactorTemplateUpdateFormValuesToBackEndValues(event.payload.values);

      updateRiskFactorTemplate({
        variables: {
          id: riskFactorTemplateId!,
          input: values,
        },
      });
    }
  };

  useSetRiskFactorTemplatesBreadcrumbs("UPDATE", pickLocalizedValue(riskFactorTemplate?.display!, riskFactorTemplate?.arabicDisplay!));

  return (
    <RiskFactorTemplateUpsertForm
      riskFactorTemplate={riskFactorTemplate}
      submitButtonLabel={t("Update")}
      isLoading={loading}
      isButtonDisabled={isSubmitting}
      onChange={onRiskFactorTemplateUpsertFormChange}
    />
  );
};
