import { MedicalMessageVariant } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, useBreadCrumbs } from "@toolkit/ui";
import { MedicalMessageInformation } from "pages/MedicalMessages/components";
import { createMedicalMessagesBreadcrumbs, medicalMessagesPaths } from "pages/MedicalMessages/constants";
import { useMedicalMessageVariantListQuery } from "pages/MedicalMessages/gql";
import { IMedicalMessageVariantsContainerParams } from "pages/MedicalMessages/types";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMedicalMessageVariantsContainerStyle } from "./useMedicalMessageVariantsContainerStyle";
import { useMedicalMessageVariantsTableColumns } from "./useMedicalMessageVariantsTableColumns";

export const MedicalMessageVariantsContainer = () => {
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();
  const { t } = useTranslation("gcadmin");

  const { medicalMessageId } = useParams<IMedicalMessageVariantsContainerParams>();

  const { classes } = useMedicalMessageVariantsContainerStyle();

  const onAddNewItemClick = () => {
    navigate(medicalMessagesPaths.variantCreate.fullPathWithParams({ medicalMessageId: medicalMessageId! }));
  };

  const onEditRowClick = (item: MedicalMessageVariant) => {
    navigate(
      medicalMessagesPaths.variantUpdate.fullPathWithParams({ medicalMessageId: medicalMessageId!, medicalMessageVariantId: item?.id })
    );
  };

  useEffect(() => {
    if (medicalMessageId) {
      const { variantsTitle, main } = createMedicalMessagesBreadcrumbs(t);
      setBreadCrumb({ title: variantsTitle, values: [main] });
    }
  }, [setBreadCrumb, t, medicalMessageId]);

  return (
    <GridProvider
      gridName='medicalMessageVaraintList'
      columns={useMedicalMessageVariantsTableColumns()}
      query={useMedicalMessageVariantListQuery}
      filterInput={{ medicalMessageId: medicalMessageId! }}
      tableAction={{
        isEditVisible: true,
        onEditRow: onEditRowClick,
      }}
    >
      <PageWrapper filters={<FilterGrid />} actions={<MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />}>
        <MedicalMessageInformation medicalMessageId={medicalMessageId!} />

        <div className={classes.divider} />

        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
