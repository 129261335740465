/* eslint-disable max-lines */
import { GuidedCareHealthProgram } from "@/schema/types";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper, Typography } from "@toolkit/ui";
import { useGuidedCareProgramTemplateListLazyQuery } from "pages/Programs/gql";
import {
  convertProgramTemplateToFormValues,
  convertProgramTemplateWithLatestVersionToFormValues,
  convertProgramToFormValues,
} from "pages/Programs/others";
import { IGuidedCareProgramUpsertFormEvent } from "pages/Programs/types";
import { FC, useCallback, useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { GuidedCareProgramOutdatedAlert } from "../../components/ProgramOutdatedAlert/GuidedCareProgramOutdatedAlert";
import { GuidedCareProgramActivitiesForm } from "../ProgramActivities/GuidedCareProgramActivitiesForm";
import { GuidedCareProgramInformationForm } from "../ProgramInformation/GuidedCareProgramInformationForm";
import { GuidedCareProgramMembersForm } from "../ProgramMembers/GuidedCareProgramMembersForm";
import { GuidedCareProgramPayerNetworksForm } from "../ProgramPayerNetworks/GuidedCareProgramPayerNetworksForm";
import {
  GuidedCareProgramUpsertFormSchema,
  IGuidedCareProgramUpsertFormValues,
  guidedCareProgramUpsertFormDefaultValues,
} from "./GuidedCareProgramUpsertFormSchema";

type GuidedCareProgramUpsertFormProps = {
  submitButtonLabel: string;
  isLoading?: boolean;
  isButtonDisabled?: boolean;
  program?: GuidedCareHealthProgram;
  onChange: (values: IGuidedCareProgramUpsertFormEvent) => void;
};

export const GuidedCareProgramUpsertForm: FC<GuidedCareProgramUpsertFormProps> = props => {
  const { submitButtonLabel, program, isLoading, isButtonDisabled, onChange } = props;

  const [isProgramOutdated, setIsProgramOutdated] = useState(false);

  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();

  const form = useCustomForm<IGuidedCareProgramUpsertFormValues>({
    defaultValues: guidedCareProgramUpsertFormDefaultValues,
    schema: GuidedCareProgramUpsertFormSchema,
  });

  const { watch, setValue, setValues, setFormDisabled, handleSubmit } = form;

  const selectedTemplate = watch("template")?.value?.name;
  const selectedPayerId = watch("payer")?.value?.id;

  const [fetchGuidedCareProgramTemplateListQuery, { loading: isFetchingTemplate }] = useGuidedCareProgramTemplateListLazyQuery({
    fetchPolicy: "no-cache",
  });

  const fetchAndSetGuidedCareProgramTemplate = useCallback(
    async (templateName: string) => {
      const { data } = await fetchGuidedCareProgramTemplateListQuery({
        variables: {
          filter: {
            name: templateName,
          },
        },
      });

      const template = data?.healthProgramTemplates?.edges?.[0]?.node!;

      if (program) {
        const _program = convertProgramTemplateWithLatestVersionToFormValues(template, program);
        setValues(_program);
      } else {
        const _template = convertProgramTemplateToFormValues(template);
        setValues(_template);
      }
    },
    [fetchGuidedCareProgramTemplateListQuery, program, setValues]
  );

  const onSubmit = (values: IGuidedCareProgramUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  const handleSyncClick = () => {
    if (!program) return;

    setIsProgramOutdated(false);
    fetchAndSetGuidedCareProgramTemplate(program?.guidedCareHealthProgramTemplate?.name!);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    setValue("coveredNetworks", []);
    setValue("coveredMembers", []);
  }, [selectedPayerId, setValue]);

  useEffect(() => {
    if (program) {
      setIsProgramOutdated(!program?.guidedCareHealthProgramTemplate?.isLatestVersion);
    } else {
      setIsProgramOutdated(false);
    }
  }, [program]);

  useEffect(() => {
    setFormDisabled(isProgramOutdated);
  }, [isProgramOutdated, setFormDisabled]);

  useEffect(() => {
    if (program) {
      const _program = convertProgramToFormValues(program);
      setValues(_program);
    } else if (selectedTemplate) {
      fetchAndSetGuidedCareProgramTemplate(selectedTemplate);
    }
  }, [program, selectedTemplate, setValues, fetchAndSetGuidedCareProgramTemplate]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              isLoading={isLoading}
              hasFormButton={!isProgramOutdated}
              formButtonTitle={submitButtonLabel}
              onNavigation={handleCancel}
              newButtonDisabled={isButtonDisabled || isFetchingTemplate}
            />
          }
        >
          <Grid container spacing={3}>
            {!isLoading && isProgramOutdated && (
              <Grid item xs={12}>
                <GuidedCareProgramOutdatedAlert onClick={handleSyncClick} />
              </Grid>
            )}

            <Grid item xs={12}>
              <FormCard title={t("Program Information")} loading={isLoading} doYouHaveData>
                <GuidedCareProgramInformationForm isUpdateMode={!!program} />
              </FormCard>
            </Grid>

            {!selectedTemplate ? (
              <Grid item xs={12}>
                <FormCard title={t("Program Details")} loading={isFetchingTemplate} doYouHaveData>
                  <Typography> {t("Select a template to update program")} </Typography>
                </FormCard>
              </Grid>
            ) : (
              <>
                <GuidedCareProgramPayerNetworksForm selectedPayerId={selectedPayerId} isLoading={isLoading} />

                <Grid item xs={12}>
                  <FormCard loading={isLoading} doYouHaveData>
                    <GuidedCareProgramMembersForm />
                  </FormCard>
                </Grid>

                <Grid item xs={12}>
                  <FormCard loading={isLoading} doYouHaveData>
                    <GuidedCareProgramActivitiesForm />
                  </FormCard>
                </Grid>
              </>
            )}
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
