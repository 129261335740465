import { DecisionPlanCategory, HealthParameter } from "@/schema/types";
import { DecisionUpdateContainer } from "@health/domains";
import { pickLocalizedValue } from "@toolkit/i18n";
import { useDecisionPlanListQuery } from "pages/Decisions/gql/queries";
import { DecisionPlanCode, IHealthParameterDecisionUpdateContainerParams } from "pages/HealthParameters/types/types";
import { useParams } from "react-router-dom";
import { useHealthParameterGetQuery } from "../../gql";
import { useSetHealthParametersBreadcrumbs } from "../../hooks";

export const HealthParameterDecisionUpdateContainer = () => {
  const { healthParameterId } = useParams<IHealthParameterDecisionUpdateContainerParams>();

  const { data } = useHealthParameterGetQuery({
    variables: {
      id: healthParameterId!,
    },
    skip: !healthParameterId,
  });

  const healthParameter = data?.getHealthParameter as HealthParameter;

  const { data: healthParameterDecisionPlanData } = useDecisionPlanListQuery({
    variables: {
      first: 10,
      filter: {
        planCode: DecisionPlanCode.HealthParameterReference,
      },
    },
  });

  const healthParameterDecisionPlan = healthParameterDecisionPlanData?.getPlans?.edges?.[0]?.node!;

  useSetHealthParametersBreadcrumbs("DECISION_UPDATE", pickLocalizedValue(healthParameter?.display!, healthParameter?.arabicDisplay!));

  return (
    <DecisionUpdateContainer
      category={DecisionPlanCategory.HealthCondition}
      healthParameter={healthParameter}
      healthParameterDecisionPlan={healthParameterDecisionPlan}
    />
  );
};
