import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { HPageInfoFragmentFragmentDoc } from '../../../../../gql/fragments/__generated__/HPageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareTemplateActivitiesItemListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter: Types.TemplateActivityEventItemFilterInput;
  sortBy?: Types.InputMaybe<Types.TemplateActivityEventItemSortingInput>;
}>;


export type GuidedCareTemplateActivitiesItemListQuery = { __typename?: 'Query', TemplateActivityEventItems?: { __typename?: 'TemplateActivityEventItemCountableConnection', pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'TemplateActivityEventItemCountableEdge', node: { __typename?: 'TemplateActivityEventItem', id: string, offsetInDays?: number | null, timeOfDay?: Types.TimeOfDay | null, templateActivity?: { __typename?: 'TemplateActivity', id: string, templateActivityType?: Types.GuidedCareActivityType | null, templateTeamMember?: { __typename?: 'TemplateTeamMember', position?: Types.TeamMemberPosition | null, specialization?: string | null } | null, medicalMessage?: { __typename?: 'MedicalMessage', id: string, code?: string | null, name?: string | null } | null, medicalForm?: { __typename?: 'MedicalForm', id: string, code?: string | null, name?: string | null, nameAr?: string | null } | null } | null } }> } | null };


export const GuidedCareTemplateActivitiesItemListDocument = gql`
    query GuidedCareTemplateActivitiesItemList($first: Int, $after: String, $last: Int, $before: String, $filter: TemplateActivityEventItemFilterInput!, $sortBy: TemplateActivityEventItemSortingInput) {
  TemplateActivityEventItems(
    first: $first
    after: $after
    last: $last
    before: $before
    filter: $filter
    sortBy: $sortBy
  ) {
    pageInfo {
      ...HPageInfoFragment
    }
    edges {
      node {
        id
        offsetInDays
        timeOfDay
        templateActivity {
          id
          templateActivityType
          templateTeamMember {
            position
            specialization
          }
          medicalMessage {
            id
            code
            name
          }
          medicalForm {
            id
            code
            name
            nameAr
          }
        }
      }
    }
  }
}
    ${HPageInfoFragmentFragmentDoc}`;

/**
 * __useGuidedCareTemplateActivitiesItemListQuery__
 *
 * To run a query within a React component, call `useGuidedCareTemplateActivitiesItemListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareTemplateActivitiesItemListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuidedCareTemplateActivitiesItemListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGuidedCareTemplateActivitiesItemListQuery(baseOptions: Apollo.QueryHookOptions<GuidedCareTemplateActivitiesItemListQuery, GuidedCareTemplateActivitiesItemListQueryVariables> & ({ variables: GuidedCareTemplateActivitiesItemListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuidedCareTemplateActivitiesItemListQuery, GuidedCareTemplateActivitiesItemListQueryVariables>(GuidedCareTemplateActivitiesItemListDocument, options);
      }
export function useGuidedCareTemplateActivitiesItemListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuidedCareTemplateActivitiesItemListQuery, GuidedCareTemplateActivitiesItemListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuidedCareTemplateActivitiesItemListQuery, GuidedCareTemplateActivitiesItemListQueryVariables>(GuidedCareTemplateActivitiesItemListDocument, options);
        }
export function useGuidedCareTemplateActivitiesItemListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GuidedCareTemplateActivitiesItemListQuery, GuidedCareTemplateActivitiesItemListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GuidedCareTemplateActivitiesItemListQuery, GuidedCareTemplateActivitiesItemListQueryVariables>(GuidedCareTemplateActivitiesItemListDocument, options);
        }
export type GuidedCareTemplateActivitiesItemListQueryHookResult = ReturnType<typeof useGuidedCareTemplateActivitiesItemListQuery>;
export type GuidedCareTemplateActivitiesItemListLazyQueryHookResult = ReturnType<typeof useGuidedCareTemplateActivitiesItemListLazyQuery>;
export type GuidedCareTemplateActivitiesItemListSuspenseQueryHookResult = ReturnType<typeof useGuidedCareTemplateActivitiesItemListSuspenseQuery>;
export type GuidedCareTemplateActivitiesItemListQueryResult = Apollo.QueryResult<GuidedCareTemplateActivitiesItemListQuery, GuidedCareTemplateActivitiesItemListQueryVariables>;