import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthConditionUpdateMutationVariables = Types.Exact<{
  updateHealthConditionId: Types.Scalars['ID']['input'];
  input: Types.HealthConditionUpdateInput;
}>;


export type HealthConditionUpdateMutation = { __typename?: 'Mutation', updateHealthCondition?: { __typename?: 'HealthCondition', errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', field?: string | null, message?: string | null } | null> | null } | null };


export const HealthConditionUpdateDocument = gql`
    mutation HealthConditionUpdate($updateHealthConditionId: ID!, $input: HealthConditionUpdateInput!) {
  updateHealthCondition(id: $updateHealthConditionId, input: $input) {
    errors {
      field
      message
    }
  }
}
    `;
export type HealthConditionUpdateMutationFn = Apollo.MutationFunction<HealthConditionUpdateMutation, HealthConditionUpdateMutationVariables>;

/**
 * __useHealthConditionUpdateMutation__
 *
 * To run a mutation, you first call `useHealthConditionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthConditionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthConditionUpdateMutation, { data, loading, error }] = useHealthConditionUpdateMutation({
 *   variables: {
 *      updateHealthConditionId: // value for 'updateHealthConditionId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHealthConditionUpdateMutation(baseOptions?: Apollo.MutationHookOptions<HealthConditionUpdateMutation, HealthConditionUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthConditionUpdateMutation, HealthConditionUpdateMutationVariables>(HealthConditionUpdateDocument, options);
      }
export type HealthConditionUpdateMutationHookResult = ReturnType<typeof useHealthConditionUpdateMutation>;
export type HealthConditionUpdateMutationResult = Apollo.MutationResult<HealthConditionUpdateMutation>;
export type HealthConditionUpdateMutationOptions = Apollo.BaseMutationOptions<HealthConditionUpdateMutation, HealthConditionUpdateMutationVariables>;