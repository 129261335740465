import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RiskFactorTemplateGetQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type RiskFactorTemplateGetQuery = { __typename?: 'Query', getRiskFactorTemplate?: { __typename?: 'RiskFactorTemplate', id?: string | null, code?: string | null, display?: string | null, arabicDisplay?: string | null, isActive?: boolean | null } | null };


export const RiskFactorTemplateGetDocument = gql`
    query RiskFactorTemplateGet($id: ID!) {
  getRiskFactorTemplate(id: $id) {
    id
    code
    display
    arabicDisplay
    isActive
  }
}
    `;

/**
 * __useRiskFactorTemplateGetQuery__
 *
 * To run a query within a React component, call `useRiskFactorTemplateGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useRiskFactorTemplateGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRiskFactorTemplateGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRiskFactorTemplateGetQuery(baseOptions: Apollo.QueryHookOptions<RiskFactorTemplateGetQuery, RiskFactorTemplateGetQueryVariables> & ({ variables: RiskFactorTemplateGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RiskFactorTemplateGetQuery, RiskFactorTemplateGetQueryVariables>(RiskFactorTemplateGetDocument, options);
      }
export function useRiskFactorTemplateGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RiskFactorTemplateGetQuery, RiskFactorTemplateGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RiskFactorTemplateGetQuery, RiskFactorTemplateGetQueryVariables>(RiskFactorTemplateGetDocument, options);
        }
export function useRiskFactorTemplateGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RiskFactorTemplateGetQuery, RiskFactorTemplateGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RiskFactorTemplateGetQuery, RiskFactorTemplateGetQueryVariables>(RiskFactorTemplateGetDocument, options);
        }
export type RiskFactorTemplateGetQueryHookResult = ReturnType<typeof useRiskFactorTemplateGetQuery>;
export type RiskFactorTemplateGetLazyQueryHookResult = ReturnType<typeof useRiskFactorTemplateGetLazyQuery>;
export type RiskFactorTemplateGetSuspenseQueryHookResult = ReturnType<typeof useRiskFactorTemplateGetSuspenseQuery>;
export type RiskFactorTemplateGetQueryResult = Apollo.QueryResult<RiskFactorTemplateGetQuery, RiskFactorTemplateGetQueryVariables>;