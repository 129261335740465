import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LabTemplateGetQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type LabTemplateGetQuery = { __typename?: 'Query', getLabTemplate?: { __typename?: 'LabTemplate', id?: string | null, display?: string | null, arabicDisplay?: string | null, code?: string | null, labCodeDisplay?: string | null, isActive?: boolean | null } | null };


export const LabTemplateGetDocument = gql`
    query LabTemplateGet($id: ID!) {
  getLabTemplate(id: $id) {
    id
    display
    arabicDisplay
    code
    labCodeDisplay
    isActive
  }
}
    `;

/**
 * __useLabTemplateGetQuery__
 *
 * To run a query within a React component, call `useLabTemplateGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useLabTemplateGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLabTemplateGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLabTemplateGetQuery(baseOptions: Apollo.QueryHookOptions<LabTemplateGetQuery, LabTemplateGetQueryVariables> & ({ variables: LabTemplateGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LabTemplateGetQuery, LabTemplateGetQueryVariables>(LabTemplateGetDocument, options);
      }
export function useLabTemplateGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LabTemplateGetQuery, LabTemplateGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LabTemplateGetQuery, LabTemplateGetQueryVariables>(LabTemplateGetDocument, options);
        }
export function useLabTemplateGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<LabTemplateGetQuery, LabTemplateGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LabTemplateGetQuery, LabTemplateGetQueryVariables>(LabTemplateGetDocument, options);
        }
export type LabTemplateGetQueryHookResult = ReturnType<typeof useLabTemplateGetQuery>;
export type LabTemplateGetLazyQueryHookResult = ReturnType<typeof useLabTemplateGetLazyQuery>;
export type LabTemplateGetSuspenseQueryHookResult = ReturnType<typeof useLabTemplateGetSuspenseQuery>;
export type LabTemplateGetQueryResult = Apollo.QueryResult<LabTemplateGetQuery, LabTemplateGetQueryVariables>;