import { CodeSystemCode } from "@/schema/types";
import { SystemCodeAutocomplete } from "@health/autocompletes";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, FormTextField, Grid } from "@toolkit/ui";
import { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { healthConditionDiagnoseCreateTypeOptions } from "../../enum-options/HealthConditionDiagnoseCreationMethod";
import { HealthConditionDiagnoseCreationMethod } from "../../types";
import {
  healthConditionDiagnoseCreateFormDefaultValues,
  healthConditionDiagnoseCreateFormSchema,
  IHealthConditionDiagnoseCreateFormValues,
} from "./HealthConditionDiagnoseCreateFormSchema";

export type IHealthConditionDiagnoseCreateFormEvent = {
  type: "CREATE";
  payload: {
    values: IHealthConditionDiagnoseCreateFormValues;
  };
};

type HealthConditionDiagnoseCreateFormProps = {
  onChange: (event: IHealthConditionDiagnoseCreateFormEvent) => void;
};

export type HealthConditionDiagnoseCreateFormRef = {
  getForm: () => UseFormReturn<IHealthConditionDiagnoseCreateFormValues>;
  submit: () => void;
};

const HealthConditionDiagnoseCreateFormForwardRef: ForwardRefRenderFunction<
  HealthConditionDiagnoseCreateFormRef,
  HealthConditionDiagnoseCreateFormProps
> = (props, ref) => {
  const { onChange } = props;

  const { t } = useTranslation("gcadmin");

  const form = useCustomForm<IHealthConditionDiagnoseCreateFormValues>({
    defaultValues: healthConditionDiagnoseCreateFormDefaultValues,
    schema: healthConditionDiagnoseCreateFormSchema,
  });

  const { watch, setValue, handleSubmit } = form;

  const creationMethod = watch("creationMethod")?.value;

  const onSubmit = (values: IHealthConditionDiagnoseCreateFormValues) => {
    onChange({
      type: "CREATE",
      payload: { values },
    });
  };

  useImperativeHandle(ref, () => ({
    getForm: () => form,
    submit: handleSubmit(onSubmit),
  }));

  useEffect(() => {
    setValue("diagnose", undefined);
    setValue("disease", undefined);
  }, [creationMethod, setValue]);

  return (
    <FormProvider {...form}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormAutocomplete name={"creationMethod"} label={t("Creation Method")} options={healthConditionDiagnoseCreateTypeOptions} />
        </Grid>

        {creationMethod === HealthConditionDiagnoseCreationMethod.Diagnose && (
          <Grid item xs={12}>
            <SystemCodeAutocomplete name={"diagnose"} label={t("Diagnose")} filter={{ codeSystemCode: CodeSystemCode.Diagnosis }} />
          </Grid>
        )}

        {creationMethod === HealthConditionDiagnoseCreationMethod.Disease && (
          <Grid item xs={12}>
            <FormTextField name={"disease"} label={t("Disease")} placeholder={t("Disease")} />
          </Grid>
        )}
      </Grid>
    </FormProvider>
  );
};

export const HealthConditionDiagnoseCreateForm = forwardRef(HealthConditionDiagnoseCreateFormForwardRef);
