import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthConditionGetQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type HealthConditionGetQuery = { __typename?: 'Query', getHealthCondition?: { __typename?: 'HealthCondition', id?: string | null, display?: string | null, arabicDisplay?: string | null, code?: string | null, umlsCode?: string | null, disease?: string | null, type?: Types.HealthConditionType | null, isActive?: boolean | null } | null };


export const HealthConditionGetDocument = gql`
    query HealthConditionGet($id: ID!) {
  getHealthCondition(id: $id) {
    id
    display
    arabicDisplay
    code
    umlsCode
    disease
    type
    isActive
  }
}
    `;

/**
 * __useHealthConditionGetQuery__
 *
 * To run a query within a React component, call `useHealthConditionGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthConditionGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthConditionGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHealthConditionGetQuery(baseOptions: Apollo.QueryHookOptions<HealthConditionGetQuery, HealthConditionGetQueryVariables> & ({ variables: HealthConditionGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthConditionGetQuery, HealthConditionGetQueryVariables>(HealthConditionGetDocument, options);
      }
export function useHealthConditionGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthConditionGetQuery, HealthConditionGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthConditionGetQuery, HealthConditionGetQueryVariables>(HealthConditionGetDocument, options);
        }
export function useHealthConditionGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HealthConditionGetQuery, HealthConditionGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HealthConditionGetQuery, HealthConditionGetQueryVariables>(HealthConditionGetDocument, options);
        }
export type HealthConditionGetQueryHookResult = ReturnType<typeof useHealthConditionGetQuery>;
export type HealthConditionGetLazyQueryHookResult = ReturnType<typeof useHealthConditionGetLazyQuery>;
export type HealthConditionGetSuspenseQueryHookResult = ReturnType<typeof useHealthConditionGetSuspenseQuery>;
export type HealthConditionGetQueryResult = Apollo.QueryResult<HealthConditionGetQuery, HealthConditionGetQueryVariables>;