import { CustomDialog } from "@toolkit/ui";
import { FC, useEffect, useRef } from "react";
import { useTranslation } from "@toolkit/i18n";
import {
  IRiskFactorTemplateParameterCreateFormEvent,
  RiskFactorTemplateParameterCreateForm,
  RiskFactorTemplateParameterCreateFormRef,
} from "../../forms/RiskFactorTemplateParameterCreate/RiskFactorTemplateParameterCreateForm";
import { useOpenState } from "@toolkit/core";

export const RiskFactorTemplateParameterCreateModalApi: { open: () => void; close: () => void } = {
  open: () => {},
  close: () => {},
};

type RiskFactorTemplateParameterCreateModalProps = {
  isLoading?: boolean;
  onChange: (event: IRiskFactorTemplateParameterCreateFormEvent) => void;
};

export const RiskFactorTemplateParameterCreateModal: FC<RiskFactorTemplateParameterCreateModalProps> = props => {
  const { isLoading, onChange } = props;

  const riskFactorTemplateParameterCreateFormRef = useRef<RiskFactorTemplateParameterCreateFormRef>(null);

  const { t } = useTranslation("gcadmin");

  const { open, handleOpen, handleClose } = useOpenState();

  const onButtonClick = () => {
    riskFactorTemplateParameterCreateFormRef.current?.submit();
  };

  useEffect(() => {
    RiskFactorTemplateParameterCreateModalApi.open = handleOpen;
    RiskFactorTemplateParameterCreateModalApi.close = handleClose;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      maxWidth={"sm"}
      open={open}
      DialogTitleProps={{
        title: t("Add New Parameter"),
        onClose: close,
      }}
      DialogActionsProps={{
        hasClose: true,
        submitTitle: t("Add"),
        disableSubmit: isLoading,
        onSubmit: onButtonClick,
      }}
    >
      <RiskFactorTemplateParameterCreateForm ref={riskFactorTemplateParameterCreateFormRef} onChange={onChange} />
    </CustomDialog>
  );
};
