import { DecisionPlanCategory } from "@/schema/types";
import { DecisionCreateContainer } from "@health/domains";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { decisionsBreadcrumbs } from "pages/Decisions/constants";
import { FC, useEffect } from "react";

type GuidedCareDecisionCreateContainerProps = {
  category: DecisionPlanCategory;
};

export const GuidedCareDecisionCreateContainer: FC<GuidedCareDecisionCreateContainerProps> = props => {
  const { category } = props;

  const { t } = useTranslation("gcadmin");
  const { setBreadCrumb } = useBreadCrumbs();

  useEffect(() => {
    const { title, main, create } = decisionsBreadcrumbs(t);

    setBreadCrumb({ title: title(category), values: [main(category), create] });
  }, [category, setBreadCrumb, t]);

  return <DecisionCreateContainer category={category} />;
};
