import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthConditionActivationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type HealthConditionActivationMutation = { __typename?: 'Mutation', updateHealthConditionActiveStatus?: { __typename?: 'HealthCondition', isActive?: boolean | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', field?: string | null, message?: string | null } | null> | null } | null };


export const HealthConditionActivationDocument = gql`
    mutation HealthConditionActivation($id: ID!) {
  updateHealthConditionActiveStatus(id: $id) {
    isActive
    errors {
      field
      message
    }
  }
}
    `;
export type HealthConditionActivationMutationFn = Apollo.MutationFunction<HealthConditionActivationMutation, HealthConditionActivationMutationVariables>;

/**
 * __useHealthConditionActivationMutation__
 *
 * To run a mutation, you first call `useHealthConditionActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthConditionActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthConditionActivationMutation, { data, loading, error }] = useHealthConditionActivationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHealthConditionActivationMutation(baseOptions?: Apollo.MutationHookOptions<HealthConditionActivationMutation, HealthConditionActivationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthConditionActivationMutation, HealthConditionActivationMutationVariables>(HealthConditionActivationDocument, options);
      }
export type HealthConditionActivationMutationHookResult = ReturnType<typeof useHealthConditionActivationMutation>;
export type HealthConditionActivationMutationResult = Apollo.MutationResult<HealthConditionActivationMutation>;
export type HealthConditionActivationMutationOptions = Apollo.BaseMutationOptions<HealthConditionActivationMutation, HealthConditionActivationMutationVariables>;