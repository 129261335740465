import { HealthParameter, RiskFactorTemplate } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { PageWrapper, PlusIcon, StyledButton, useAddToast } from "@toolkit/ui";
import { useNavigate, useParams } from "react-router-dom";
import { HealthParameterCustomTableList } from "../../../HealthParameters/components";
import { RiskFactorTemplateInformation } from "../../components/RiskFactorTemplateInformation/RiskFactorTemplateInformation";
import { riskFactorTemplatesPaths } from "../../constants";
import { IRiskFactorTemplateParameterCreateFormEvent } from "../../forms/RiskFactorTemplateParameterCreate/RiskFactorTemplateParameterCreateForm";
import {
  RiskFactorTemplateParameterListDocument,
  useRiskFactorTemplateParameterCreateMutation,
  useRiskFactorTemplateParameterDeleteMutation,
  useRiskFactorTemplateParameterListQuery,
} from "../../gql";
import { useSetRiskFactorTemplatesBreadcrumbs } from "../../hooks";
import {
  RiskFactorTemplateParameterCreateModal,
  RiskFactorTemplateParameterCreateModalApi,
} from "../../modals/RiskFactorTemplateParameterCreate/RiskFactorTemplateParameterCreateModal";
import { IRiskFactorTemplateParameterListContainerParams } from "../../types";

export const RiskFactorTemplateParameterListContainer = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const { riskFactorTemplateId } = useParams<IRiskFactorTemplateParameterListContainerParams>();

  const { data, loading } = useRiskFactorTemplateParameterListQuery({
    variables: {
      id: riskFactorTemplateId!,
    },
    skip: !riskFactorTemplateId,
  });

  const riskFactorTemplate = data?.getRiskFactorTemplate as RiskFactorTemplate;
  const riskFactorTemplateParameters = riskFactorTemplate?.fields as HealthParameter[];

  const [createRiskFactorTemplateParameter, { loading: isCreating }] = useRiskFactorTemplateParameterCreateMutation({
    onCompleted: mutationData => {
      if (!mutationData?.addFieldToRiskFactorTemplate?.errors?.length) {
        succeeded(t("Risk Factor Template Parameter added successfully"));
        RiskFactorTemplateParameterCreateModalApi.close();
      } else {
        failed(t("Risk Factor Template Parameter add failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    refetchQueries: [
      {
        query: RiskFactorTemplateParameterListDocument,
        variables: { id: riskFactorTemplateId },
      },
    ],
  });

  const [deleteRiskFactorTemplateParameter, { loading: isDeleting }] = useRiskFactorTemplateParameterDeleteMutation({
    onCompleted: mutationData => {
      if (!mutationData?.deleteFieldFromRiskFactorTemplate?.errors?.length) {
        succeeded(t("Risk Factor Template Parameter deleted successfully"));
      } else {
        failed(t("Risk Factor Template Parameter delete failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    refetchQueries: [
      {
        query: RiskFactorTemplateParameterListDocument,
        variables: { id: riskFactorTemplateId },
      },
    ],
  });

  const onAddNewItemClick = () => {
    RiskFactorTemplateParameterCreateModalApi.open();
  };

  const onDeleteRowClick = (item: HealthParameter) => {
    deleteRiskFactorTemplateParameter({
      variables: {
        riskFactorTemplateId: riskFactorTemplateId!,
        healthParameterId: item?.id!,
      },
    });
  };

  const onCancelClick = () => {
    navigate(riskFactorTemplatesPaths.list.fullPath);
  };

  const onRiskFactorTemplateParameterCreateModalChange = (event: IRiskFactorTemplateParameterCreateFormEvent) => {
    if (event.type === "CREATE") {
      createRiskFactorTemplateParameter({
        variables: {
          riskFactorTemplateId: riskFactorTemplateId!,
          healthParameterId: event.payload.values.healthParameter?.value?.id!,
        },
      });
    }
  };

  useSetRiskFactorTemplatesBreadcrumbs(
    "PARAMETERS_LIST",
    pickLocalizedValue(riskFactorTemplate?.display!, riskFactorTemplate?.arabicDisplay!)
  );

  return (
    <>
      <RiskFactorTemplateParameterCreateModal isLoading={isCreating} onChange={onRiskFactorTemplateParameterCreateModalChange} />

      <PageWrapper
        actions={
          <>
            <StyledButton color={"success"} startIcon={<PlusIcon />} onClick={onAddNewItemClick}>
              {t("Add New")}
            </StyledButton>

            <StyledButton variant={"outlined"} onClick={onCancelClick}>
              {t("Cancel")}
            </StyledButton>
          </>
        }
      >
        <RiskFactorTemplateInformation riskFactorTemplate={riskFactorTemplate} isLoading={loading} />

        <HealthParameterCustomTableList
          healthParameters={riskFactorTemplateParameters}
          isLoading={loading}
          isRowDeletable={!isDeleting}
          onDeleteRow={onDeleteRowClick}
        />
      </PageWrapper>
    </>
  );
};
