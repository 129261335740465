import { GuidedCareActivityItemsForm, IGuidedCareActivityItemsFormEvent } from "@/pages/GuidedCare/forms";
import {
  convertGuidedCareActivityItemsFormValuesToBackEndValues,
  convertGuidedCareActivityItemsToFormValues,
} from "@/pages/GuidedCare/others";
import { guidedCareActivityTypeOptionsMap } from "@health/enum-options";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useNavigate, useParams } from "react-router-dom";
import { TemplateActivityEventItem } from "../../../../schema/types";
import {
  GuidedCareTemplateActivityItemsUpdateMutation,
  useGuidedCareTemplateActivityGetQuery,
  useGuidedCareTemplateActivityItemsUpdateMutation,
} from "../../gql";
import { useSetGuidedCareTemplatesBreadcrumbs } from "../../hooks";
import { IGuidedCareTemplateActivityItemsContainerParams } from "../../types";

export const GuidedCareTemplateActivityItemListContainer = () => {
  const { t } = useTranslation("gcadmin");
  const { failed, succeeded } = useAddToast();
  const navigate = useNavigate();

  const { activityId } = useParams<IGuidedCareTemplateActivityItemsContainerParams>();

  const { data, loading, refetch } = useGuidedCareTemplateActivityGetQuery({
    variables: {
      templateActivityId: activityId!,
    },
  });

  const templateActivity = data?.templateActivity;

  const templateActivityItems = convertGuidedCareActivityItemsToFormValues(
    templateActivity?.templateActivityEventItems as TemplateActivityEventItem[]
  );

  const [fetchGuidedCareTemplateActivityItemsUpdateMutation, { loading: isSubmitting }] = useGuidedCareTemplateActivityItemsUpdateMutation({
    onCompleted: (mutationData: GuidedCareTemplateActivityItemsUpdateMutation) => {
      const errors = mutationData?.updateTemplateActivityItems?.templateActivityErrors;

      if (errors?.length) {
        failed(t(formatMessageErrors(errors)));
      } else {
        succeeded(t("Activity items have been updated successfully"));
        navigate(-1);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
  });

  const onGuidedCareActivityItemsFormsChange = (event: IGuidedCareActivityItemsFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertGuidedCareActivityItemsFormValuesToBackEndValues(event.payload.values.activityItems);

      fetchGuidedCareTemplateActivityItemsUpdateMutation({
        variables: {
          activityId: activityId!,
          activityItems: values,
        },
      }).then(() => refetch());
    }
  };

  useSetGuidedCareTemplatesBreadcrumbs(
    "ACTIVITY_ITEM_LIST",
    guidedCareActivityTypeOptionsMap[templateActivity?.templateActivityType!]?.label
  );

  return (
    <GuidedCareActivityItemsForm
      activityItems={templateActivityItems}
      position={templateActivity?.templateTeamMember?.position!}
      activityType={templateActivity?.templateActivityType!}
      medicalMessage={templateActivity?.medicalMessage!}
      isLoading={loading || isSubmitting}
      onChange={onGuidedCareActivityItemsFormsChange}
    />
  );
};
