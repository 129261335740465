import { IGuidedCareActivityItemUpdateFormValues } from "@/pages/GuidedCare/forms";
import { createMedicalMessageAutocompleteOption } from "@health/autocompletes";
import { timeOfDayOptionsMap } from "@health/enum-options";
import { GuidedCareProgramActivityItem, TemplateActivityEventItem, TemplateInterventionActivityEventItem } from "../../../schema/types";

export const convertGuidedCareActivityItemsToFormValues = (
  activityItems?: (TemplateActivityEventItem | TemplateInterventionActivityEventItem | GuidedCareProgramActivityItem)[]
): IGuidedCareActivityItemUpdateFormValues[] => {
  return (
    activityItems?.map(item => ({
      id: item?.id!,
      offset: item?.offsetInDays!,
      time: timeOfDayOptionsMap[item?.timeOfDay!],
      beforeActivity: item?.beforeCallMessage ? createMedicalMessageAutocompleteOption(item?.beforeCallMessage) : undefined,
      beforeActivityOffset: item?.beforeCallMessageOffsetDays! || null,
      afterActivity: item?.afterCallMessage ? createMedicalMessageAutocompleteOption(item?.afterCallMessage) : undefined,
      afterActivityOffset: item?.afterCallMessageOffsetDays! || null,
    })) || []
  );
};

export const convertGuidedCareActivityItemsFormValuesToBackEndValues = (activityItems: IGuidedCareActivityItemUpdateFormValues[]) => {
  return activityItems.map(item => ({
    offsetInDays: item.offset,
    timeOfDay: item?.time?.value,
    beforeCallMessageId: item.beforeActivity?.value?.id,
    beforeCallMessageOffsetDays: item.beforeActivityOffset,
    afterCallMessageId: item.afterActivity?.value?.id,
    afterCallMessageOffsetDays: item.afterActivityOffset,
  }));
};
