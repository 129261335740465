import { makeStyles } from "@toolkit/ui";

export const useGuidedCareActivityItemsStyle = makeStyles()(theme => ({
  title: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  subtitle: {
    fontSize: 13,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
}));
