import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ArticleCategoryGetQueryVariables = Types.Exact<{
  articleCategoryId: Types.Scalars['ID']['input'];
}>;


export type ArticleCategoryGetQuery = { __typename?: 'Query', articleCategory?: { __typename?: 'ArticleCategory', id: string, code?: string | null, display?: string | null, displayAr?: string | null, icon?: string | null } | null };


export const ArticleCategoryGetDocument = gql`
    query ArticleCategoryGet($articleCategoryId: ID!) {
  articleCategory(id: $articleCategoryId) {
    id
    code
    display
    displayAr
    icon
  }
}
    `;

/**
 * __useArticleCategoryGetQuery__
 *
 * To run a query within a React component, call `useArticleCategoryGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleCategoryGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleCategoryGetQuery({
 *   variables: {
 *      articleCategoryId: // value for 'articleCategoryId'
 *   },
 * });
 */
export function useArticleCategoryGetQuery(baseOptions: Apollo.QueryHookOptions<ArticleCategoryGetQuery, ArticleCategoryGetQueryVariables> & ({ variables: ArticleCategoryGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArticleCategoryGetQuery, ArticleCategoryGetQueryVariables>(ArticleCategoryGetDocument, options);
      }
export function useArticleCategoryGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticleCategoryGetQuery, ArticleCategoryGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArticleCategoryGetQuery, ArticleCategoryGetQueryVariables>(ArticleCategoryGetDocument, options);
        }
export function useArticleCategoryGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ArticleCategoryGetQuery, ArticleCategoryGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ArticleCategoryGetQuery, ArticleCategoryGetQueryVariables>(ArticleCategoryGetDocument, options);
        }
export type ArticleCategoryGetQueryHookResult = ReturnType<typeof useArticleCategoryGetQuery>;
export type ArticleCategoryGetLazyQueryHookResult = ReturnType<typeof useArticleCategoryGetLazyQuery>;
export type ArticleCategoryGetSuspenseQueryHookResult = ReturnType<typeof useArticleCategoryGetSuspenseQuery>;
export type ArticleCategoryGetQueryResult = Apollo.QueryResult<ArticleCategoryGetQuery, ArticleCategoryGetQueryVariables>;