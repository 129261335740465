import { RiskFactorTemplate, RiskFactorTemplateInput, RiskFactorTemplateUpdateInput } from "@/schema/types";
import { IRiskFactorTemplateUpsertFormValues } from "../forms/RiskFactorTemplateUpsert/RiskFactorTemplateUpsertFormSchema";

export const convertRiskFactorTemplateToFormValues = (
  riskFactorTemplate: RiskFactorTemplate,
  isClone: boolean
): IRiskFactorTemplateUpsertFormValues => {
  return {
    display: riskFactorTemplate?.display!,
    arabicDisplay: riskFactorTemplate?.arabicDisplay!,
    code: isClone ? "" : riskFactorTemplate?.code!,
    isActive: !!riskFactorTemplate?.isActive,
  };
};

export const convertRiskFactorTemplateFormValuesToBackEndValues = (
  values: IRiskFactorTemplateUpsertFormValues
): RiskFactorTemplateInput => {
  return {
    display: values?.display!,
    arabicDisplay: values?.arabicDisplay!,
    code: values?.code!,
    isActive: values?.isActive,
  };
};

export const convertRiskFactorTemplateUpdateFormValuesToBackEndValues = (
  values: IRiskFactorTemplateUpsertFormValues
): RiskFactorTemplateUpdateInput => {
  return {
    display: values?.display!,
    arabicDisplay: values?.arabicDisplay!,
    isActive: values?.isActive,
  };
};
