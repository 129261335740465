import { H_OrderDirection, MedicalMessageVariant, MedicalMessageVariantSortField } from "@/schema/types";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import moment from "moment";
import { useMemo } from "react";
import { MedicalMessageVariantActivation } from "../../components";

export const useMedicalMessageVariantsTableColumns = (): CustomTableColumnProps<MedicalMessageVariant>[] => {
  const { t } = useTranslation("gcadmin");

  return useMemo(() => {
    return [
      {
        key: "id",
        header: t("id-as-number"),
        accessor: "id",
        isSortable: true,
        sortDirection: H_OrderDirection.Desc,
        sortColumnEnum: MedicalMessageVariantSortField.Id,
      },
      {
        key: "presentedTitleEnglish",
        header: t("Title (English)"),
        accessor: "presentedTitleEnglish",
        filter: {
          type: "string",
          name: "titleContains",
        },
      },
      {
        key: "presentedTitleArabic",
        header: t("Title (Arabic)"),
        accessor: "presentedTitleArabic",
      },
      {
        key: "sentCounter",
        header: t("Sent Count"),
        accessor: "sentCounter",
      },
      {
        key: "readCounter",
        header: t("Read Count"),
        accessor: "readCounter",
      },
      {
        key: "buttonTextEnglish",
        header: t("Button Text (English)"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "buttonText",
        },
      },
      {
        key: "createdDate",
        header: t("Created Date"),
        accessor: ({ createdDate }) => moment(createdDate).format("DD MMM YYYY"),
        isSortable: true,
        direction: H_OrderDirection.Desc,
        sortColumnEnum: MedicalMessageVariantSortField.CreatedDate,
      },
      {
        key: "lastModifiedDate",
        header: t("Last Modified Date"),
        accessor: ({ lastModifiedDate }) => moment(lastModifiedDate).format("DD MMM YYYY"),
        isSortable: true,
        direction: H_OrderDirection.Desc,
        sortColumnEnum: MedicalMessageVariantSortField.LastModifiedDate,
      },
      {
        key: "active",
        header: t("Active"),
        accessor: ({ id, isActive }) => <MedicalMessageVariantActivation id={id} isActive={!!isActive} />,
        isSortable: true,
        sortDirection: H_OrderDirection.Desc,
        sortColumnEnum: MedicalMessageVariantSortField.IsActive,
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
      },
    ];
  }, [t]);
};
