import { MedicalMessageCategory } from "@/schema/types";
import { MedicalMessagesAutocomplete } from "@health/autocompletes";
import { timeOfDayOptions } from "@health/enum-options";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, FormNumberField, Grid } from "@toolkit/ui";
import { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import {
  guidedCareActivityItemUpdateFormDefaultValues,
  guidedCareActivityItemUpdateFormSchema,
  IGuidedCareActivityItemUpdateFormValues,
} from "./GuidedCareActivityItemUpdateFormSchema";

export type IGuidedCareActivityItemUpdateFormEvent = {
  type: "UPDATE";
  payload: {
    values: IGuidedCareActivityItemUpdateFormValues;
  };
};

type GuidedCareActivityItemUpdateFormProps = {
  activityItem: IGuidedCareActivityItemUpdateFormValues;
  onChange: (event: IGuidedCareActivityItemUpdateFormEvent) => void;
};

export type GuidedCareActivityItemUpdateFormRef = {
  getForm: () => UseFormReturn<IGuidedCareActivityItemUpdateFormValues>;
  submit: () => void;
};

const GuidedCareActivityItemUpdateFormForwardRef: ForwardRefRenderFunction<
  GuidedCareActivityItemUpdateFormRef,
  GuidedCareActivityItemUpdateFormProps
> = (props, ref) => {
  const { activityItem, onChange } = props;

  const { t } = useTranslation("gcadmin");

  const form = useCustomForm<IGuidedCareActivityItemUpdateFormValues>({
    defaultValues: guidedCareActivityItemUpdateFormDefaultValues,
    schema: guidedCareActivityItemUpdateFormSchema,
  });

  const { setValues, handleSubmit } = form;

  const onSubmit = (values: IGuidedCareActivityItemUpdateFormValues) => {
    onChange({
      type: "UPDATE",
      payload: { values },
    });
  };

  useImperativeHandle(ref, () => ({
    getForm: () => form,
    submit: handleSubmit(onSubmit),
  }));

  useEffect(() => {
    if (activityItem) {
      setValues(activityItem);
    }
  }, [activityItem, setValues]);

  return (
    <FormProvider {...form}>
      <form>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={6}>
            <FormNumberField name={"offset"} label={t("Offset (Days)")} />
          </Grid>

          <Grid item xs={6}>
            <FormAutocomplete name={"time"} label={t("Time")} options={timeOfDayOptions} />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <MedicalMessagesAutocomplete
                  name={"beforeActivity"}
                  label={t("Before Activity")}
                  filter={{ category: MedicalMessageCategory.Instruction, isPublished: true }}
                />
              </Grid>

              <Grid item xs={6}>
                <MedicalMessagesAutocomplete
                  name={"afterActivity"}
                  label={t("After Activity")}
                  filter={{ category: MedicalMessageCategory.Instruction, isPublished: true }}
                />
              </Grid>

              <Grid item xs={6}>
                <FormNumberField name={"beforeActivityOffset"} label={t("Before Activity - Offset (Days)")} />
              </Grid>

              <Grid item xs={6}>
                <FormNumberField name={"afterActivityOffset"} label={t("After Activity - Offset (Days)")} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export const GuidedCareActivityItemUpdateForm = forwardRef(GuidedCareActivityItemUpdateFormForwardRef);
