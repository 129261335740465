import { CodeSystemCode, HealthSymptom, HealthSymptomOrderField } from "@/schema/types";
import { getSystemCodeAutocompleteFilter } from "@health/autocompletes";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, OrderDirection, TruncateTypography, format } from "@toolkit/ui";
import { useMemo } from "react";
import { HealthSymptomsSpecializationsModal } from "../../modals/HealthSymptomsSpecializations/HealthSymptomsSpecializationsModal";

export const useHealthSymptomListContainerColumns = (): CustomTableColumnProps<HealthSymptom>[] => {
  const { t } = useTranslation("gcadmin");

  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        accessor: ({ name }) => <TruncateTypography text={name!} />,
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: HealthSymptomOrderField.Name,
        filter: {
          type: "string",
          name: "name_Icontains",
        },
      },
      {
        key: "specializations",
        header: t("Specializations"),
        accessor: ({ specializations }) => <HealthSymptomsSpecializationsModal specializations={specializations} />,
        filter: {
          ...getSystemCodeAutocompleteFilter({
            name: "specializations",
            multiple: true,
            isCodeHidden: true,
            queryVariables: { codeSystemCode: CodeSystemCode.Speciality },
          }),
        },
      },
      {
        key: "created",
        header: t("Created"),
        accessor: row => format(row?.created),
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: HealthSymptomOrderField.Created,
      },
    ];
  }, [t]);
};
