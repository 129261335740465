import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareTemplateInterventionActivityGetQueryVariables = Types.Exact<{
  activityId: Types.Scalars['ID']['input'];
}>;


export type GuidedCareTemplateInterventionActivityGetQuery = { __typename?: 'Query', templateInterventionActivity?: { __typename?: 'TemplateInterventionActivity', templateActivityType?: Types.GuidedCareActivityType | null, templateTeamMember?: { __typename?: 'TemplateTeamMember', position?: Types.TeamMemberPosition | null, specialization?: string | null } | null, medicalMessage?: { __typename?: 'MedicalMessage', code?: string | null, name?: string | null } | null, interventionActivityEventItems?: Array<{ __typename?: 'TemplateInterventionActivityEventItem', id: string, offsetInDays?: number | null, timeOfDay?: Types.TimeOfDay | null, beforeCallMessageOffsetDays?: number | null, afterCallMessageOffsetDays?: number | null, beforeCallMessage?: { __typename?: 'MedicalMessage', id: string, code?: string | null, name?: string | null } | null, afterCallMessage?: { __typename?: 'MedicalMessage', id: string, code?: string | null, name?: string | null } | null } | null> | null } | null };


export const GuidedCareTemplateInterventionActivityGetDocument = gql`
    query GuidedCareTemplateInterventionActivityGet($activityId: ID!) {
  templateInterventionActivity(id: $activityId) {
    templateActivityType
    templateTeamMember {
      position
      specialization
    }
    medicalMessage {
      code
      name
    }
    interventionActivityEventItems {
      id
      offsetInDays
      timeOfDay
      beforeCallMessageOffsetDays
      beforeCallMessage {
        id
        code
        name
      }
      afterCallMessage {
        id
        code
        name
      }
      afterCallMessageOffsetDays
    }
  }
}
    `;

/**
 * __useGuidedCareTemplateInterventionActivityGetQuery__
 *
 * To run a query within a React component, call `useGuidedCareTemplateInterventionActivityGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareTemplateInterventionActivityGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuidedCareTemplateInterventionActivityGetQuery({
 *   variables: {
 *      activityId: // value for 'activityId'
 *   },
 * });
 */
export function useGuidedCareTemplateInterventionActivityGetQuery(baseOptions: Apollo.QueryHookOptions<GuidedCareTemplateInterventionActivityGetQuery, GuidedCareTemplateInterventionActivityGetQueryVariables> & ({ variables: GuidedCareTemplateInterventionActivityGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuidedCareTemplateInterventionActivityGetQuery, GuidedCareTemplateInterventionActivityGetQueryVariables>(GuidedCareTemplateInterventionActivityGetDocument, options);
      }
export function useGuidedCareTemplateInterventionActivityGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuidedCareTemplateInterventionActivityGetQuery, GuidedCareTemplateInterventionActivityGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuidedCareTemplateInterventionActivityGetQuery, GuidedCareTemplateInterventionActivityGetQueryVariables>(GuidedCareTemplateInterventionActivityGetDocument, options);
        }
export function useGuidedCareTemplateInterventionActivityGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GuidedCareTemplateInterventionActivityGetQuery, GuidedCareTemplateInterventionActivityGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GuidedCareTemplateInterventionActivityGetQuery, GuidedCareTemplateInterventionActivityGetQueryVariables>(GuidedCareTemplateInterventionActivityGetDocument, options);
        }
export type GuidedCareTemplateInterventionActivityGetQueryHookResult = ReturnType<typeof useGuidedCareTemplateInterventionActivityGetQuery>;
export type GuidedCareTemplateInterventionActivityGetLazyQueryHookResult = ReturnType<typeof useGuidedCareTemplateInterventionActivityGetLazyQuery>;
export type GuidedCareTemplateInterventionActivityGetSuspenseQueryHookResult = ReturnType<typeof useGuidedCareTemplateInterventionActivityGetSuspenseQuery>;
export type GuidedCareTemplateInterventionActivityGetQueryResult = Apollo.QueryResult<GuidedCareTemplateInterventionActivityGetQuery, GuidedCareTemplateInterventionActivityGetQueryVariables>;