import { useTranslation } from "@toolkit/i18n";
import { CustomDialog } from "@toolkit/ui";
import { FC, useEffect, useRef, useState } from "react";
import {
  GuidedCareTemplateInterventionActivityUpsertForm,
  GuidedCareTemplateInterventionActivityUpsertFormRef,
  IGuidedCareTemplateInterventionActivityUpsertFormEvent,
} from "../../forms/TemplateInterventionActivityUpsert/GuidedCareTemplateInterventionActivityUpsertForm";
import { IGuidedCareTemplateInterventionActivityItem } from "../../types";
import { useOpenState } from "@toolkit/core";

type IGuidedCareTemplateInterventionActivityUpsertModalData = {
  interventionIndex: number;
  activity?: IGuidedCareTemplateInterventionActivityItem;
};

type IOpen = (data: IGuidedCareTemplateInterventionActivityUpsertModalData) => void;
type IClose = () => void;

export const GuidedCareTemplateInterventionActivityUpsertModalApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

type GuidedCareTemplateInterventionActivityUpsertModalProps = {
  onChange: (event: IGuidedCareTemplateInterventionActivityUpsertFormEvent) => void;
};

export const GuidedCareTemplateInterventionActivityUpsertModal: FC<GuidedCareTemplateInterventionActivityUpsertModalProps> = props => {
  const { onChange } = props;

  const [data, setData] = useState<IGuidedCareTemplateInterventionActivityUpsertModalData>();

  const { t } = useTranslation("gcadmin");

  const { open: isOpen, handleOpen, handleClose } = useOpenState();

  const templateInterventionActivityUpsertFormRef = useRef<GuidedCareTemplateInterventionActivityUpsertFormRef>(null);

  const onButtonClick = () => {
    templateInterventionActivityUpsertFormRef.current?.submit();
  };

  const open: IOpen = _data => {
    handleOpen();
    setData(_data);
  };

  useEffect(() => {
    GuidedCareTemplateInterventionActivityUpsertModalApi.open = open;
    GuidedCareTemplateInterventionActivityUpsertModalApi.close = handleClose;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      DialogTitleProps={{
        title: data?.activity ? t("Update Activity") : t("Create Activity"),
        onClose: handleClose,
      }}
      DialogActionsProps={{
        hasClose: true,
        submitTitle: data?.activity ? t("Update") : t("Add"),
        onSubmit: onButtonClick,
      }}
    >
      <GuidedCareTemplateInterventionActivityUpsertForm
        interventionIndex={data?.interventionIndex!}
        activity={data?.activity}
        ref={templateInterventionActivityUpsertFormRef}
        onChange={onChange}
      />
    </CustomDialog>
  );
};
