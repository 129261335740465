/* eslint-disable react-hooks/rules-of-hooks */
import { PermissionEnum } from "@/schema/types";
import { hasPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { ByPatientIcon, useTheme } from "@toolkit/ui";
import {
  MedicalMessageCreateContainer,
  MedicalMessageUpdateContainer,
  MedicalMessageVariantCreateContainer,
  MedicalMessageVariantUpdateContainer,
  MedicalMessageVariantsContainer,
  MedicalMessagesContainer,
} from "pages/MedicalMessages/containers";
import { NavigateFunction } from "react-router-dom";
import { RouteItem } from "shared/components";
import { medicalMessagesPaths, medicalMessagesRoute } from "./MedicalMessagesPaths";

export const medicalMessagesRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }): RouteItem => {
  const theme = useTheme();
  return {
    id: "medical-messages",
    text: t("Patient Engagement Messages", { ns: "gcadmin" }),
    icon: (
      <ByPatientIcon
        sx={{
          path: {
            fill: theme.palette.primary.main,
          },
        }}
      />
    ),
    route: medicalMessagesRoute,
    fullPath: medicalMessagesRoute,
    onClick: (route: string) => navigate(route),
    isProhibited: !hasPermission(PermissionEnum.ManageHealthMessages),
    subItems: [
      {
        id: "medical-message-list",
        text: "",
        route: medicalMessagesPaths.main.route,
        fullPath: medicalMessagesPaths.main.fullPath,
        element: <MedicalMessagesContainer />,
        hidden: true,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "medical-message-create",
        text: "",
        route: medicalMessagesPaths.create.route,
        fullPath: medicalMessagesPaths.create.fullPath,
        element: <MedicalMessageCreateContainer />,
        hidden: true,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "medical-message-update",
        text: "",
        route: medicalMessagesPaths.update.route,
        fullPath: medicalMessagesPaths.update.fullPath,
        element: <MedicalMessageUpdateContainer />,
        hidden: true,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "medical-message-variant-list",
        text: "",
        route: medicalMessagesPaths.variants.route,
        fullPath: medicalMessagesPaths.variants.fullPath,
        element: <MedicalMessageVariantsContainer />,
        hidden: true,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "medical-message-variant-create",
        text: "",
        route: medicalMessagesPaths.variantCreate.route,
        fullPath: medicalMessagesPaths.variantCreate.fullPath,
        element: <MedicalMessageVariantCreateContainer />,
        hidden: true,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "medical-message-variant-update",
        text: "",
        route: medicalMessagesPaths.variantUpdate.route,
        fullPath: medicalMessagesPaths.variantUpdate.fullPath,
        element: <MedicalMessageVariantUpdateContainer />,
        hidden: true,
        onClick: (route: string) => navigate(route),
      },
    ],
  };
};
