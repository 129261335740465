import { H_OrderDirection, TemplateActivityEventItemSortingField } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, FormAutocomplete, FormNumberField } from "@toolkit/ui";
import { useMemo } from "react";
import { getGuidedCareTemplateActivitiesItemProperties } from "../../others";
import { IGuidedCareTemplateActivitiesItem } from "../../types";
import { timeOfDayOptions } from "@health/enum-options";

export const useGuidedCareTemplateActivitiesItemsColumns = (): CustomTableColumnProps<IGuidedCareTemplateActivitiesItem>[] => {
  const { t } = useTranslation("gcadmin");

  return useMemo(() => {
    return [
      {
        key: "activity",
        header: t("Activity"),
        accessor: ({ activityType }) => activityType?.label,
      },
      {
        key: "name",
        header: t("Name"),
        accessor: item => {
          const itemProperties = getGuidedCareTemplateActivitiesItemProperties(item);
          return itemProperties?.name || "-";
        },
      },
      {
        key: "code",
        header: t("Code"),
        accessor: item => {
          const itemProperties = getGuidedCareTemplateActivitiesItemProperties(item);
          return itemProperties?.code || "-";
        },
      },
      {
        key: "offset",
        header: t("Offset (Days)"),
        accessor: (_, index) => <FormNumberField name={`activitiesItems.${index}.offset`} label={t("Offset")} placeholder={t("Offset")} />,
        isSortable: true,
        sortDirection: H_OrderDirection.Desc,
        sortColumnEnum: TemplateActivityEventItemSortingField.Offset,
      },
      {
        key: "time",
        header: t("Time"),
        accessor: (_, index) => (
          <FormAutocomplete name={`activitiesItems.${index}.timeOfDay`} label={t("Time")} options={timeOfDayOptions} />
        ),
      },
    ];
  }, [t]);
};
