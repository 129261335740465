import { Article, ArticleInput } from "@/schema/types";
import { pickLocalizedValue } from "@toolkit/i18n";
import { createAutocompleteOption } from "@toolkit/ui";
import { IArticleUpsertFormValues } from "../forms/ArticleUpsert/ArticleUpsertFormSchema";

export const convertArticleToFormValues = (article: Article): IArticleUpsertFormValues => {
  return {
    title: article.title!,
    titleAr: article.titleAr!,
    category: createAutocompleteOption(
      { id: article?.category?.id!, display: article?.category?.display!, displayAr: article?.category?.displayAr! },
      "id",
      item => pickLocalizedValue(item?.display, item?.displayAr!)
    ),
    readTimeMinutes: article.readTimeMinutes!,
    tags: article?.tags?.length ? (article?.tags as string[]) : [],
    relatedArticles: article.relatedArticles?.length
      ? article?.relatedArticles?.map(item => createAutocompleteOption({ id: item?.id!, title: item?.title! }, "id", "title"))
      : [],
    active: article.active!,
    description: article.description!,
    descriptionAr: article.descriptionAr!,
    references: article.references?.map(s => s as string)! || [],
    content: article.content!,
    contentAr: article.contentAr!,
    headerImage: article.headerImage!,
    priority: article.priority!,
  };
};

export const convertArticleFormValuesToBackEndValues = (values: IArticleUpsertFormValues): ArticleInput => {
  return {
    title: values?.title!,
    titleAr: values?.titleAr!,
    categoryId: values?.category?.value?.id!,
    readTimeMinutes: values?.readTimeMinutes!,
    tags: values?.tags!,
    relatedArticles: values?.relatedArticles!.map(article => article.value.id),
    active: values?.active!,
    references: values?.references! || [],
    description: values?.description!,
    descriptionAr: values?.descriptionAr!,
    content: values?.content!,
    contentAr: values?.contentAr!,
    headerImage: values?.headerImage!,
    priority: values?.priority!,
  };
};
