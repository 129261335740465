import { useTranslation } from "@toolkit/i18n";
import { CustomDialog } from "@toolkit/ui";
import React, { FC, useEffect, useState } from "react";
import { useOpenState } from "@toolkit/core";

export type IGuidedCareTemplateGoalDeleteConfirmationModalEvent = {
  type: "GOAL_DELETE" | "RECOMMENDATION_DELETE";
};

type IGuidedCareTemplateGoalDeleteConfirmationModalData = {
  type: "GOAL" | "RECOMMENDATION";
};

type IOpen = (data: IGuidedCareTemplateGoalDeleteConfirmationModalData) => void;
type IClose = () => void;

export const GuidedCareTemplateGoalDeleteConfirmationModalApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

type GuidedCareTemplateActivityUpsertModalProps = {
  onChange: (event: IGuidedCareTemplateGoalDeleteConfirmationModalEvent) => void;
};

export const GuidedCareTemplateGoalDeleteConfirmationModal: FC<GuidedCareTemplateActivityUpsertModalProps> = props => {
  const { onChange } = props;

  const [data, setData] = useState<IGuidedCareTemplateGoalDeleteConfirmationModalData>();

  const { t } = useTranslation("gcadmin");

  const { open: isOpen, handleOpen, handleClose } = useOpenState();

  const getCustomDialogText = () => {
    if (data?.type === "GOAL") {
      return {
        title: t("Delete Goal Confirmation"),
        body: t("Please be aware that deleting this goal will also remove all associated recommendations and their interventions"),
      };
    } else if (data?.type === "RECOMMENDATION") {
      return {
        title: t("Delete Recommendation Confirmation"),
        body: t("Please be aware that deleting this recommendation will also remove all associated interventions"),
      };
    }
  };

  const onCustomDialogDelete = () => {
    if (data?.type === "GOAL") {
      onChange({
        type: "GOAL_DELETE",
      });
    } else if (data?.type === "RECOMMENDATION") {
      onChange({
        type: "RECOMMENDATION_DELETE",
      });
    }
  };

  const open: IOpen = _data => {
    handleOpen();
    setData(_data);
  };

  useEffect(() => {
    GuidedCareTemplateGoalDeleteConfirmationModalApi.open = open;
    GuidedCareTemplateGoalDeleteConfirmationModalApi.close = handleClose;
  }, []);

  return (
    <CustomDialog
      type={"delete"}
      isOpen={isOpen}
      text={{ ...getCustomDialogText() }}
      onDelete={onCustomDialogDelete}
      onClose={handleClose}
    />
  );
};
