import { GuidedCareActivityItemsForm, IGuidedCareActivityItemsFormEvent } from "@/pages/GuidedCare/forms";
import {
  convertGuidedCareActivityItemsFormValuesToBackEndValues,
  convertGuidedCareActivityItemsToFormValues,
} from "@/pages/GuidedCare/others";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GuidedCareProgramActivityItem } from "../../../../schema/types";
import {
  GuidedCareProgramActivityItemsUpdateMutation,
  useGuidedCareProgramActivityGetQuery,
  useGuidedCareProgramActivityItemsUpdateMutation,
} from "../../gql";
import { useGuidedCareProgramsBreadcrumbs } from "../../hooks";
import { IGuidedCareProgramActivityItemListContainerParams } from "../../types";

export const GuidedCareProgramActivityItemListContainer = () => {
  const { t } = useTranslation("gcadmin");
  const { failed, succeeded } = useAddToast();
  const navigate = useNavigate();

  const { setGuidedCareProgramActivityItemListContainerBreadcrumb } = useGuidedCareProgramsBreadcrumbs();

  const { activityId } = useParams<IGuidedCareProgramActivityItemListContainerParams>();

  const { data, loading, refetch } = useGuidedCareProgramActivityGetQuery({
    variables: {
      guidedCareHealthProgramActivityId: activityId!,
    },
  });

  const programActivity = data?.guidedCareHealthProgramActivity;

  const programActivityItems = convertGuidedCareActivityItemsToFormValues(
    programActivity?.guidedCareProgramActivityItems as GuidedCareProgramActivityItem[]
  );

  const [fetchGuidedCareProgramActivityItemsUpdateMutation, { loading: isSubmitting }] = useGuidedCareProgramActivityItemsUpdateMutation({
    onCompleted: (mutationData: GuidedCareProgramActivityItemsUpdateMutation) => {
      const errors = mutationData?.updateGuidedCareHealthProgramActivityItems?.guidedCareHealthProgramActivityErrors;

      if (errors?.length) {
        failed(t(formatMessageErrors(errors)));
      } else {
        succeeded(t("Activity items have been updated successfully"));
        navigate(-1);
      }
    },
    onError: () => {
      failed(t("Activity items failed to update"));
    },
  });

  const onGuidedCareActivityItemsFormChange = (event: IGuidedCareActivityItemsFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertGuidedCareActivityItemsFormValuesToBackEndValues(event.payload.values.activityItems);

      fetchGuidedCareProgramActivityItemsUpdateMutation({
        variables: {
          activityId: activityId!,
          activityItems: values,
        },
      }).then(() => refetch());
    }
  };

  useEffect(() => {
    setGuidedCareProgramActivityItemListContainerBreadcrumb();
  }, [setGuidedCareProgramActivityItemListContainerBreadcrumb]);

  return (
    <GuidedCareActivityItemsForm
      activityItems={programActivityItems}
      position={programActivity?.guidedCareProgramTeamMember?.position!}
      activityType={programActivity?.templateActivityType!}
      medicalMessage={programActivity?.templateActivity?.medicalMessage!}
      isLoading={loading || isSubmitting}
      onChange={onGuidedCareActivityItemsFormChange}
    />
  );
};
