import { LabTemplate, LabTemplateSortingField } from "@/schema/types";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Button, CustomIcon, CustomTableColumnProps, ShowButton, formatDate } from "@toolkit/ui";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { LabTemplateActivation } from "../../components/LabTemplateActivation/LabTemplateActivation";
import { labTemplatesPaths } from "../../constants";

export type LabTemplatesColumnTypes = CustomTableColumnProps<LabTemplate>;

export const useLabTemplateListContainerColumns = (): LabTemplatesColumnTypes[] => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();

  const onShowParametersClick = useCallback(
    (id: string) => {
      navigate(labTemplatesPaths.parameterList.fullPathWithParams({ labTemplateId: id }));
    },
    [navigate]
  );

  const onCloneClick = useCallback(
    (id: string) => {
      navigate(labTemplatesPaths.clone.fullPathWithParams({ labTemplateId: id }));
    },
    [navigate]
  );

  return useMemo(() => {
    return [
      {
        key: "display",
        header: t("Display"),
        accessor: ({ display, arabicDisplay }) => pickLocalizedValue(display!, arabicDisplay!),
        isSortable: true,
        sortDirection: undefined,
        sortColumnEnum: pickLocalizedValue(LabTemplateSortingField.Display, LabTemplateSortingField.ArabicDisplay),
        filter: {
          type: "string",
          name: pickLocalizedValue("display", "arabicDisplay"),
        },
      },
      {
        key: "code",
        header: t("Code"),
        accessor: "labCodeDisplay",
        isSortable: true,
        sortDirection: undefined,
        sortColumnEnum: LabTemplateSortingField.Code,
        filter: {
          type: "string",
          name: "code",
        },
      },
      {
        key: "createdDate",
        header: t("Created Date"),
        accessor: ({ createdDate }) => formatDate(createdDate!),
        isSortable: true,
        sortDirection: undefined,
        sortColumnEnum: LabTemplateSortingField.CreatedDate,
      },
      {
        key: "fields",
        header: t("Parameters"),
        accessor: ({ id }) => <ShowButton variant={"text"} onClick={() => onShowParametersClick(id!)} />,
      },
      {
        key: "Clone",
        header: t("Clone"),
        accessor: ({ id }) => (
          <Button startIcon={<CustomIcon icon={"clone"} />} variant={"text"} onClick={() => onCloneClick(id!)}>
            {t("Clone")}
          </Button>
        ),
      },
      {
        key: "active",
        header: t("Active"),
        accessor: ({ id, isActive }) => <LabTemplateActivation id={id!} isActive={!!isActive} />,
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
      },
    ];
  }, [onCloneClick, onShowParametersClick, t]);
};
