/* eslint-disable max-lines */
import { GuidedCareActivityType, MedicalMessageCategory } from "@/schema/types";
import { MedicalFormsClassificationsAutocomplete, MedicalMessagesAutocomplete } from "@health/autocompletes";
import {
  appointmentFrequencyTypeOptions,
  guidedCareActivityTypeTemplateOptions,
  isGuidedCareActivityTypeMedicalMessage,
} from "@health/enum-options";
import { useCustomForm, useCustomFormContext } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, FormNumberField, Grid } from "@toolkit/ui";
import {
  filterTeamMembersByActivityType,
  getMedicalMessageCategory,
  shouldTemplateActivityMessagesAndOffsetsShow,
} from "pages/Templates/others";
import { IGuidedCareTemplateActivityUpsertFormEvent } from "pages/Templates/types";
import { ForwardRefRenderFunction, forwardRef, useEffect, useImperativeHandle } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { GuidedCareTemplateActivityMedicalFormClassificationsInterventionsUpsertForm } from "../TemplateActivityMedicalFormClassificationsInterventionsUpsert/GuidedCareTemplateActivityMedicalFormClassificationsInterventionsUpsertForm";
import { IGuidedCareTemplateUpsertFormValues } from "../TemplateUpsert/GuidedCareTemplateUpsertFormSchema";
import {
  GuidedCareTemplateActivityUpsertFormSchema,
  IGuidedCareTemplateActivityUpsertFormValues,
  guidedCareTemplateActivityUpsertFormDefaultValues,
} from "./GuidedCareTemplateActivityUpsertFormSchema";

type GuidedCareTemplateActivityUpsertFormProps = {
  activity?: IGuidedCareTemplateActivityUpsertFormValues;
  onChange: (event: IGuidedCareTemplateActivityUpsertFormEvent) => void;
};

export type GuidedCareTemplateActivityUpsertFormRef = {
  getForm: () => UseFormReturn<IGuidedCareTemplateActivityUpsertFormValues>;
  submit: () => void;
};

const GuidedCareTemplateActivityUpsertFormForwardRef: ForwardRefRenderFunction<
  GuidedCareTemplateActivityUpsertFormRef,
  GuidedCareTemplateActivityUpsertFormProps
> = (props, ref) => {
  const { activity, onChange } = props;

  const { t } = useTranslation("gcadmin");

  const formContext = useCustomFormContext<IGuidedCareTemplateUpsertFormValues>();
  const { teamMembers, interventions } = formContext.getValues();

  const form = useCustomForm<IGuidedCareTemplateActivityUpsertFormValues>({
    defaultValues: guidedCareTemplateActivityUpsertFormDefaultValues,
    schema: GuidedCareTemplateActivityUpsertFormSchema,
  });

  const { watch, setValue, setValues, handleSubmit } = form;

  const templateActivityType = watch("templateActivityType");
  const templateActivityTypeValue = templateActivityType?.value;
  const medicalForm = watch("medicalForm")?.value;

  const showTemplateActivityMessagesAndOffsets = shouldTemplateActivityMessagesAndOffsetsShow(templateActivityTypeValue);
  const filteredTeamMembers = filterTeamMembersByActivityType(teamMembers, templateActivityTypeValue);
  const medicalMessageCategory = getMedicalMessageCategory(templateActivityTypeValue);
  const medicalMessageActivity = isGuidedCareActivityTypeMedicalMessage(templateActivityType?.key!);

  const onSubmit = (values: IGuidedCareTemplateActivityUpsertFormValues) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { activityServices, ...rest } = values;

    if (activity) {
      onChange({
        type: "UPDATE",
        payload: { values: rest },
      });
    } else {
      onChange({
        type: "CREATE",
        payload: { values },
      });
    }
  };

  useImperativeHandle(ref, () => ({
    getForm: () => form,
    submit: handleSubmit(onSubmit),
  }));

  useEffect(() => {
    if (activity?.medicalForm) return;
    setValue("medicalForm", undefined);
  }, [activity?.medicalForm, setValue, templateActivityTypeValue]);

  useEffect(() => {
    if (activity?.medicalFormClassificationsInterventions?.length) return;
    setValue("medicalFormClassificationsInterventions", []);
  }, [setValue, medicalForm, activity?.medicalFormClassificationsInterventions?.length]);

  useEffect(() => {
    const medicalFormClassificationsInterventions = watch("medicalFormClassificationsInterventions");

    if (medicalFormClassificationsInterventions?.length) return;

    if (medicalForm) {
      const _medicalFormClassificationsInterventions =
        medicalForm?.medicalFormClassifications?.map(item => ({
          classification: item?.name!,
          intervention: undefined,
        })) || [];

      setValue("medicalFormClassificationsInterventions", _medicalFormClassificationsInterventions);
    }
  }, [medicalForm, setValue, watch]);

  useEffect(() => {
    if (activity) {
      setValues(activity);
    }
  }, [activity, setValues]);

  return (
    <FormProvider {...form}>
      <form>
        <Grid container xs={12} spacing={2}>
          <Grid item xs={6}>
            <FormAutocomplete name={"templateActivityType"} label={t("Activity Type")} options={guidedCareActivityTypeTemplateOptions} />
          </Grid>

          <Grid item xs={6}>
            <FormAutocomplete
              name={"teamMember"}
              label={t("Assignee")}
              disabled={!templateActivityTypeValue}
              options={filteredTeamMembers}
            />
          </Grid>

          {templateActivityTypeValue === GuidedCareActivityType.MedicalForm && (
            <Grid item xs={12}>
              <MedicalFormsClassificationsAutocomplete name={"medicalForm"} filter={{ isAdminForm: true, isActive: true }} />
            </Grid>
          )}

          {medicalMessageActivity && (
            <Grid item xs={12}>
              <MedicalMessagesAutocomplete name={"medicalMessage"} filter={{ category: medicalMessageCategory, isPublished: true }} />
            </Grid>
          )}

          <Grid item xs={6}>
            <FormNumberField name={"numberOfOccurrences"} label={t("Occurrences")} />
          </Grid>

          <Grid item xs={6}>
            <FormAutocomplete name={"frequencyType"} label={t("Frequency")} options={appointmentFrequencyTypeOptions} />
          </Grid>

          {showTemplateActivityMessagesAndOffsets && (
            <>
              <Grid item xs={6}>
                <MedicalMessagesAutocomplete
                  name={"beforeActivity"}
                  label={t("Before Activity")}
                  filter={{ category: MedicalMessageCategory.Instruction, isPublished: true }}
                />
              </Grid>

              <Grid item xs={6}>
                <MedicalMessagesAutocomplete
                  name={"afterActivity"}
                  label={t("After Activity")}
                  filter={{ category: MedicalMessageCategory.Instruction, isPublished: true }}
                />
              </Grid>

              <Grid item xs={6}>
                <FormNumberField name={"beforeActivityOffset"} label={t("Before Activity - Offset (Days)")} />
              </Grid>

              <Grid item xs={6}>
                <FormNumberField name={"afterActivityOffset"} label={t("After Activity - Offset (Days)")} />
              </Grid>
            </>
          )}

          {templateActivityTypeValue === GuidedCareActivityType.MedicalForm && (
            <Grid item xs={12}>
              <GuidedCareTemplateActivityMedicalFormClassificationsInterventionsUpsertForm interventions={interventions} />
            </Grid>
          )}
        </Grid>
      </form>
    </FormProvider>
  );
};

export const GuidedCareTemplateActivityUpsertForm = forwardRef(GuidedCareTemplateActivityUpsertFormForwardRef);
