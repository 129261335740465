import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthConditionParameterCreateMutationVariables = Types.Exact<{
  healthConditionId: Types.Scalars['ID']['input'];
  healthParameterId: Types.Scalars['ID']['input'];
}>;


export type HealthConditionParameterCreateMutation = { __typename?: 'Mutation', addFieldToHealthCondition?: { __typename?: 'HealthCondition', errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', field?: string | null, message?: string | null } | null> | null } | null };


export const HealthConditionParameterCreateDocument = gql`
    mutation HealthConditionParameterCreate($healthConditionId: ID!, $healthParameterId: ID!) {
  addFieldToHealthCondition(
    healthConditionId: $healthConditionId
    healthParameterId: $healthParameterId
  ) {
    errors {
      field
      message
    }
  }
}
    `;
export type HealthConditionParameterCreateMutationFn = Apollo.MutationFunction<HealthConditionParameterCreateMutation, HealthConditionParameterCreateMutationVariables>;

/**
 * __useHealthConditionParameterCreateMutation__
 *
 * To run a mutation, you first call `useHealthConditionParameterCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthConditionParameterCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthConditionParameterCreateMutation, { data, loading, error }] = useHealthConditionParameterCreateMutation({
 *   variables: {
 *      healthConditionId: // value for 'healthConditionId'
 *      healthParameterId: // value for 'healthParameterId'
 *   },
 * });
 */
export function useHealthConditionParameterCreateMutation(baseOptions?: Apollo.MutationHookOptions<HealthConditionParameterCreateMutation, HealthConditionParameterCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthConditionParameterCreateMutation, HealthConditionParameterCreateMutationVariables>(HealthConditionParameterCreateDocument, options);
      }
export type HealthConditionParameterCreateMutationHookResult = ReturnType<typeof useHealthConditionParameterCreateMutation>;
export type HealthConditionParameterCreateMutationResult = Apollo.MutationResult<HealthConditionParameterCreateMutation>;
export type HealthConditionParameterCreateMutationOptions = Apollo.BaseMutationOptions<HealthConditionParameterCreateMutation, HealthConditionParameterCreateMutationVariables>;