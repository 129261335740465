import { MedicalMessageVariant } from "@/schema/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { createMedicalMessagesBreadcrumbs, medicalMessagesPaths } from "pages/MedicalMessages/constants";
import { MedicalMessageVariantUpsertForm } from "pages/MedicalMessages/forms";
import {
  MedicalMessageUpdateMutation,
  MedicalMessageVariantGetDocument,
  useMedicalMessageGetQuery,
  useMedicalMessageVariantGetQuery,
  useMedicalMessageVariantUpdateMutation,
} from "pages/MedicalMessages/gql";
import { convertMedicalMessageVariantFormValuesToBackEndValues } from "pages/MedicalMessages/others";
import { IMedicalMessageVariantUpdateContainerParams, IMedicalMessageVariantUpsertFormEvent } from "pages/MedicalMessages/types";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const MedicalMessageVariantUpdateContainer = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();
  const { setBreadCrumb } = useBreadCrumbs();

  const { medicalMessageId, medicalMessageVariantId } = useParams<IMedicalMessageVariantUpdateContainerParams>();

  const { data: medicalMessageData, loading: isMedicalMessageLoading } = useMedicalMessageGetQuery({
    variables: { medicalMessageId: medicalMessageId! },
    skip: !medicalMessageId,
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
  });

  const medicalMessageType = medicalMessageData?.medicalMessage?.medicalMessageType;

  const { data, loading } = useMedicalMessageVariantGetQuery({
    variables: { medicalMessageVariantId: medicalMessageVariantId! },
    skip: !medicalMessageVariantId,
  });

  const medicalMessageVariant = data?.medicalMessageVariant as MedicalMessageVariant;

  const [fetchMedicalMessageVariantUpdateMutation, { loading: isSubmitting }] = useMedicalMessageVariantUpdateMutation({
    onCompleted: (mutationData: MedicalMessageUpdateMutation) => {
      const mutationErrors = mutationData?.medicalMessageUpdate?.medicalMessageErrors;
      if (!mutationErrors?.length) {
        succeeded(t("Medical Message updated successfully"));
        navigate(medicalMessagesPaths.variants.fullPathWithParams({ medicalMessageId: medicalMessageId! }));
      } else {
        failed(t(formatMessageErrors(mutationErrors)));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
    refetchQueries: [MedicalMessageVariantGetDocument],
  });

  const onMedicalMessageVariantUpsertFormChange = (event: IMedicalMessageVariantUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertMedicalMessageVariantFormValuesToBackEndValues(event.payload.values, medicalMessageType!);

      fetchMedicalMessageVariantUpdateMutation({
        variables: {
          medicalMessageVariantUpdateId: medicalMessageVariantId!,
          input: {
            ...values,
            medicalMessageID: medicalMessageId,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (medicalMessageId && medicalMessageVariant?.id) {
      const { variantsTitle, main, variantList, field } = createMedicalMessagesBreadcrumbs(t);
      setBreadCrumb({ title: variantsTitle, values: [main, variantList(medicalMessageId), field(medicalMessageVariant.id)] });
    }
  }, [setBreadCrumb, t, medicalMessageId, medicalMessageVariant?.id]);

  return (
    <MedicalMessageVariantUpsertForm
      submitButtonLabel={t("Update")}
      medicalMessageVariant={medicalMessageVariant}
      medicalMessageType={medicalMessageType!}
      isLoading={loading || isMedicalMessageLoading}
      isButtonDisabled={isSubmitting}
      onChange={onMedicalMessageVariantUpsertFormChange}
    />
  );
};
