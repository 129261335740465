import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminMedicalFormCreateMutationVariables = Types.Exact<{
  input: Types.AdminMedicalFormInput;
}>;


export type AdminMedicalFormCreateMutation = { __typename?: 'Mutation', adminCreateMedicalForm?: { __typename?: 'MedicalForm', errors?: Array<{ __typename?: 'MedicalFormGraphqlError', errorType?: Types.ErrorType | null, message?: string | null, field?: string | null } | null> | null } | null };


export const AdminMedicalFormCreateDocument = gql`
    mutation AdminMedicalFormCreate($input: AdminMedicalFormInput!) {
  adminCreateMedicalForm(input: $input) {
    errors {
      errorType
      message
      field
    }
  }
}
    `;
export type AdminMedicalFormCreateMutationFn = Apollo.MutationFunction<AdminMedicalFormCreateMutation, AdminMedicalFormCreateMutationVariables>;

/**
 * __useAdminMedicalFormCreateMutation__
 *
 * To run a mutation, you first call `useAdminMedicalFormCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminMedicalFormCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminMedicalFormCreateMutation, { data, loading, error }] = useAdminMedicalFormCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminMedicalFormCreateMutation(baseOptions?: Apollo.MutationHookOptions<AdminMedicalFormCreateMutation, AdminMedicalFormCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminMedicalFormCreateMutation, AdminMedicalFormCreateMutationVariables>(AdminMedicalFormCreateDocument, options);
      }
export type AdminMedicalFormCreateMutationHookResult = ReturnType<typeof useAdminMedicalFormCreateMutation>;
export type AdminMedicalFormCreateMutationResult = Apollo.MutationResult<AdminMedicalFormCreateMutation>;
export type AdminMedicalFormCreateMutationOptions = Apollo.BaseMutationOptions<AdminMedicalFormCreateMutation, AdminMedicalFormCreateMutationVariables>;