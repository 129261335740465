import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareProgramActivityGetQueryVariables = Types.Exact<{
  guidedCareHealthProgramActivityId: Types.Scalars['ID']['input'];
}>;


export type GuidedCareProgramActivityGetQuery = { __typename?: 'Query', guidedCareHealthProgramActivity?: { __typename?: 'GuidedCareHealthProgramActivity', id?: string | null, templateActivityType?: Types.GuidedCareActivityType | null, guidedCareProgramActivityItems?: Array<{ __typename?: 'GuidedCareProgramActivityItem', id: string, offsetInDays?: number | null, timeOfDay?: Types.TimeOfDay | null, beforeCallMessageOffsetDays?: number | null, afterCallMessageOffsetDays?: number | null, beforeCallMessage?: { __typename?: 'MedicalMessage', id: string, code?: string | null, name?: string | null } | null, afterCallMessage?: { __typename?: 'MedicalMessage', id: string, code?: string | null, name?: string | null } | null } | null> | null, guidedCareProgramTeamMember?: { __typename?: 'GuidedCareProgramTeamMember', id: string, position?: Types.TeamMemberPosition | null, specialization?: string | null } | null, templateActivity?: { __typename?: 'TemplateActivity', id: string, medicalMessage?: { __typename?: 'MedicalMessage', id: string, code?: string | null, name?: string | null } | null } | null } | null };


export const GuidedCareProgramActivityGetDocument = gql`
    query GuidedCareProgramActivityGet($guidedCareHealthProgramActivityId: ID!) {
  guidedCareHealthProgramActivity(id: $guidedCareHealthProgramActivityId) {
    guidedCareProgramActivityItems {
      id
      offsetInDays
      timeOfDay
      beforeCallMessageOffsetDays
      afterCallMessageOffsetDays
      beforeCallMessage {
        id
        code
        name
      }
      afterCallMessage {
        id
        code
        name
      }
    }
    guidedCareProgramTeamMember {
      id
      position
      specialization
    }
    id
    templateActivity {
      id
      medicalMessage {
        id
        code
        name
      }
    }
    templateActivityType
  }
}
    `;

/**
 * __useGuidedCareProgramActivityGetQuery__
 *
 * To run a query within a React component, call `useGuidedCareProgramActivityGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareProgramActivityGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuidedCareProgramActivityGetQuery({
 *   variables: {
 *      guidedCareHealthProgramActivityId: // value for 'guidedCareHealthProgramActivityId'
 *   },
 * });
 */
export function useGuidedCareProgramActivityGetQuery(baseOptions: Apollo.QueryHookOptions<GuidedCareProgramActivityGetQuery, GuidedCareProgramActivityGetQueryVariables> & ({ variables: GuidedCareProgramActivityGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuidedCareProgramActivityGetQuery, GuidedCareProgramActivityGetQueryVariables>(GuidedCareProgramActivityGetDocument, options);
      }
export function useGuidedCareProgramActivityGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuidedCareProgramActivityGetQuery, GuidedCareProgramActivityGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuidedCareProgramActivityGetQuery, GuidedCareProgramActivityGetQueryVariables>(GuidedCareProgramActivityGetDocument, options);
        }
export function useGuidedCareProgramActivityGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GuidedCareProgramActivityGetQuery, GuidedCareProgramActivityGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GuidedCareProgramActivityGetQuery, GuidedCareProgramActivityGetQueryVariables>(GuidedCareProgramActivityGetDocument, options);
        }
export type GuidedCareProgramActivityGetQueryHookResult = ReturnType<typeof useGuidedCareProgramActivityGetQuery>;
export type GuidedCareProgramActivityGetLazyQueryHookResult = ReturnType<typeof useGuidedCareProgramActivityGetLazyQuery>;
export type GuidedCareProgramActivityGetSuspenseQueryHookResult = ReturnType<typeof useGuidedCareProgramActivityGetSuspenseQuery>;
export type GuidedCareProgramActivityGetQueryResult = Apollo.QueryResult<GuidedCareProgramActivityGetQuery, GuidedCareProgramActivityGetQueryVariables>;