import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RiskFactorTemplateParameterDeleteMutationVariables = Types.Exact<{
  healthParameterId: Types.Scalars['ID']['input'];
  riskFactorTemplateId: Types.Scalars['ID']['input'];
}>;


export type RiskFactorTemplateParameterDeleteMutation = { __typename?: 'Mutation', deleteFieldFromRiskFactorTemplate?: { __typename?: 'RiskFactorTemplate', errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', field?: string | null, message?: string | null } | null> | null } | null };


export const RiskFactorTemplateParameterDeleteDocument = gql`
    mutation RiskFactorTemplateParameterDelete($healthParameterId: ID!, $riskFactorTemplateId: ID!) {
  deleteFieldFromRiskFactorTemplate(
    healthParameterId: $healthParameterId
    riskFactorTemplateId: $riskFactorTemplateId
  ) {
    errors {
      field
      message
    }
  }
}
    `;
export type RiskFactorTemplateParameterDeleteMutationFn = Apollo.MutationFunction<RiskFactorTemplateParameterDeleteMutation, RiskFactorTemplateParameterDeleteMutationVariables>;

/**
 * __useRiskFactorTemplateParameterDeleteMutation__
 *
 * To run a mutation, you first call `useRiskFactorTemplateParameterDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRiskFactorTemplateParameterDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [riskFactorTemplateParameterDeleteMutation, { data, loading, error }] = useRiskFactorTemplateParameterDeleteMutation({
 *   variables: {
 *      healthParameterId: // value for 'healthParameterId'
 *      riskFactorTemplateId: // value for 'riskFactorTemplateId'
 *   },
 * });
 */
export function useRiskFactorTemplateParameterDeleteMutation(baseOptions?: Apollo.MutationHookOptions<RiskFactorTemplateParameterDeleteMutation, RiskFactorTemplateParameterDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RiskFactorTemplateParameterDeleteMutation, RiskFactorTemplateParameterDeleteMutationVariables>(RiskFactorTemplateParameterDeleteDocument, options);
      }
export type RiskFactorTemplateParameterDeleteMutationHookResult = ReturnType<typeof useRiskFactorTemplateParameterDeleteMutation>;
export type RiskFactorTemplateParameterDeleteMutationResult = Apollo.MutationResult<RiskFactorTemplateParameterDeleteMutation>;
export type RiskFactorTemplateParameterDeleteMutationOptions = Apollo.BaseMutationOptions<RiskFactorTemplateParameterDeleteMutation, RiskFactorTemplateParameterDeleteMutationVariables>;