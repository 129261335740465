import { useTranslation } from "@toolkit/i18n";
import { CustomDialog } from "@toolkit/ui";
import { FC, MouseEvent } from "react";
import { GuidedCareProgramTemplateActivityItemList } from "../../components/ProgramTemplateActivityItemList/GuidedCareProgramTemplateActivityItemList";

type GuidedCareProgramTemplateActivityItemListModalProps = {
  templateActivityId: string;
  isOpen: boolean;
  onClose: (e?: MouseEvent<HTMLButtonElement>) => void;
};

export const GuidedCareProgramTemplateActivityItemListModal: FC<GuidedCareProgramTemplateActivityItemListModalProps> = props => {
  const { templateActivityId, isOpen, onClose: handleClose } = props;

  const { t } = useTranslation("gcadmin");

  return (
    <CustomDialog type={"base"} maxWidth={"lg"} open={isOpen} DialogTitleProps={{ title: t("Activity Items"), onClose: handleClose }}>
      <GuidedCareProgramTemplateActivityItemList templateActivityId={templateActivityId} />
    </CustomDialog>
  );
};
