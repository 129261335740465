import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProviderUsersQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sortBy?: Types.InputMaybe<Types.UserSortingInput>;
}>;


export type ProviderUsersQuery = { __typename?: 'Query', vendor?: { __typename?: 'Vendor', name: string, ownerName: string, nationalId: string, description?: string | null, users?: { __typename?: 'UserCountableConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'UserCountableEdge', node: { __typename?: 'User', id: string, email?: string | null, firstName?: string | null, lastName?: string | null, mobile?: string | null, isStaff?: boolean | null, isSuperuser?: boolean | null, isVendorAdmin?: boolean | null, healthLicenseNumber?: string | null, healthLicenseStartDate?: any | null, healthLicenseEndDate?: any | null, appType?: Types.AppTypes | null, appRole?: Types.AppRoleTypes | null, isActive: boolean } }> } | null } | null };


export const ProviderUsersDocument = gql`
    query providerUsers($id: ID!, $first: Int, $after: String, $last: Int, $before: String, $sortBy: UserSortingInput) {
  vendor(id: $id) {
    name
    ownerName
    nationalId
    description
    users(
      first: $first
      after: $after
      last: $last
      before: $before
      sortBy: $sortBy
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          email
          firstName
          lastName
          mobile
          isStaff
          isSuperuser
          isVendorAdmin
          healthLicenseNumber
          healthLicenseStartDate
          healthLicenseEndDate
          appType
          appRole
          isActive
        }
      }
    }
  }
}
    `;

/**
 * __useProviderUsersQuery__
 *
 * To run a query within a React component, call `useProviderUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useProviderUsersQuery(baseOptions: Apollo.QueryHookOptions<ProviderUsersQuery, ProviderUsersQueryVariables> & ({ variables: ProviderUsersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProviderUsersQuery, ProviderUsersQueryVariables>(ProviderUsersDocument, options);
      }
export function useProviderUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProviderUsersQuery, ProviderUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProviderUsersQuery, ProviderUsersQueryVariables>(ProviderUsersDocument, options);
        }
export function useProviderUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProviderUsersQuery, ProviderUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProviderUsersQuery, ProviderUsersQueryVariables>(ProviderUsersDocument, options);
        }
export type ProviderUsersQueryHookResult = ReturnType<typeof useProviderUsersQuery>;
export type ProviderUsersLazyQueryHookResult = ReturnType<typeof useProviderUsersLazyQuery>;
export type ProviderUsersSuspenseQueryHookResult = ReturnType<typeof useProviderUsersSuspenseQuery>;
export type ProviderUsersQueryResult = Apollo.QueryResult<ProviderUsersQuery, ProviderUsersQueryVariables>;