import { GuidedCareActivityItemsForm, IGuidedCareActivityItemsFormEvent } from "@/pages/GuidedCare/forms";
import {
  convertGuidedCareActivityItemsFormValuesToBackEndValues,
  convertGuidedCareActivityItemsToFormValues,
} from "@/pages/GuidedCare/others";
import { TemplateInterventionActivity } from "@/schema/types";
import { guidedCareActivityTypeOptionsMap } from "@health/enum-options";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useNavigate, useParams } from "react-router-dom";
import { TemplateInterventionActivityEventItem } from "../../../../schema/types";
import { useGuidedCareTemplateInterventionActivityGetQuery, useGuidedCareTemplateInterventionActivityItemsUpdateMutation } from "../../gql";
import { useSetGuidedCareTemplatesBreadcrumbs } from "../../hooks";
import { IGuidedCareTemplateInterventionActivityItemListContainerParams } from "../../types";

export const GuidedCareTemplateInterventionActivityItemListContainer = () => {
  const { t } = useTranslation("gcadmin");
  const { failed, succeeded } = useAddToast();
  const navigate = useNavigate();

  const { activityId } = useParams<IGuidedCareTemplateInterventionActivityItemListContainerParams>();

  const { data, loading, refetch } = useGuidedCareTemplateInterventionActivityGetQuery({
    variables: {
      activityId: activityId!,
    },
  });

  const templateInterventionActivity = data?.templateInterventionActivity as TemplateInterventionActivity;

  const templateInterventionActivityItems = convertGuidedCareActivityItemsToFormValues(
    templateInterventionActivity?.interventionActivityEventItems as TemplateInterventionActivityEventItem[]
  );

  const [updateGuidedCareTemplateInterventionActivityItems, { loading: isSubmitting }] =
    useGuidedCareTemplateInterventionActivityItemsUpdateMutation({
      onCompleted: mutationData => {
        const errors = mutationData?.updateTemplateInterventionActivityItems?.templateActivityErrors;

        if (errors?.length) {
          failed(t(formatMessageErrors(errors)));
        } else {
          succeeded(t("Activity items have been updated successfully"));
          navigate(-1);
        }
      },
      onError: ({ graphQLErrors }) => {
        failed(formatGraphQLError(graphQLErrors));
      },
    });

  const onGuidedCareActivityItemsFormChange = (event: IGuidedCareActivityItemsFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertGuidedCareActivityItemsFormValuesToBackEndValues(event.payload.values.activityItems);

      updateGuidedCareTemplateInterventionActivityItems({
        variables: {
          activityId: activityId!,
          activityItems: values,
        },
      }).then(() => refetch());
    }
  };

  useSetGuidedCareTemplatesBreadcrumbs(
    "ACTIVITY_ITEM_LIST",
    guidedCareActivityTypeOptionsMap[templateInterventionActivity?.templateActivityType!]?.label
  );

  return (
    <GuidedCareActivityItemsForm
      activityItems={templateInterventionActivityItems}
      position={templateInterventionActivity?.templateTeamMember?.position!}
      activityType={templateInterventionActivity?.templateActivityType!}
      medicalMessage={templateInterventionActivity?.medicalMessage!}
      isLoading={loading || isSubmitting}
      onChange={onGuidedCareActivityItemsFormChange}
    />
  );
};
