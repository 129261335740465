/* eslint-disable max-lines */
import { createAutocompleteOption } from "@toolkit/ui";
import {
  appointmentFrequencyTypeOptionsMap,
  codeSystemCodeOptionsMap,
  guidedCareActivityTypeOptionsMap,
  teamMemberPositionOptionsMap,
  guidedCareTypeOptionsMap,
  timeOfDayOptionsMap,
} from "@health/enum-options";
import {
  HealthProgramTemplate,
  Maybe,
  MedicalFormClassificationToInterventionLink,
  TemplateActivity,
  TemplateActivityEventItem,
  TemplateGoal,
  TemplateIntervention,
  TemplateInterventionActivity,
  TemplateTeamMember,
} from "@/schema/types";
import { IGuidedCareTemplateMemberUpsertFormValues } from "../forms/TemplateMemberUpsert/GuidedCareTemplateMemberUpsertFormSchema";
import { IGuidedCareTemplateUpsertFormValues } from "../forms/TemplateUpsert/GuidedCareTemplateUpsertFormSchema";
import { IGuidedCareTemplateActivityUpsertFormValues } from "../forms/TemplateActivityUpsert/GuidedCareTemplateActivityUpsertFormSchema";
import { IGuidedCareTemplateGoalUpsertFormValues } from "../forms/TemplateGoalUpsert/GuidedCareTemplateGoalUpsertFormSchema";
import { pickLocalizedValue } from "@toolkit/i18n";
import { getTeamMemberLabel } from "./utils";
import { IGuidedCareTemplateInterventionUpsertFormValues } from "../forms/TemplateInterventionUpsert/GuidedCareTemplateInterventionUpsertFormSchema";
import { IGuidedCareTemplateInterventionActivityUpsertFormValues } from "../forms/TemplateInterventionActivityUpsert/GuidedCareTemplateInterventionActivityUpsertFormSchema";
import {
  createMedicalFormAutocompleteOption,
  createMedicalFormClassificationAutocompleteOption,
  createMedicalMessageAutocompleteOption,
} from "@health/autocompletes";
import { IGuidedCareTemplateActivitiesItems } from "../types";

export const convertTemplateToFormValues = (template: HealthProgramTemplate, isClone = false): IGuidedCareTemplateUpsertFormValues => {
  const healthCondition = template?.healthCondition
    ? createAutocompleteOption(
        { id: template?.healthCondition?.id!, code: template?.healthCondition?.code!, display: template?.healthCondition?.display! },
        "code",
        "display"
      )
    : undefined;

  const riskFactorTemplate = template?.riskFactorTemplate
    ? createAutocompleteOption(
        {
          id: template?.riskFactorTemplate?.id!,
          code: template?.riskFactorTemplate?.code!,
          display: template?.riskFactorTemplate?.display!,
        },
        "code",
        "display"
      )
    : undefined;

  return {
    name: isClone ? "" : template?.name!,
    guidedCareType: guidedCareTypeOptionsMap[template?.guidedCareType!],
    isRenewable: !!template?.isRenewable,
    templateDuration: template?.templateDuration!,
    isVerified: !!template?.isVerified,
    standardCode: template?.standardCode! || "",
    standardName: template?.standardName! || "",
    standardURL: template?.standardURL! || "",
    healthCondition,
    riskFactorTemplate,
    teamMembers: template?.templateTeamMembers?.map(item => convertTemplateTeamMemberToFormValues(item)) || [],
    activities: convertTemplateActivitiesToFormValues(template?.templateActivities, template?.templateTeamMembers),
    interventions: convertTemplateInterventionsToFormValues(template?.templateInterventions, template?.templateTeamMembers),
    goals: convertTemplateGoalsToFormValues(template?.templateGoals, template?.templateTeamMembers),
  };
};

const convertTemplateTeamMemberToFormValues = (
  templateTeamMember?: Maybe<TemplateTeamMember>
): IGuidedCareTemplateMemberUpsertFormValues => {
  return {
    teamMemberId: templateTeamMember?.id!,
    uniqueId: templateTeamMember?.id!,
    position: teamMemberPositionOptionsMap[templateTeamMember?.position!],
    specialization: templateTeamMember?.specialization
      ? createAutocompleteOption(
          { code: templateTeamMember?.specialization!, display: templateTeamMember?.specializationDisplay! },
          "code",
          "display"
        )
      : undefined,
    isLicencedHealthProfessional: !!templateTeamMember?.isLicencedHealthProfessional,
    isBackupPersonMandatory: !!templateTeamMember?.isBackupPersonMandatory,
    isKeyTeamMember: !!templateTeamMember?.isKeyTeamMember,
  };
};

const convertTemplateActivityMedicalFormClassificationsInterventionsToFormValues = (
  medicalFormClassificationsInterventions?: Maybe<Array<Maybe<MedicalFormClassificationToInterventionLink>>>
): IGuidedCareTemplateActivityUpsertFormValues["medicalFormClassificationsInterventions"] => {
  return (
    medicalFormClassificationsInterventions?.map(item => ({
      id: item?.id,
      classification: item?.classification!,
      intervention: item?.intervention
        ? createAutocompleteOption(
            {
              uniqueId: item?.intervention?.id!,
              name: item?.intervention?.name!,
              nameAr: item?.intervention?.nameAr!,
              code: item?.intervention?.code!,
              category: item?.intervention?.category!,
              duration: item?.intervention?.duration!,
              description: item?.intervention?.description!,
              descriptionAr: item?.intervention?.descriptionAr!,
              activities: [],
            },
            "code",
            _item => pickLocalizedValue(_item?.name, _item?.nameAr)
          )
        : undefined,
    })) || []
  );
};

const convertTemplateActivitiesToFormValues = (
  activities?: Maybe<Maybe<TemplateActivity>[]>,
  members?: Maybe<Maybe<TemplateTeamMember>[]>
): IGuidedCareTemplateActivityUpsertFormValues[] => {
  return (
    activities?.map(item => {
      const teamMember = members?.find(member => member?.id! === item?.templateTeamMember?.id!);

      return {
        activityId: item?.id!,
        teamMember: createAutocompleteOption(convertTemplateTeamMemberToFormValues(teamMember), "uniqueId", getTeamMemberLabel),
        templateActivityType: guidedCareActivityTypeOptionsMap[item?.templateActivityType!],
        numberOfOccurrences: item?.numberOfOccurrences!,
        frequencyType: appointmentFrequencyTypeOptionsMap[item?.frequencyType!],
        medicalForm: item?.medicalForm ? createMedicalFormClassificationAutocompleteOption(item?.medicalForm) : undefined,
        medicalMessage: item?.medicalMessage ? createMedicalMessageAutocompleteOption(item?.medicalMessage) : undefined,
        beforeActivity: item?.beforeCallMessage ? createMedicalMessageAutocompleteOption(item?.beforeCallMessage) : undefined,
        beforeActivityOffset: item?.beforeCallMessageOffsetDays!,
        afterActivity: item?.afterCallMessage ? createMedicalMessageAutocompleteOption(item?.afterCallMessage) : undefined,
        afterActivityOffset: item?.afterCallMessageOffsetDays!,
        medicalFormClassificationsInterventions: convertTemplateActivityMedicalFormClassificationsInterventionsToFormValues(
          item?.medicalFormClassificationToInterventionLinkList
        ),
        activityServices:
          item?.serviceOfHealthProgramTemplateDetails?.map(service => ({
            serviceId: service?.id!,
            standard: codeSystemCodeOptionsMap[service?.standard!],
            service: createAutocompleteOption({ code: service?.serviceCode, display: service?.serviceName }, "code", "display"),
          })) || [],
      };
    }) || []
  );
};

const convertTemplateInterventionToFormValues = (
  intervention: TemplateIntervention,
  templateTeamMembers?: Maybe<Array<Maybe<TemplateTeamMember>>>
): IGuidedCareTemplateInterventionUpsertFormValues => {
  return {
    interventionId: intervention?.id!,
    uniqueId: intervention?.id!,
    name: intervention?.name!,
    nameAr: intervention?.nameAr!,
    code: intervention?.code!,
    category: intervention?.category!,
    duration: intervention?.duration!,
    description: intervention?.description!,
    descriptionAr: intervention?.descriptionAr!,
    activities: convertTemplateInterventionActivitiesToFormValues(intervention?.interventionTemplateActivities, templateTeamMembers) || [],
  };
};

const convertTemplateInterventionsToFormValues = (
  templateInterventions?: Maybe<Array<Maybe<TemplateIntervention>>>,
  templateTeamMembers?: Maybe<Array<Maybe<TemplateTeamMember>>>
): IGuidedCareTemplateInterventionUpsertFormValues[] => {
  return templateInterventions?.map(intervention => convertTemplateInterventionToFormValues(intervention!, templateTeamMembers)) || [];
};

const convertTemplateGoalsToFormValues = (
  templateGoals?: Maybe<Array<Maybe<TemplateGoal>>>,
  templateTeamMembers?: Maybe<Array<Maybe<TemplateTeamMember>>>
): IGuidedCareTemplateGoalUpsertFormValues[] => {
  return (
    templateGoals?.map(goal => {
      const healthParameter = createAutocompleteOption(
        {
          id: goal?.healthParameter?.id!,
          code: goal?.healthParameter?.code!,
          display: goal?.healthParameter?.display!,
          arabicDisplay: goal?.healthParameter?.arabicDisplay!,
        },
        "id",
        item => pickLocalizedValue(item?.display, item?.arabicDisplay!)
      );

      return {
        goalId: goal?.id!,
        uniqueId: goal?.id!,
        name: goal?.name!,
        nameAr: goal?.nameAr!,
        healthParameter,
        target: goal?.target!,
        description: goal?.description!,
        descriptionAr: goal?.descriptionAr!,
        recommendations:
          goal?.templateRecommendations?.map(recommendation => ({
            recommendationId: recommendation?.id!,
            uniqueId: recommendation?.id!,
            name: recommendation?.name!,
            nameAr: recommendation?.nameAr!,
            code: recommendation?.code!,
            description: recommendation?.description!,
            descriptionAr: recommendation?.descriptionAr!,
            interventions:
              recommendation?.templateInterventions?.map(item => {
                const intervention = convertTemplateInterventionToFormValues(item!, templateTeamMembers);
                return createAutocompleteOption(intervention, "uniqueId", _item => pickLocalizedValue(_item?.name!, _item?.nameAr!));
              }) || [],
          })) || [],
      };
    }) || []
  );
};

const convertTemplateInterventionActivitiesToFormValues = (
  activities?: Maybe<Maybe<TemplateInterventionActivity>[]>,
  members?: Maybe<Maybe<TemplateTeamMember>[]>
): IGuidedCareTemplateInterventionActivityUpsertFormValues[] => {
  return (
    activities?.map(item => {
      const teamMember = members?.find(member => member?.id! === item?.templateTeamMember?.id!);

      return {
        activityId: item?.id!,
        teamMember: createAutocompleteOption(convertTemplateTeamMemberToFormValues(teamMember), "uniqueId", getTeamMemberLabel),
        templateActivityType: guidedCareActivityTypeOptionsMap[item?.templateActivityType!],
        numberOfOccurrences: item?.numberOfOccurrences!,
        frequencyType: appointmentFrequencyTypeOptionsMap[item?.frequencyType!],
        medicalForm: item?.medicalForm ? createMedicalFormAutocompleteOption(item?.medicalForm) : undefined,
        medicalMessage: item?.medicalMessage ? createMedicalMessageAutocompleteOption(item?.medicalMessage) : undefined,
        beforeActivity: item?.beforeCallMessage ? createMedicalMessageAutocompleteOption(item?.beforeCallMessage) : undefined,
        beforeActivityOffset: item?.beforeCallMessageOffsetDays!,
        afterActivity: item?.afterCallMessage ? createMedicalMessageAutocompleteOption(item?.afterCallMessage) : undefined,
        afterActivityOffset: item?.afterCallMessageOffsetDays!,
        activityServices:
          // eslint-disable-next-line sonarjs/no-identical-functions
          item?.serviceOfInterventionActivityDetails?.map(service => ({
            serviceId: service?.id!,
            standard: codeSystemCodeOptionsMap[service?.standard!],
            service: createAutocompleteOption({ code: service?.serviceCode, display: service?.serviceName }, "code", "display"),
          })) || [],
      };
    }) || []
  );
};

export const convertTemplateActivitiesItemsToFormValues = (
  templateActivitiesItems: TemplateActivityEventItem[]
): IGuidedCareTemplateActivitiesItems => {
  return templateActivitiesItems?.map(item => ({
    id: item?.id!,
    activityId: item?.templateActivity?.id!,
    activityType: guidedCareActivityTypeOptionsMap[item?.templateActivity?.templateActivityType!],
    medicalForm: item?.templateActivity?.medicalForm ? createMedicalFormAutocompleteOption(item?.templateActivity?.medicalForm) : undefined,
    medicalMessage: item?.templateActivity?.medicalMessage
      ? createMedicalMessageAutocompleteOption(item?.templateActivity?.medicalMessage)
      : undefined,
    offset: item?.offsetInDays!,
    timeOfDay: timeOfDayOptionsMap[item?.timeOfDay!],
    beforeActivity: item?.beforeCallMessage ? createMedicalMessageAutocompleteOption(item?.beforeCallMessage) : undefined,
    afterActivity: item?.afterCallMessage ? createMedicalMessageAutocompleteOption(item?.afterCallMessage) : undefined,
    beforeActivityOffset: item?.beforeCallMessageOffsetDays,
    afterActivityOffset: item?.afterCallMessageOffsetDays,
  }));
};

export const getTemplateActivitiesItemsModified = (
  templateActivitiesItems: TemplateActivityEventItem[],
  activitiesItems: IGuidedCareTemplateActivitiesItems
): IGuidedCareTemplateActivitiesItems => {
  const templateMap = new Map<string, TemplateActivityEventItem>();

  templateActivitiesItems.forEach(item => {
    templateMap.set(item.id, item);
  });

  return (
    activitiesItems.filter(activityItem => {
      const templateItem = templateMap.get(activityItem.id);
      return (
        templateItem && (activityItem?.offset !== templateItem?.offsetInDays || activityItem?.timeOfDay?.value !== templateItem?.timeOfDay)
      );
    }) || []
  );
};
