import { BulkTemplateCode } from "@/schema/types";
import { FileDownload, FileImportDialog } from "@health/domains";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useGridContext } from "@toolkit/ui";
import { medicalFormsPaths } from "pages/MedicalForms/constants";
import { useNavigate } from "react-router-dom";

export const MedicalFormListPageWrapper = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();

  const { open, handleToggle } = useOpenState();
  const { customTableProps } = useGridContext();

  const onAddNewItemClick = () => {
    navigate(medicalFormsPaths.new.fullPath);
  };

  const handleSuccessUpload = () => {
    customTableProps?.onUpdateGrid && customTableProps?.onUpdateGrid();
  };

  return (
    <PageWrapper
      filters={<FilterGrid />}
      actions={
        <>
          <TableSettingComponent />

          <MainListActions hasAddNew onAddNewItem={onAddNewItemClick}>
            <FileImportDialog
              title={t("Import Medical Forms")}
              subTitle={t("Upload Medical Forms you want to get")}
              isOpen={open}
              onToggle={handleToggle}
              onSuccessUpload={handleSuccessUpload}
              templateCode={BulkTemplateCode.MedicalForm}
            />

            <FileDownload templateCode={BulkTemplateCode.MedicalForm} />
          </MainListActions>
        </>
      }
    >
      <TableGrid />
    </PageWrapper>
  );
};
