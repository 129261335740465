import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ArticleActivationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ArticleActivationMutation = { __typename?: 'Mutation', updateArticleActiveStatus?: { __typename?: 'Article', active?: boolean | null } | null };


export const ArticleActivationDocument = gql`
    mutation ArticleActivation($id: ID!) {
  updateArticleActiveStatus(id: $id) {
    active
  }
}
    `;
export type ArticleActivationMutationFn = Apollo.MutationFunction<ArticleActivationMutation, ArticleActivationMutationVariables>;

/**
 * __useArticleActivationMutation__
 *
 * To run a mutation, you first call `useArticleActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArticleActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [articleActivationMutation, { data, loading, error }] = useArticleActivationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArticleActivationMutation(baseOptions?: Apollo.MutationHookOptions<ArticleActivationMutation, ArticleActivationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArticleActivationMutation, ArticleActivationMutationVariables>(ArticleActivationDocument, options);
      }
export type ArticleActivationMutationHookResult = ReturnType<typeof useArticleActivationMutation>;
export type ArticleActivationMutationResult = Apollo.MutationResult<ArticleActivationMutation>;
export type ArticleActivationMutationOptions = Apollo.BaseMutationOptions<ArticleActivationMutation, ArticleActivationMutationVariables>;