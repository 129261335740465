import { HealthSymptom, HealthSymptomInput } from "@/schema/types";
import { createAutocompleteOption } from "@toolkit/ui";
import { IHealthSymptomUpsertFormValues } from "../forms/HealthSymptomUpsert/HealthSymptomUpsertFormSchema";

export const convertHealthSymptomToFormValues = (healthSymptom: HealthSymptom): IHealthSymptomUpsertFormValues => {
  return {
    name: healthSymptom?.name!,
    specializations:
      healthSymptom?.specializations?.map(item =>
        createAutocompleteOption(
          {
            code: item?.code!,
            display: item?.display!,
          },
          "code",
          "display"
        )
      ) || [],
  };
};

export const convertHealthSymptomFormValuesToBackEndValues = (values: IHealthSymptomUpsertFormValues): HealthSymptomInput => {
  return {
    name: values?.name,
    specializations: values?.specializations?.map(item => item?.value?.code as string).filter(Boolean) || [],
  };
};
