import { LabTemplate, LabTemplateInput, LabTemplateUpdateInput } from "@/schema/types";
import { createAutocompleteOption } from "@toolkit/ui";
import { ILabTemplateUpsertFormValues } from "../forms/LabTemplateUpsert/LabTemplateUpsertFormSchema";

export const convertLabTemplateToFormValues = (labTemplate: LabTemplate, isClone: boolean): ILabTemplateUpsertFormValues => {
  return {
    display: labTemplate?.display!,
    arabicDisplay: labTemplate?.arabicDisplay!,
    code: !isClone
      ? createAutocompleteOption(
          {
            code: labTemplate?.code!,
            display: labTemplate?.labCodeDisplay!,
          },
          "code",
          item => `${item?.code} - ${item?.display}`
        )
      : (undefined as any),
    isActive: !!labTemplate?.isActive,
  };
};

export const convertLabTemplateFormValuesToBackEndValues = (values: ILabTemplateUpsertFormValues): LabTemplateInput => {
  return {
    display: values?.display!,
    arabicDisplay: values?.arabicDisplay!,
    code: values?.code?.value?.code!,
    isActive: values?.isActive,
  };
};

export const convertLabTemplateUpdateFormValuesToBackEndValues = (values: ILabTemplateUpsertFormValues): LabTemplateUpdateInput => {
  return {
    display: values?.display!,
    arabicDisplay: values?.arabicDisplay!,
    isActive: values?.isActive,
  };
};
