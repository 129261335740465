import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthSymptomListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.HealthSymptomFilterInput>;
  sortBy?: Types.InputMaybe<Types.HealthSymptomOrder>;
}>;


export type HealthSymptomListQuery = { __typename?: 'Query', healthSymptoms?: { __typename?: 'HealthSymptomCountableConnection', pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'HealthSymptomCountableEdge', node: { __typename?: 'HealthSymptom', id: string, name: string, created: any, specializations?: Array<{ __typename?: 'HealthSymptomSpecialization', id: string, code?: string | null, display?: string | null, arabicDisplay?: string | null } | null> | null } }> } | null };


export const HealthSymptomListDocument = gql`
    query HealthSymptomList($first: Int, $last: Int, $before: String, $after: String, $filter: HealthSymptomFilterInput, $sortBy: HealthSymptomOrder) {
  healthSymptoms(
    first: $first
    last: $last
    before: $before
    after: $after
    filter: $filter
    sortBy: $sortBy
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        id
        name
        created
        specializations {
          id
          code
          display
          arabicDisplay
        }
      }
    }
  }
}
    `;

/**
 * __useHealthSymptomListQuery__
 *
 * To run a query within a React component, call `useHealthSymptomListQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthSymptomListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthSymptomListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useHealthSymptomListQuery(baseOptions?: Apollo.QueryHookOptions<HealthSymptomListQuery, HealthSymptomListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthSymptomListQuery, HealthSymptomListQueryVariables>(HealthSymptomListDocument, options);
      }
export function useHealthSymptomListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthSymptomListQuery, HealthSymptomListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthSymptomListQuery, HealthSymptomListQueryVariables>(HealthSymptomListDocument, options);
        }
export function useHealthSymptomListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HealthSymptomListQuery, HealthSymptomListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HealthSymptomListQuery, HealthSymptomListQueryVariables>(HealthSymptomListDocument, options);
        }
export type HealthSymptomListQueryHookResult = ReturnType<typeof useHealthSymptomListQuery>;
export type HealthSymptomListLazyQueryHookResult = ReturnType<typeof useHealthSymptomListLazyQuery>;
export type HealthSymptomListSuspenseQueryHookResult = ReturnType<typeof useHealthSymptomListSuspenseQuery>;
export type HealthSymptomListQueryResult = Apollo.QueryResult<HealthSymptomListQuery, HealthSymptomListQueryVariables>;