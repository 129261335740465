import { CodeSystemCode, HealthSymptom } from "@/schema/types";
import { SystemCodeAutocomplete } from "@health/autocompletes";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, FormTextField, Grid, PageWrapper } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { convertHealthSymptomToFormValues } from "../../others";
import {
  healthSymptomUpsertFormDefaultValues,
  healthSymptomUpsertFormSchema,
  IHealthSymptomUpsertFormValues,
} from "./HealthSymptomUpsertFormSchema";

export type IHealthSymptomUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IHealthSymptomUpsertFormValues;
  };
};

type HealthSymptomUpsertFormProps = {
  healthSymptom?: HealthSymptom;
  submitButtonLabel: string;
  isLoading?: boolean;
  isButtonDisabled?: boolean;
  onChange: (values: IHealthSymptomUpsertFormEvent) => void;
};

export const HealthSymptomUpsertForm: FC<HealthSymptomUpsertFormProps> = props => {
  const { healthSymptom, submitButtonLabel, isLoading, isButtonDisabled, onChange } = props;

  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();

  const form = useCustomForm<IHealthSymptomUpsertFormValues>({
    defaultValues: healthSymptomUpsertFormDefaultValues,
    schema: healthSymptomUpsertFormSchema,
  });

  const { setValues, handleSubmit } = form;
  console.log("form", form.getValues());
  const onSubmit = (values: IHealthSymptomUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  const handleCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (healthSymptom) {
      const _healthSymptom = convertHealthSymptomToFormValues(healthSymptom);
      setValues(_healthSymptom);
    }
  }, [healthSymptom, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={submitButtonLabel}
              onNavigation={handleCancel}
              newButtonDisabled={isButtonDisabled}
            />
          }
        >
          <FormCard title={t("Health Symptom Information")} loading={isLoading} doYouHaveData>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormTextField name={"name"} label={t("Name")} placeholder={t("Name")} disabled={!!healthSymptom} />
                  </Grid>

                  <Grid item xs={12}>
                    <SystemCodeAutocomplete
                      name={"specializations"}
                      label={t("Specializations")}
                      multiple
                      isCodeHidden
                      filter={{ codeSystemCode: CodeSystemCode.Speciality }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FormCard>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
