import { DecisionPlanCategory } from "@/schema/types";
import { createPathDefinition } from "@toolkit/core";
import { kebabCase } from "lodash";

export const decisionsRoute = `/decisions`;

export const decisionsPaths = createPathDefinition(decisionsRoute, {
  main: ":category",
  new: ":category/new",
  edit: ":category/:id",
});

const getCategoryRouteAndPaths = (category: DecisionPlanCategory) => {
  const categoryKey = kebabCase(category);
  const route = decisionsPaths.main.fullPathWithParams({ category: categoryKey });

  const paths = createPathDefinition(route, {
    main: "",
    new: "new",
    edit: ":id",
  });

  return { route, paths };
};

export const riskStratificationRoutes = getCategoryRouteAndPaths(DecisionPlanCategory.RiskStratification);
export const taskFlowRoutes = getCategoryRouteAndPaths(DecisionPlanCategory.TaskFlow);
export const interventionRoutes = getCategoryRouteAndPaths(DecisionPlanCategory.Intervention);
