import { PermissionEnum } from "@/schema/types";
import { hasPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import { RouteItem } from "shared/components";
import { HealthConditionsRoutesIcon } from "../components/HealthConditionsRoutesIcon/HealthConditionsRoutesIcon";
import {
  HealthConditionCloneContainer,
  HealthConditionCreateContainer,
  HealthConditionDiagnosisListContainer,
  HealthConditionListContainer,
  HealthConditionParameterListContainer,
  HealthConditionUpdateContainer,
} from "../containers";
import { healthConditionsPaths, healthConditionsRoute } from "./HealthConditionsPaths";

export const healthConditionsRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  return {
    id: "health-conditions-routes",
    text: t("Health Conditions", { ns: "gcadmin" }),
    route: healthConditionsRoute,
    isProhibited: !hasPermission(PermissionEnum.ManageHealthConditions),
    icon: <HealthConditionsRoutesIcon />,
    subItems: [
      {
        id: "health-condition-list-route",
        route: healthConditionsPaths.list.route,
        fullPath: healthConditionsPaths.list.fullPath,
        element: <HealthConditionListContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "health-condition-create-route",
        route: healthConditionsPaths.create.route,
        fullPath: healthConditionsPaths.create.fullPath,
        hidden: true,
        element: <HealthConditionCreateContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "health-condition-update-route",
        route: healthConditionsPaths.update.route,
        fullPath: healthConditionsPaths.update.fullPath,
        hidden: true,
        element: <HealthConditionUpdateContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "health-condition-clone-route",
        route: healthConditionsPaths.clone.route,
        fullPath: healthConditionsPaths.clone.fullPath,
        hidden: true,
        element: <HealthConditionCloneContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "health-condition-parameter-list-route",
        route: healthConditionsPaths.parameterList.route,
        fullPath: healthConditionsPaths.parameterList.fullPath,
        hidden: true,
        element: <HealthConditionParameterListContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "health-condition-diagnosis-list-route",
        route: healthConditionsPaths.diagnosisList.route,
        fullPath: healthConditionsPaths.diagnosisList.fullPath,
        hidden: true,
        element: <HealthConditionDiagnosisListContainer />,
        onClick: (route: string) => navigate(route),
      },
    ],
  };
};
