import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareProgramActivityItemsUpdateMutationVariables = Types.Exact<{
  activityId: Types.Scalars['ID']['input'];
  activityItems?: Types.InputMaybe<Array<Types.InputMaybe<Types.GuidedCareHealthProgramActivityItemInput>> | Types.InputMaybe<Types.GuidedCareHealthProgramActivityItemInput>>;
}>;


export type GuidedCareProgramActivityItemsUpdateMutation = { __typename?: 'Mutation', updateGuidedCareHealthProgramActivityItems?: { __typename?: 'GuidedCareHealthProgramActivityCRUD', guidedCareHealthProgramActivityErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, message?: string | null }> | null } | null };


export const GuidedCareProgramActivityItemsUpdateDocument = gql`
    mutation GuidedCareProgramActivityItemsUpdate($activityId: ID!, $activityItems: [GuidedCareHealthProgramActivityItemInput]) {
  updateGuidedCareHealthProgramActivityItems(
    activityId: $activityId
    activityItems: $activityItems
  ) {
    guidedCareHealthProgramActivityErrors {
      field
      message
    }
  }
}
    `;
export type GuidedCareProgramActivityItemsUpdateMutationFn = Apollo.MutationFunction<GuidedCareProgramActivityItemsUpdateMutation, GuidedCareProgramActivityItemsUpdateMutationVariables>;

/**
 * __useGuidedCareProgramActivityItemsUpdateMutation__
 *
 * To run a mutation, you first call `useGuidedCareProgramActivityItemsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareProgramActivityItemsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guidedCareProgramActivityItemsUpdateMutation, { data, loading, error }] = useGuidedCareProgramActivityItemsUpdateMutation({
 *   variables: {
 *      activityId: // value for 'activityId'
 *      activityItems: // value for 'activityItems'
 *   },
 * });
 */
export function useGuidedCareProgramActivityItemsUpdateMutation(baseOptions?: Apollo.MutationHookOptions<GuidedCareProgramActivityItemsUpdateMutation, GuidedCareProgramActivityItemsUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GuidedCareProgramActivityItemsUpdateMutation, GuidedCareProgramActivityItemsUpdateMutationVariables>(GuidedCareProgramActivityItemsUpdateDocument, options);
      }
export type GuidedCareProgramActivityItemsUpdateMutationHookResult = ReturnType<typeof useGuidedCareProgramActivityItemsUpdateMutation>;
export type GuidedCareProgramActivityItemsUpdateMutationResult = Apollo.MutationResult<GuidedCareProgramActivityItemsUpdateMutation>;
export type GuidedCareProgramActivityItemsUpdateMutationOptions = Apollo.BaseMutationOptions<GuidedCareProgramActivityItemsUpdateMutation, GuidedCareProgramActivityItemsUpdateMutationVariables>;