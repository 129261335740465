import { z } from "zod";
import { zodSchema } from "@health/autocompletes";
import { zodEnumSchema } from "@health/enum-options";

export const guidedCareTemplateActivitiesItemsFormSchema = z.object({
  activitiesItems: z.array(
    z.object({
      id: z.string(),
      activityId: z.string(),
      activityType: zodEnumSchema.guidedCareActivityType,
      medicalForm: zodSchema.medicalForm.nullish(),
      medicalMessage: zodSchema.medicalMessage.nullish(),
      offset: z.coerce.number().min(0),
      timeOfDay: zodEnumSchema.timeOfDay,
      beforeActivity: zodSchema.medicalMessage.nullish(),
      afterActivity: zodSchema.medicalMessage.nullish(),
      beforeActivityOffset: z.coerce.number().min(0).nullish(),
      afterActivityOffset: z.coerce.number().min(0).nullish(),
    })
  ),
});

export type IGuidedCareTemplateActivitiesItemsFormValues = z.infer<typeof guidedCareTemplateActivitiesItemsFormSchema>;

export const guidedCareTemplateActivitiesItemsFormDefaultValues: Partial<IGuidedCareTemplateActivitiesItemsFormValues> = {
  activitiesItems: [],
};
