import { TemplateActivity, TemplateActivityEventItem } from "@/schema/types";
import { map, max, min } from "lodash";
import { FC } from "react";
import { GuidedCareTemplateActivitiesItemsDialog } from "../TemplateActivitiesItemsDialog/GuidedCareTemplateActivitiesItemsDialog";
import { GuidedCareTemplateActivitiesItemsTimelineItem } from "../TemplateActivitiesItemsTimelineItem/GuidedCareTemplateActivitiesItemsTimelineItem";
import { GuidedCareTemplateActivitiesItemsTimelineItemIcon } from "../TemplateActivitiesItemsTimelineItemIcon/GuidedCareTemplateActivitiesItemsTimelineItemIcon";

type GuidedCareTemplateActivitiesItemsTimelineAnnualProps = {
  activities?: TemplateActivityEventItem[];
  selectItem: TemplateActivity;
  onSelectItem: (item: TemplateActivity) => void;
};

export const GuidedCareTemplateActivitiesItemsTimelineAnnual: FC<GuidedCareTemplateActivitiesItemsTimelineAnnualProps> = props => {
  const { activities, selectItem, onSelectItem: handleSelectItem } = props;

  const activitiesOffsets = map(activities || [], "offsetInDays");

  return (
    <>
      {activities?.slice(0, 10)?.map((item, index) => {
        return (
          <>
            <GuidedCareTemplateActivitiesItemsTimelineItem key={item?.id} offsetInDays={item?.offsetInDays!} item={item?.templateActivity!}>
              <GuidedCareTemplateActivitiesItemsTimelineItemIcon
                item={item?.templateActivity!}
                selectItem={selectItem!}
                onSelectItem={handleSelectItem}
              />
            </GuidedCareTemplateActivitiesItemsTimelineItem>

            {index + 1 === 10 && activities?.length! > 10 && (
              <GuidedCareTemplateActivitiesItemsDialog
                minOffsetInDays={min(activitiesOffsets)!}
                maxOffsetInDays={max(activitiesOffsets)!}
              />
            )}
          </>
        );
      })}
    </>
  );
};
