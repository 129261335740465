import { LabTemplate } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useNavigate, useParams } from "react-router-dom";
import { labTemplatesPaths } from "../../constants";
import { ILabTemplateUpsertFormEvent, LabTemplateUpsertForm } from "../../forms/LabTemplateUpsert/LabTemplateUpsertForm";
import { useLabTemplateGetQuery, useLabTemplateUpdateMutation } from "../../gql";
import { useSetLabTemplatesBreadcrumbs } from "../../hooks";
import { convertLabTemplateUpdateFormValuesToBackEndValues } from "../../others/utils";
import { ILabTemplateUpdateContainerParams } from "../../types";

export const LabTemplateUpdateContainer = () => {
  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const { labTemplateId } = useParams<ILabTemplateUpdateContainerParams>();

  const { data, loading } = useLabTemplateGetQuery({
    variables: {
      id: labTemplateId!,
    },
    skip: !labTemplateId,
  });

  const labTemplate = data?.getLabTemplate as LabTemplate;

  const [updateLabTemplate, { loading: isSubmitting }] = useLabTemplateUpdateMutation({
    onCompleted: mutationData => {
      if (!mutationData?.updateLabTemplate?.errors?.length) {
        succeeded(t("Lab Template updated successfully"));
        navigate(labTemplatesPaths.list.fullPath);
      } else {
        failed(t("Lab Template update failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onLabTemplateUpsertFormChange = (event: ILabTemplateUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertLabTemplateUpdateFormValuesToBackEndValues(event.payload.values);

      updateLabTemplate({
        variables: {
          id: labTemplateId!,
          input: values,
        },
      });
    }
  };

  useSetLabTemplatesBreadcrumbs("UPDATE", pickLocalizedValue(labTemplate?.display!, labTemplate?.arabicDisplay!));

  return (
    <LabTemplateUpsertForm
      labTemplate={labTemplate}
      submitButtonLabel={t("Update")}
      isLoading={loading}
      isButtonDisabled={isSubmitting}
      onChange={onLabTemplateUpsertFormChange}
    />
  );
};
