import { HealthSymptomSpecialization } from "@/schema/types";
import { CustomTable } from "@toolkit/ui";
import { FC } from "react";
import { useHealthSymptomsSpecializationsColumns } from "./useHealthSymptomsSpecializationsColumns";

type HealthSymptomsSpecializationsProps = {
  specializations: HealthSymptomSpecialization[];
};

export const HealthSymptomsSpecializations: FC<HealthSymptomsSpecializationsProps> = props => {
  const { specializations } = props;

  return <CustomTable data={specializations} columns={useHealthSymptomsSpecializationsColumns()} hasFooter={false} scrollable />;
};
