import { useTranslation } from "@toolkit/i18n";
import { Box, CustomDialog, IconButton, MuiMoreVertIcon } from "@toolkit/ui";
import { IGuidedCareTemplateActivitiesItemsTimelineContainerParams } from "pages/Templates/types";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { GuidedCareTemplateActivitiesItemList } from "../TemplateActivitiesItemList/GuidedCareTemplateActivitiesItemList";
import { useGuidedCareTemplateActivitiesItemsDialogStyle } from "./useGuidedCareTemplateActivitiesItemsDialogStyle";
import { useOpenState } from "@toolkit/core";

type GuidedCareTemplateActivitiesItemsDialogProps = {
  maxOffsetInDays: number;
  minOffsetInDays: number;
};

export const GuidedCareTemplateActivitiesItemsDialog: FC<GuidedCareTemplateActivitiesItemsDialogProps> = props => {
  const { maxOffsetInDays, minOffsetInDays } = props;

  const { t } = useTranslation("gcadmin");
  const { open, handleToggle } = useOpenState();

  const { templateId } = useParams<IGuidedCareTemplateActivitiesItemsTimelineContainerParams>();

  const { classes } = useGuidedCareTemplateActivitiesItemsDialogStyle();

  return (
    <CustomDialog
      type={"base"}
      open={open}
      maxWidth={"md"}
      DialogContentProps={{
        sx: { maxHeight: "100vh" },
      }}
      DialogActionsProps={{
        hasClose: true,
        onClose: handleToggle,
      }}
      DialogTitleProps={{
        title: t("Activity Items"),
        onClose: handleToggle,
      }}
      button={
        <IconButton className={classes.showMoreButtonIcon} onClick={handleToggle}>
          <Box mt={1} className={classes.moreIcon}>
            <MuiMoreVertIcon />
          </Box>
        </IconButton>
      }
    >
      <GuidedCareTemplateActivitiesItemList templateId={templateId!} minOffsetInDays={minOffsetInDays} maxOffsetInDays={maxOffsetInDays} />
    </CustomDialog>
  );
};
