import { HealthSymptom } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useNavigate, useParams } from "react-router-dom";
import { healthSymptomsPaths } from "../../constants";
import { HealthSymptomUpsertForm, IHealthSymptomUpsertFormEvent } from "../../forms/HealthSymptomUpsert/HealthSymptomUpsertForm";
import { HealthSymptomGetDocument, useHealthSymptomGetQuery, useHealthSymptomUpdateMutation } from "../../gql";
import { useSetHealthSymptomsBreadcrumbs } from "../../hooks";
import { convertHealthSymptomFormValuesToBackEndValues } from "../../others";
import { IHealthSymptomUpdateContainerParams } from "../../types";

export const HealthSymptomUpdateContainer = () => {
  const { t } = useTranslation("gcadmin");
  const { succeeded, failed } = useAddToast();
  const navigate = useNavigate();

  const { healthSymptomId } = useParams<IHealthSymptomUpdateContainerParams>();

  const { data, loading } = useHealthSymptomGetQuery({
    variables: {
      healthSymptomId,
    },
    skip: !healthSymptomId,
  });

  const healthSymptom = data?.healthSymptom as HealthSymptom;

  const [updateHealthSymptom, { loading: isSubmitting }] = useHealthSymptomUpdateMutation({
    onCompleted: mutationData => {
      if (!mutationData?.healthSymptomUpdate?.healthSymptomErrors?.length) {
        succeeded(t("Health Symptom updated successfully"));
        navigate(healthSymptomsPaths.list.fullPath);
      } else {
        failed(t("Health Symptom update failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    refetchQueries: [HealthSymptomGetDocument],
  });

  const onHealthSymptomUpsertFormChange = (event: IHealthSymptomUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertHealthSymptomFormValuesToBackEndValues(event.payload.values);

      updateHealthSymptom({
        variables: {
          id: healthSymptomId!,
          input: values,
        },
      });
    }
  };

  useSetHealthSymptomsBreadcrumbs("UPDATE", healthSymptom?.name);

  return (
    <HealthSymptomUpsertForm
      submitButtonLabel={t("Update")}
      isLoading={loading}
      isButtonDisabled={isSubmitting}
      healthSymptom={healthSymptom}
      onChange={onHealthSymptomUpsertFormChange}
    />
  );
};
