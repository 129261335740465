import { HealthParameter } from "@/schema/types";
import { CustomTable } from "@toolkit/ui";
import { FC } from "react";
import { useHealthParameterCustomTableListColumns } from "./useHealthParameterCustomTableListColumns";

type HealthParameterListProps = {
  healthParameters: HealthParameter[];
  isLoading?: boolean;
  isRowDeletable?: boolean;
  onDeleteRow: (item: HealthParameter, index: number) => void;
};

export const HealthParameterCustomTableList: FC<HealthParameterListProps> = props => {
  const { healthParameters, isLoading, isRowDeletable, onDeleteRow } = props;

  return (
    <CustomTable
      data={healthParameters}
      columns={useHealthParameterCustomTableListColumns()}
      isLoading={isLoading}
      isDeleteVisible
      isRowDeletable={isRowDeletable}
      onDeleteRow={onDeleteRow}
      hasFooter={false}
    />
  );
};
