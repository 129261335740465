import { HealthCondition } from "@/schema/types";
import { healthConditionTypeOptions } from "@health/enum-options";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormAutocomplete, FormCard, FormSwitch, FormTextField, Grid, PageWrapper } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { healthConditionsPaths } from "../../constants";
import { convertHealthConditionToFormValues } from "../../others/utils";
import {
  healthConditionUpsertFormDefaultValues,
  healthConditionUpsertFormSchema,
  IHealthConditionUpsertFormValues,
} from "./HealthConditionUpsertFormSchema";

export type IHealthConditionUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IHealthConditionUpsertFormValues;
  };
};

type HealthConditionUpsertFormProps = {
  healthCondition?: HealthCondition;
  submitButtonLabel: string;
  isClone?: boolean;
  isLoading?: boolean;
  isButtonDisabled?: boolean;
  onChange: (event: IHealthConditionUpsertFormEvent) => void;
};

export const HealthConditionUpsertForm: FC<HealthConditionUpsertFormProps> = props => {
  const { healthCondition, submitButtonLabel, isClone, isLoading, isButtonDisabled, onChange } = props;

  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();

  const form = useCustomForm<IHealthConditionUpsertFormValues>({
    defaultValues: healthConditionUpsertFormDefaultValues,
    schema: healthConditionUpsertFormSchema,
  });

  const { setValues, handleSubmit } = form;

  const onSubmit = (values: IHealthConditionUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  const onCancelClick = () => {
    navigate(healthConditionsPaths.list.fullPath);
  };

  useEffect(() => {
    if (healthCondition) {
      const _healthCondition = convertHealthConditionToFormValues(healthCondition, !!isClone);
      setValues(_healthCondition);
    }
  }, [healthCondition, isClone, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={submitButtonLabel}
              onNavigation={onCancelClick}
              newButtonDisabled={isButtonDisabled}
            />
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormCard title={t("Health Condition Information")} loading={isLoading} doYouHaveData>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormTextField name={"display"} label={t("Display")} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FormTextField name={"arabicDisplay"} label={t("Arabic Display")} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FormTextField name={"code"} label={t("Code")} disabled={!!healthCondition && !isClone} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FormTextField name={"umlsCode"} label={t("UMLS Code")} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FormTextField name={"disease"} label={t("Disease")} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FormAutocomplete name={"type"} label={t("Type")} options={healthConditionTypeOptions} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FormSwitch name={"isActive"} label={t("Is Active")} />
                  </Grid>
                </Grid>
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
