import { guidedCareActivityTypeOptionsMap, teamMemberPositionOptionsMap } from "@health/enum-options";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomTable, FormActions, FormCard, Grid, PageWrapper, Typography } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { GuidedCareActivityType, MedicalMessage, TeamMemberPosition } from "../../../../schema/types";
import { IGuidedCareActivityItemUpdateFormEvent } from "../../forms/GuidedCareActivityItemUpdate/GuidedCareActivityItemUpdateForm";
import {
  GuidedCareActivityItemUpdateModal,
  GuidedCareActivityItemUpdateModalApi,
} from "../../modals/GuidedCareActivityItemUpdate/GuidedCareActivityItemUpdateModal";
import { IGuidedCareActivityItemUpdateFormValues } from "../GuidedCareActivityItemUpdate/GuidedCareActivityItemUpdateFormSchema";
import {
  guidedCareActivityItemsFormDefaultValues,
  guidedCareActivityItemsFormSchema,
  IGuidedCareActivityItemsFormValues,
} from "./GuidedCareActivityItemsFormSchema";
import { useGuidedCareActivityItemsFormColumns } from "./useGuidedCareActivityItemsFormColumns";
import { useGuidedCareActivityItemsStyle } from "./useGuidedCareActivityItemsStyle";

export type IGuidedCareActivityItemsFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IGuidedCareActivityItemsFormValues;
  };
};

type GuidedCareActivityItemsFormProps = {
  activityItems: IGuidedCareActivityItemUpdateFormValues[];
  position: TeamMemberPosition;
  activityType: GuidedCareActivityType;
  medicalMessage?: MedicalMessage;
  isLoading?: boolean;
  onChange: (event: IGuidedCareActivityItemsFormEvent) => void;
};

export const GuidedCareActivityItemsForm: FC<GuidedCareActivityItemsFormProps> = props => {
  const { activityItems, position, activityType, medicalMessage, isLoading, onChange } = props;

  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();

  const { classes } = useGuidedCareActivityItemsStyle();

  const form = useCustomForm<IGuidedCareActivityItemsFormValues>({
    defaultValues: guidedCareActivityItemsFormDefaultValues,
    schema: guidedCareActivityItemsFormSchema,
  });

  const { watch, setValue, handleSubmit } = form;

  const formActivityItems = watch("activityItems");

  const onSubmit = (values: IGuidedCareActivityItemsFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  const onGuidedCareActivityItemUpdateModalChange = (event: IGuidedCareActivityItemUpdateFormEvent) => {
    if (event.type === "UPDATE") {
      const updatedActivityItems = formActivityItems?.map(item => {
        if (item?.id === event.payload.values.id) {
          return event.payload.values;
        } else {
          return item;
        }
      });

      setValue("activityItems", updatedActivityItems);

      GuidedCareActivityItemUpdateModalApi.close();
    }
  };

  const onEditRowClick = (item: IGuidedCareActivityItemUpdateFormValues) => {
    GuidedCareActivityItemUpdateModalApi.open({ activityItem: item });
  };

  const handleCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (activityItems?.length) {
      setValue("activityItems", activityItems);
    }
  }, [activityItems, setValue]);

  return (
    <>
      <GuidedCareActivityItemUpdateModal onChange={onGuidedCareActivityItemUpdateModalChange} />

      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <PageWrapper
            actions={
              <FormActions hasCancel hasFormButton formButtonTitle={t("Save")} onNavigation={handleCancel} newButtonDisabled={isLoading} />
            }
          >
            <FormCard title={t("Activity Items")} loading={isLoading} doYouHaveData>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className={classes.subtitle}>
                    {t("Team Member")}: {teamMemberPositionOptionsMap[position]?.label!}
                  </Typography>

                  <Typography className={classes.subtitle}>
                    {t("Activity Type")}: {guidedCareActivityTypeOptionsMap[activityType]?.label}
                  </Typography>

                  {medicalMessage && (
                    <Typography className={classes.subtitle}>
                      {t("Medical Message")}: {`${medicalMessage?.code} - ${medicalMessage?.name}`}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <CustomTable
                    data={formActivityItems}
                    columns={useGuidedCareActivityItemsFormColumns()}
                    isEditVisible
                    isRowEditable
                    onEditRow={onEditRowClick}
                    hasFooter={false}
                    scrollable
                  />
                </Grid>
              </Grid>
            </FormCard>
          </PageWrapper>
        </form>
      </FormProvider>
    </>
  );
};
