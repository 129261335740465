import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthParameterCreateMutationVariables = Types.Exact<{
  input: Types.HealthParameterInput;
}>;


export type HealthParameterCreateMutation = { __typename?: 'Mutation', addHealthParameter?: { __typename?: 'HealthParameter', errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', field?: string | null, message?: string | null } | null> | null } | null };


export const HealthParameterCreateDocument = gql`
    mutation HealthParameterCreate($input: HealthParameterInput!) {
  addHealthParameter(input: $input) {
    errors {
      field
      message
    }
  }
}
    `;
export type HealthParameterCreateMutationFn = Apollo.MutationFunction<HealthParameterCreateMutation, HealthParameterCreateMutationVariables>;

/**
 * __useHealthParameterCreateMutation__
 *
 * To run a mutation, you first call `useHealthParameterCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthParameterCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthParameterCreateMutation, { data, loading, error }] = useHealthParameterCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHealthParameterCreateMutation(baseOptions?: Apollo.MutationHookOptions<HealthParameterCreateMutation, HealthParameterCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthParameterCreateMutation, HealthParameterCreateMutationVariables>(HealthParameterCreateDocument, options);
      }
export type HealthParameterCreateMutationHookResult = ReturnType<typeof useHealthParameterCreateMutation>;
export type HealthParameterCreateMutationResult = Apollo.MutationResult<HealthParameterCreateMutation>;
export type HealthParameterCreateMutationOptions = Apollo.BaseMutationOptions<HealthParameterCreateMutation, HealthParameterCreateMutationVariables>;