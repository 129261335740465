import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MedicalMessageCreateMutationVariables = Types.Exact<{
  input: Types.MedicalMessageInput;
}>;


export type MedicalMessageCreateMutation = { __typename?: 'Mutation', medicalMessageCreate?: { __typename?: 'MedicalMessageCRUD', medicalMessageErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, message?: string | null }> | null } | null };


export const MedicalMessageCreateDocument = gql`
    mutation MedicalMessageCreate($input: MedicalMessageInput!) {
  medicalMessageCreate(input: $input) {
    medicalMessageErrors {
      field
      message
    }
  }
}
    `;
export type MedicalMessageCreateMutationFn = Apollo.MutationFunction<MedicalMessageCreateMutation, MedicalMessageCreateMutationVariables>;

/**
 * __useMedicalMessageCreateMutation__
 *
 * To run a mutation, you first call `useMedicalMessageCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMedicalMessageCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [medicalMessageCreateMutation, { data, loading, error }] = useMedicalMessageCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMedicalMessageCreateMutation(baseOptions?: Apollo.MutationHookOptions<MedicalMessageCreateMutation, MedicalMessageCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MedicalMessageCreateMutation, MedicalMessageCreateMutationVariables>(MedicalMessageCreateDocument, options);
      }
export type MedicalMessageCreateMutationHookResult = ReturnType<typeof useMedicalMessageCreateMutation>;
export type MedicalMessageCreateMutationResult = Apollo.MutationResult<MedicalMessageCreateMutation>;
export type MedicalMessageCreateMutationOptions = Apollo.BaseMutationOptions<MedicalMessageCreateMutation, MedicalMessageCreateMutationVariables>;