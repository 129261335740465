import { ArticleCategory } from "@/schema/types";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, GridImageModal } from "@toolkit/ui";
import { useMemo } from "react";

export const useArticleCategoryListContainerColumns = (): CustomTableColumnProps<ArticleCategory>[] => {
  const { t } = useTranslation("gcadmin");

  return useMemo(() => {
    return [
      {
        key: "icon",
        header: t("Icon"),
        accessor: ({ icon }) => <GridImageModal image={icon!} title={t("Icon")} altText={t("Icon")} />,
      },
      {
        key: "id",
        header: t("ID"),
        accessor: "id",
        filter: {
          type: "string",
          name: "ids",
        },
      },
      {
        key: "display",
        header: t("Display"),
        accessor: pickLocalizedValue("display", "displayAr"),
        filter: {
          type: "string",
          name: "display",
        },
      },
      {
        key: "code",
        header: t("Code"),
        accessor: "code",
        filter: {
          type: "string",
          name: "code",
        },
      },
    ];
  }, []);
};
