import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog } from "@toolkit/ui";
import { FC, useEffect, useRef, useState } from "react";
import {
  GuidedCareActivityItemUpdateForm,
  GuidedCareActivityItemUpdateFormRef,
  IGuidedCareActivityItemUpdateFormEvent,
} from "../../forms/GuidedCareActivityItemUpdate/GuidedCareActivityItemUpdateForm";
import { IGuidedCareActivityItemUpdateFormValues } from "../../forms/GuidedCareActivityItemUpdate/GuidedCareActivityItemUpdateFormSchema";

type IGuidedCareActivityItemUpdateModalData = {
  activityItem: IGuidedCareActivityItemUpdateFormValues;
};

type IOpen = (data: IGuidedCareActivityItemUpdateModalData) => void;
type IClose = () => void;

export const GuidedCareActivityItemUpdateModalApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

type GuidedCareActivityItemUpdateModalProps = {
  onChange: (values: IGuidedCareActivityItemUpdateFormEvent) => void;
};

export const GuidedCareActivityItemUpdateModal: FC<GuidedCareActivityItemUpdateModalProps> = props => {
  const { onChange } = props;

  const { open: isOpen, handleClose, handleOpen } = useOpenState();
  const [data, setData] = useState<IGuidedCareActivityItemUpdateModalData>();

  const { t } = useTranslation("gcadmin");

  const activityItemUpdateFormRef = useRef<GuidedCareActivityItemUpdateFormRef>(null);

  const onUpdateClick = () => {
    activityItemUpdateFormRef.current?.submit();
  };

  const open: IOpen = _data => {
    handleOpen();
    setData(_data);
  };

  const close: IClose = () => {
    handleClose();
  };

  useEffect(() => {
    GuidedCareActivityItemUpdateModalApi.open = open;
    GuidedCareActivityItemUpdateModalApi.close = close;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      DialogTitleProps={{
        title: t("Update Activity Item"),
        onClose: close,
      }}
      DialogActionsProps={{
        hasClose: true,
        submitTitle: t("Update"),
        onSubmit: onUpdateClick,
      }}
    >
      <GuidedCareActivityItemUpdateForm activityItem={data?.activityItem!} ref={activityItemUpdateFormRef} onChange={onChange} />
    </CustomDialog>
  );
};
