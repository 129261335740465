import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareTemplateInterventionActivityItemsUpdateMutationVariables = Types.Exact<{
  activityId: Types.Scalars['ID']['input'];
  activityItems?: Types.InputMaybe<Array<Types.InputMaybe<Types.TemplateInterventionActivityEventItemInput>> | Types.InputMaybe<Types.TemplateInterventionActivityEventItemInput>>;
}>;


export type GuidedCareTemplateInterventionActivityItemsUpdateMutation = { __typename?: 'Mutation', updateTemplateInterventionActivityItems?: { __typename?: 'TemplateInterventionActivityCRUD', templateActivityErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, message?: string | null }> | null } | null };


export const GuidedCareTemplateInterventionActivityItemsUpdateDocument = gql`
    mutation GuidedCareTemplateInterventionActivityItemsUpdate($activityId: ID!, $activityItems: [TemplateInterventionActivityEventItemInput]) {
  updateTemplateInterventionActivityItems(
    activityId: $activityId
    activityItems: $activityItems
  ) {
    templateActivityErrors {
      field
      message
    }
  }
}
    `;
export type GuidedCareTemplateInterventionActivityItemsUpdateMutationFn = Apollo.MutationFunction<GuidedCareTemplateInterventionActivityItemsUpdateMutation, GuidedCareTemplateInterventionActivityItemsUpdateMutationVariables>;

/**
 * __useGuidedCareTemplateInterventionActivityItemsUpdateMutation__
 *
 * To run a mutation, you first call `useGuidedCareTemplateInterventionActivityItemsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareTemplateInterventionActivityItemsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guidedCareTemplateInterventionActivityItemsUpdateMutation, { data, loading, error }] = useGuidedCareTemplateInterventionActivityItemsUpdateMutation({
 *   variables: {
 *      activityId: // value for 'activityId'
 *      activityItems: // value for 'activityItems'
 *   },
 * });
 */
export function useGuidedCareTemplateInterventionActivityItemsUpdateMutation(baseOptions?: Apollo.MutationHookOptions<GuidedCareTemplateInterventionActivityItemsUpdateMutation, GuidedCareTemplateInterventionActivityItemsUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GuidedCareTemplateInterventionActivityItemsUpdateMutation, GuidedCareTemplateInterventionActivityItemsUpdateMutationVariables>(GuidedCareTemplateInterventionActivityItemsUpdateDocument, options);
      }
export type GuidedCareTemplateInterventionActivityItemsUpdateMutationHookResult = ReturnType<typeof useGuidedCareTemplateInterventionActivityItemsUpdateMutation>;
export type GuidedCareTemplateInterventionActivityItemsUpdateMutationResult = Apollo.MutationResult<GuidedCareTemplateInterventionActivityItemsUpdateMutation>;
export type GuidedCareTemplateInterventionActivityItemsUpdateMutationOptions = Apollo.BaseMutationOptions<GuidedCareTemplateInterventionActivityItemsUpdateMutation, GuidedCareTemplateInterventionActivityItemsUpdateMutationVariables>;