import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthParameterActivationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type HealthParameterActivationMutation = { __typename?: 'Mutation', updateHealthParameterActiveStatus?: { __typename?: 'HealthParameter', id?: string | null, isActive?: boolean | null } | null };


export const HealthParameterActivationDocument = gql`
    mutation HealthParameterActivation($id: ID!) {
  updateHealthParameterActiveStatus(id: $id) {
    id
    isActive
  }
}
    `;
export type HealthParameterActivationMutationFn = Apollo.MutationFunction<HealthParameterActivationMutation, HealthParameterActivationMutationVariables>;

/**
 * __useHealthParameterActivationMutation__
 *
 * To run a mutation, you first call `useHealthParameterActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthParameterActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthParameterActivationMutation, { data, loading, error }] = useHealthParameterActivationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHealthParameterActivationMutation(baseOptions?: Apollo.MutationHookOptions<HealthParameterActivationMutation, HealthParameterActivationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthParameterActivationMutation, HealthParameterActivationMutationVariables>(HealthParameterActivationDocument, options);
      }
export type HealthParameterActivationMutationHookResult = ReturnType<typeof useHealthParameterActivationMutation>;
export type HealthParameterActivationMutationResult = Apollo.MutationResult<HealthParameterActivationMutation>;
export type HealthParameterActivationMutationOptions = Apollo.BaseMutationOptions<HealthParameterActivationMutation, HealthParameterActivationMutationVariables>;