import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RiskFactorTemplateParameterCreateMutationVariables = Types.Exact<{
  healthParameterId: Types.Scalars['ID']['input'];
  riskFactorTemplateId: Types.Scalars['ID']['input'];
}>;


export type RiskFactorTemplateParameterCreateMutation = { __typename?: 'Mutation', addFieldToRiskFactorTemplate?: { __typename?: 'RiskFactorTemplate', errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', field?: string | null, message?: string | null } | null> | null } | null };


export const RiskFactorTemplateParameterCreateDocument = gql`
    mutation RiskFactorTemplateParameterCreate($healthParameterId: ID!, $riskFactorTemplateId: ID!) {
  addFieldToRiskFactorTemplate(
    healthParameterId: $healthParameterId
    riskFactorTemplateId: $riskFactorTemplateId
  ) {
    errors {
      field
      message
    }
  }
}
    `;
export type RiskFactorTemplateParameterCreateMutationFn = Apollo.MutationFunction<RiskFactorTemplateParameterCreateMutation, RiskFactorTemplateParameterCreateMutationVariables>;

/**
 * __useRiskFactorTemplateParameterCreateMutation__
 *
 * To run a mutation, you first call `useRiskFactorTemplateParameterCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRiskFactorTemplateParameterCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [riskFactorTemplateParameterCreateMutation, { data, loading, error }] = useRiskFactorTemplateParameterCreateMutation({
 *   variables: {
 *      healthParameterId: // value for 'healthParameterId'
 *      riskFactorTemplateId: // value for 'riskFactorTemplateId'
 *   },
 * });
 */
export function useRiskFactorTemplateParameterCreateMutation(baseOptions?: Apollo.MutationHookOptions<RiskFactorTemplateParameterCreateMutation, RiskFactorTemplateParameterCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RiskFactorTemplateParameterCreateMutation, RiskFactorTemplateParameterCreateMutationVariables>(RiskFactorTemplateParameterCreateDocument, options);
      }
export type RiskFactorTemplateParameterCreateMutationHookResult = ReturnType<typeof useRiskFactorTemplateParameterCreateMutation>;
export type RiskFactorTemplateParameterCreateMutationResult = Apollo.MutationResult<RiskFactorTemplateParameterCreateMutation>;
export type RiskFactorTemplateParameterCreateMutationOptions = Apollo.BaseMutationOptions<RiskFactorTemplateParameterCreateMutation, RiskFactorTemplateParameterCreateMutationVariables>;