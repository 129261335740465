import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RiskFactorTemplateCreateMutationVariables = Types.Exact<{
  input: Types.RiskFactorTemplateInput;
}>;


export type RiskFactorTemplateCreateMutation = { __typename?: 'Mutation', addRiskFactorTemplate?: { __typename?: 'RiskFactorTemplate', errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', field?: string | null, message?: string | null } | null> | null } | null };


export const RiskFactorTemplateCreateDocument = gql`
    mutation RiskFactorTemplateCreate($input: RiskFactorTemplateInput!) {
  addRiskFactorTemplate(input: $input) {
    errors {
      field
      message
    }
  }
}
    `;
export type RiskFactorTemplateCreateMutationFn = Apollo.MutationFunction<RiskFactorTemplateCreateMutation, RiskFactorTemplateCreateMutationVariables>;

/**
 * __useRiskFactorTemplateCreateMutation__
 *
 * To run a mutation, you first call `useRiskFactorTemplateCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRiskFactorTemplateCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [riskFactorTemplateCreateMutation, { data, loading, error }] = useRiskFactorTemplateCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRiskFactorTemplateCreateMutation(baseOptions?: Apollo.MutationHookOptions<RiskFactorTemplateCreateMutation, RiskFactorTemplateCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RiskFactorTemplateCreateMutation, RiskFactorTemplateCreateMutationVariables>(RiskFactorTemplateCreateDocument, options);
      }
export type RiskFactorTemplateCreateMutationHookResult = ReturnType<typeof useRiskFactorTemplateCreateMutation>;
export type RiskFactorTemplateCreateMutationResult = Apollo.MutationResult<RiskFactorTemplateCreateMutation>;
export type RiskFactorTemplateCreateMutationOptions = Apollo.BaseMutationOptions<RiskFactorTemplateCreateMutation, RiskFactorTemplateCreateMutationVariables>;