import { ArticleCategory } from "@/schema/types";
import { formGirdBreakPoints, formGirdSpacing, useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, FormTextField, Grid, ImageUploadController, PageWrapper } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { articlesCategoriesPaths } from "../../constants";
import { convertArticleCategoryToFormValues } from "../../utils";
import {
  articleCategoryUpsertFormSchema,
  articleCategoryUpsertFormSchemaDefaultValues,
  IArticleCategoryUpsertFormValues,
} from "./ArticleCategoryUpsertFormSchema";
import { useOidcUserProfile } from "@/shared/hooks";

export type IArticleCategoryUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IArticleCategoryUpsertFormValues;
  };
};

type ArticleCategoryUpsertFormProps = {
  articleCategory?: ArticleCategory;
  isLoading?: boolean;
  isSubmitting?: boolean;
  submitButtonLabel: string;
  onChange: (event: IArticleCategoryUpsertFormEvent) => void;
};

export const ArticleCategoryUpsertForm: FC<ArticleCategoryUpsertFormProps> = props => {
  const { articleCategory, isLoading, isSubmitting, submitButtonLabel, onChange } = props;

  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();

  const { accessToken } = useOidcUserProfile();

  const form = useCustomForm<IArticleCategoryUpsertFormValues>({
    defaultValues: articleCategoryUpsertFormSchemaDefaultValues,
    schema: articleCategoryUpsertFormSchema,
  });

  const {
    setValues,
    handleSubmit,
    formState: { errors },
  } = form;

  const onSubmit = (values: IArticleCategoryUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  const handleCancel = () => {
    navigate(articlesCategoriesPaths.list.fullPath);
  };

  useEffect(() => {
    if (articleCategory) {
      const _articleCategory = convertArticleCategoryToFormValues(articleCategory);
      setValues(_articleCategory);
    }
  }, [articleCategory, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={submitButtonLabel}
              onNavigation={handleCancel}
              newButtonDisabled={isLoading || isSubmitting}
            />
          }
        >
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12}>
              <FormCard title={t("Article Category Information")} loading={isLoading} doYouHaveData>
                <Grid container spacing={formGirdSpacing}>
                  <Grid item {...formGirdBreakPoints}>
                    <FormTextField name={"display"} label={t("Display")} />
                  </Grid>

                  <Grid item {...formGirdBreakPoints}>
                    <FormTextField name={"displayAr"} label={t("Arabic display")} />
                  </Grid>

                  <Grid item {...formGirdBreakPoints}>
                    <FormTextField name={"code"} label={t("Code")} />
                  </Grid>
                </Grid>
              </FormCard>
            </Grid>

            <Grid item xs={12}>
              <FormCard title={t("Article Category Icon")} loading={isLoading} doYouHaveData>
                <ImageUploadController
                  control={form.control}
                  name={"icon"}
                  label={t("Icon")}
                  validationRation
                  error={!!errors?.icon}
                  helperText={errors?.icon?.message}
                  token={accessToken!}
                />
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
