import { HealthCondition } from "@/schema/types";
import { healthConditionTypeOptionsMap } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { Box, FormCard, Grid, Typography } from "@toolkit/ui";
import { FC } from "react";

type HealthConditionInformationProps = {
  healthCondition: HealthCondition;
  isLoading?: boolean;
};

export const HealthConditionInformation: FC<HealthConditionInformationProps> = props => {
  const { healthCondition, isLoading } = props;

  const { t } = useTranslation("gcadmin");

  return (
    <Box marginBottom={2}>
      <FormCard title={t("Health Condition")} doYouHaveData={!!healthCondition} loading={isLoading}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography fontSize={15}>
              {t("Display")}: {healthCondition?.display || "-"}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography fontSize={15}>
              {t("Arabic Display")}: {healthCondition?.arabicDisplay || "-"}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography fontSize={15}>
              {t("Code")}: {healthCondition?.code || "-"}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography fontSize={15}>
              {t("UMLS Code")}: {healthCondition?.umlsCode || "-"}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography fontSize={15}>
              {t("Disease")}: {healthCondition?.disease || "-"}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography fontSize={15}>
              {t("Type")}: {healthConditionTypeOptionsMap[healthCondition?.type!]?.label || "-"}
            </Typography>
          </Grid>
        </Grid>
      </FormCard>
    </Box>
  );
};
