import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthSymptomCreateMutationVariables = Types.Exact<{
  input: Types.HealthSymptomInput;
}>;


export type HealthSymptomCreateMutation = { __typename?: 'Mutation', healthSymptomCreate?: { __typename?: 'HealthSymptomCreate', healthSymptomErrors: Array<{ __typename?: 'HealthSymptomError', field?: string | null, message?: string | null }> } | null };


export const HealthSymptomCreateDocument = gql`
    mutation HealthSymptomCreate($input: HealthSymptomInput!) {
  healthSymptomCreate(input: $input) {
    healthSymptomErrors {
      field
      message
    }
  }
}
    `;
export type HealthSymptomCreateMutationFn = Apollo.MutationFunction<HealthSymptomCreateMutation, HealthSymptomCreateMutationVariables>;

/**
 * __useHealthSymptomCreateMutation__
 *
 * To run a mutation, you first call `useHealthSymptomCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthSymptomCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthSymptomCreateMutation, { data, loading, error }] = useHealthSymptomCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHealthSymptomCreateMutation(baseOptions?: Apollo.MutationHookOptions<HealthSymptomCreateMutation, HealthSymptomCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthSymptomCreateMutation, HealthSymptomCreateMutationVariables>(HealthSymptomCreateDocument, options);
      }
export type HealthSymptomCreateMutationHookResult = ReturnType<typeof useHealthSymptomCreateMutation>;
export type HealthSymptomCreateMutationResult = Apollo.MutationResult<HealthSymptomCreateMutation>;
export type HealthSymptomCreateMutationOptions = Apollo.BaseMutationOptions<HealthSymptomCreateMutation, HealthSymptomCreateMutationVariables>;