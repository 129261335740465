import { MedicalMessageType, MedicalMessageVariant } from "@/schema/types";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, FormTextField, Grid, ImageUploadController, PageWrapper } from "@toolkit/ui";
import { MedicalMessageTypeForm } from "pages/MedicalMessages/forms/MedicalMessageType/MedicalMessageTypeForm";
import { convertMedicalMessageVariantToFormValues } from "pages/MedicalMessages/others";
import { IMedicalMessageVariantUpsertFormEvent } from "pages/MedicalMessages/types";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useOidcUserProfile } from "shared/hooks";
import {
  IMedicalMessageVariantUpsertFormValues,
  medicalMessageVariantUpsertFormDefaultValues,
  medicalMessageVariantUpsertFormSchema,
} from "./MedicalMessageVariantUpsertFormSchema";

type MedicalMessageVariantUpsertFormProps = {
  submitButtonLabel: string;
  isLoading?: boolean;
  isButtonDisabled?: boolean;
  medicalMessageVariant?: MedicalMessageVariant;
  medicalMessageType: MedicalMessageType;
  onChange: (values: IMedicalMessageVariantUpsertFormEvent) => void;
};

export const MedicalMessageVariantUpsertForm: FC<MedicalMessageVariantUpsertFormProps> = props => {
  const { submitButtonLabel, isLoading, isButtonDisabled, medicalMessageVariant, medicalMessageType, onChange } = props;

  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();
  const { accessToken } = useOidcUserProfile();

  const form = useCustomForm<IMedicalMessageVariantUpsertFormValues>({
    defaultValues: medicalMessageVariantUpsertFormDefaultValues,
    schema: medicalMessageVariantUpsertFormSchema,
  });

  const {
    setValues,
    handleSubmit,
    formState: { errors },
  } = form;

  const onSubmit = (values: IMedicalMessageVariantUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  const handleCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (medicalMessageVariant) {
      const _medicalMessageVariant = convertMedicalMessageVariantToFormValues(medicalMessageVariant, medicalMessageType!);
      setValues(_medicalMessageVariant);
    }
  }, [medicalMessageType, medicalMessageVariant, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={submitButtonLabel}
              onNavigation={handleCancel}
              newButtonDisabled={isButtonDisabled}
            />
          }
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormCard title={t("Variant Information")} loading={isLoading} doYouHaveData>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormTextField name={"presentedTitleEnglish"} label={t("Title (English)")} />
                  </Grid>

                  <Grid item xs={6}>
                    <FormTextField name={"presentedTitleArabic"} label={t("Title (Arabic)")} />
                  </Grid>

                  <Grid item xs={6}>
                    <FormTextField name={"bodyEnglish"} multiline rows={5} label={t("Body (English)")} />
                  </Grid>

                  <Grid item xs={6}>
                    <FormTextField name={"bodyArabic"} multiline rows={5} label={t("Body (Arabic)")} />
                  </Grid>

                  <Grid item xs={6}>
                    <MedicalMessageTypeForm medicalMessageType={medicalMessageType!} />
                  </Grid>

                  <Grid item xs={6}>
                    <MedicalMessageTypeForm medicalMessageType={medicalMessageType!} isArabic />
                  </Grid>

                  <Grid item xs={6}>
                    <FormTextField name={"buttonTextEnglish"} label={t("Button Text (English)")} />
                  </Grid>

                  <Grid item xs={6}>
                    <FormTextField name={"buttonTextArabic"} label={t("Button Text (Arabic)")} />
                  </Grid>
                </Grid>
              </FormCard>
            </Grid>

            <Grid item xs={12}>
              <FormCard title={t("Header Image")} loading={isLoading} doYouHaveData>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ImageUploadController
                      control={form.control}
                      name={"headerImage"}
                      label={t("Image")}
                      validationRation
                      error={!!errors?.headerImage}
                      helperText={errors?.headerImage?.message}
                      token={accessToken!}
                    />
                  </Grid>
                </Grid>
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
