import { DecisionPlanCategory } from "@/schema/types";
import { DecisionUpdateContainer } from "@health/domains";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { decisionsBreadcrumbs } from "pages/Decisions/constants";
import { FC, useEffect } from "react";

type GuidedCareDecisionUpdateContainerProps = {
  category: DecisionPlanCategory;
};

export const GuidedCareDecisionUpdateContainer: FC<GuidedCareDecisionUpdateContainerProps> = props => {
  const { category } = props;

  const { t } = useTranslation("gcadmin");
  const { setBreadCrumb } = useBreadCrumbs();

  useEffect(() => {
    const { title, main, update } = decisionsBreadcrumbs(t);

    setBreadCrumb({ title: title(category), values: [main(category), update] });
  }, [category, setBreadCrumb, t]);

  return <DecisionUpdateContainer category={category} />;
};
