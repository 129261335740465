import { z } from "zod";
import { guidedCareActivityItemUpdateFormSchema } from "../GuidedCareActivityItemUpdate/GuidedCareActivityItemUpdateFormSchema";

export const guidedCareActivityItemsFormSchema = z.object({
  activityItems: z.array(guidedCareActivityItemUpdateFormSchema),
});

export type IGuidedCareActivityItemsFormValues = z.infer<typeof guidedCareActivityItemsFormSchema>;

export const guidedCareActivityItemsFormDefaultValues: Partial<IGuidedCareActivityItemsFormValues> = {
  activityItems: [],
};
