import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
export type HPageInfoFragmentFragment = { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null };

export const HPageInfoFragmentFragmentDoc = gql`
    fragment HPageInfoFragment on H_PageInfo {
  endCursor
  hasNextPage
  hasPreviousPage
  startCursor
}
    `;