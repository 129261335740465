import { HealthProgramTemplate } from "@/schema/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { guidedCareTemplatesPaths } from "pages/Templates/constants";
import { useGuidedCareTemplateCreateMutation, useGuidedCareTemplateGetQuery } from "pages/Templates/gql";
import { useSetGuidedCareTemplatesBreadcrumbs } from "pages/Templates/hooks";
import { convertTemplateFormValuesToBackEndValues } from "pages/Templates/others";
import { IGuidedCareTemplateUpsertFormEvent, ITemplateCloneContainerParams } from "pages/Templates/types";
import { useNavigate, useParams } from "react-router-dom";
import { GuidedCareTemplateUpsertForm } from "../../forms/TemplateUpsert/GuidedCareTemplateUpsertForm";

export const GuidedCareTemplateClone = () => {
  const { t } = useTranslation("gcadmin");
  const { succeeded, failed } = useAddToast();
  const navigate = useNavigate();

  const { templateId } = useParams<ITemplateCloneContainerParams>();

  const { data, loading } = useGuidedCareTemplateGetQuery({
    variables: { id: templateId! },
    skip: !templateId,
  });

  const template = data?.healthProgramTemplate as HealthProgramTemplate;

  const [createGuidedCareTemplateMutation, { loading: isSubmitting }] = useGuidedCareTemplateCreateMutation({
    onCompleted: data => {
      const errors = data?.healthProgramTemplateCreate?.healthProgramTemplateErrors;
      if (errors?.length) {
        failed(formatMessageErrors(errors));
      } else {
        succeeded(t("Template cloned successfully"));
        navigate(guidedCareTemplatesPaths.list.fullPath);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onTemplateUpsertFormChange = (event: IGuidedCareTemplateUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertTemplateFormValuesToBackEndValues(event.payload.values, template, true);

      createGuidedCareTemplateMutation({
        variables: {
          input: values,
        },
      });
    }
  };

  useSetGuidedCareTemplatesBreadcrumbs("CLONE", template?.name!);

  return (
    <GuidedCareTemplateUpsertForm
      submitButtonLabel={t("Clone")}
      isLoading={loading}
      isClone
      isButtonDisabled={isSubmitting}
      template={template!}
      onChange={onTemplateUpsertFormChange}
    />
  );
};
